<!--
 * @Author: your name
 * @Date: 2021-08-15 10:30:03
 * @LastEditTime: 2021-08-24 17:41:42
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/QuestionnaireJumpTree.vue
-->
<template>
  <page-main title back>
    <!-- <scene-super-flow :node-list='examList'></scene-super-flow> -->
      <div class="scene-empty" v-if="isEmpty">
        <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
        <div>目前暂无跳题逻辑</div>
      </div>
      <VoBasic class="jump-tree" nodeTitle='name' nodeContent='selectText' :data='examObj'/>
  </page-main>
</template>
<script>
import {mapActions} from 'vuex'
import 'vue-orgchart/dist/style.min.css'
import { VoBasic } from 'vue-orgchart'
export default {
  name: 'QuestionnaireJumpTree',
  data () {
    return {
      examObj: {},
      sceneId: '',
      isEmpty: false,
    }
  },
  components: {
    VoBasic
  },
  methods: {
    ...mapActions(['getQuestionnaireJumpTree']),
  },
  mounted () {
    let arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T']
    this.sceneId = this.$route.params.sceneId || ''
    this.getQuestionnaireJumpTree(this.sceneId).then(res=>{
      res = res || {}
      let call=(data,selectList)=>{
			 return	data.map((k,i) => {
					if(k.children){
						call(k.children,k.examAnswerIds || [])
          }
          k.selectText = ''
          selectList.map((d,ci)=>{
            if (d === k.preExamAnswerId) {
              k.selectText = `选项${arr[ci]}` || ''
            }
          })
          k.name = `第${k.examIndex}题` || k.title
					return k
				})
			}
			if (!res.children) {
        this.isEmpty = true
      } else {
			   this.examObj = {...res,selectText:'',name:`第${res.examIndex}题`,children: call(res.children || [],res.examAnswerIds || [])}
        // this.examObj = { "examId": 3336, "examIndex": 1, "examAnswerIds": [ 9074, 9075, 9076, 9077 ], "preExamAnswerId": 9074, "children": [ { "examId": 3338, "examIndex": 3, "examAnswerIds": null, "preExamAnswerId": 9074, "children": [ { "examId": 3339, "examIndex": 4, "examAnswerIds": null, "preExamAnswerId": null, "children": [ { "examId": 3340, "examIndex": 5, "examAnswerIds": null, "preExamAnswerId": null, "children": [ { "examId": 3341, "examIndex": 6, "examAnswerIds": null, "preExamAnswerId": null, "children": null, "selectText": "", "name": "第6题" } ], "selectText": "", "name": "第5题" } ], "selectText": "", "name": "第4题" } ], "selectText": "选项A", "name": "第3题" }, { "examId": 3339, "examIndex": 4, "examAnswerIds": null, "preExamAnswerId": 9076, "children": [ { "examId": 3340, "examIndex": 5, "examAnswerIds": null, "preExamAnswerId": null, "children": [ { "examId": 3341, "examIndex": 6, "examAnswerIds": null, "preExamAnswerId": null, "children": null, "selectText": "", "name": "第6题" } ], "selectText": "", "name": "第5题" } ], "selectText": "选项C", "name": "第4题" }, { "examId": 3340, "examIndex": 5, "examAnswerIds": null, "preExamAnswerId": 9077, "children": [ { "examId": 3341, "examIndex": 6, "examAnswerIds": null, "preExamAnswerId": null, "children": null, "selectText": "", "name": "第6题" } ], "selectText": "选项D", "name": "第5题" }, { "examId": 3341, "examIndex": 6, "examAnswerIds": null, "preExamAnswerId": 9075, "children": null, "selectText": "选项B", "name": "第6题" } ], "selectText": "", "name": "第1题" }
      }

    },rea=>{

    })
  }
}
</script>
<style lang="scss" scoped>
  .jump-tree ::v-deep {
    overflow: auto !important;
    line-height: 40px;
    .orgchart {
      height: 100%;
      .node {
        min-width: 150px;
        text-align: center;
        position: relative;
        // display: flex;
        .title {
          background:rgba(233, 244, 255, 1);
          color: rgba(25, 140, 255, 1);
          font-size: 18px;
          font-weight: bold;
          height: 46px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 10px;
          padding-right: 10px;
          border: 1px solid rgba(25, 140, 255, 1);
          border-radius: 4px;
        }
        .content {
          position: absolute;
          font-size: 18px;
          color: #7784A1;
          font-weight: bold;
          top: -30px;
          left: 50%;
          width: auto;
          padding-left: 10px;
          border: none;
          background: transparent;
        }
      }
      tr.lines {
        .downLine {
          background: rgba(25, 140, 255, 1);
        }
        .rightLine {
          border-right: 1px solid rgba(25, 140, 255, 1);
        }
        .leftLine {
          border-left: 1px solid rgba(25, 140, 255, 1);
        }
        .topLine {
          border-top: 2px solid rgba(25, 140, 255, 1);
        }
      }
    }
  }
  .scene-empty {
    color: rgba(119, 132, 161, 1);
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 10px;
    }
  }
</style>
