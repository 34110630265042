import { ArgoLog } from '@qxs-bns/utils'
const eventNames = {
  org_model_click: 'org_model_click',
  org_model_query: 'org_model_query',
  org_model_search: 'org_model_search',
  org_model_download: 'org_model_download'
}

const argoConfigMap = {
  development: {
    // 本地开发先关掉debug模式 2开启 0关闭
    debugMode: window.location.host.indexOf('yaomaitong') > -1 ? 2 : 0,
    encryptType: 0,
    appkey: '43f814dd272484a1', // APPKEY
    uploadURL: 'https://log.yaomaitong.net' // 上传数据的地址
  },
  pre: {
    // 本地开发先关掉debug模式 2开启 0关闭
    debugMode: window.location.host.indexOf('yaomaitong') > -1 ? 2 : 0,
    encryptType: 0,
    appkey: '43f814dd272484a1', // APPKEY
    uploadURL: 'https://log.yaomaitong.net' // 上传数据的地址
  },
  publish: {
    debugMode: 0,
    appkey: 'f9705b372930d61f', // APPKEY
    uploadURL: 'https://frp-log.yaomaitong.cn' // 上传数据的地址
  }
}

const env = process.env.VUE_APP_BUILD_ENV

const argolog = new ArgoLog(argoConfigMap[env])

export  {
  argolog,
  eventNames
}
