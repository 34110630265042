<template>
  <div>
    <page-main title back>
      <el-row type="flex" justify="space-between">
        <el-row type="flex">
          <el-popover
            placement="right"
            trigger="click"
            width="180"
            @show="onShowPreview"
          >
            <div
              v-loading="showPreviewStatus === 'loading'"
              class="align-center"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
            >
              <img class="qr-popover" :src="liveQrCodePreviewData[sceneId]">
              <div class="pdg-b15 font-s16">扫二维码预览</div>
            </div>
            <div slot="reference" class="scene-cover" title="点击预览">
              <img :src="sceneDetail.coverImg && sceneDetail.coverImg.normal" alt="" style="width: 100%; height: 100%">
              <span class="time-wrapper">
                <i class="el-icon-view mgn-r8" />
                预览
              </span>
            </div>
          </el-popover>
          <div style="min-width: 150px;">
            <div class="flex-between flex-direction-col vertical-start">
              <div class="font-bold col272e40 font-s18">{{ sceneDetail.title }}</div>
            </div>
            <div class="mgn-t10 col7784a1 font-s14">
              <span>播客：共{{ speaker.length }}位播客</span>
              <span class="mgn-l10 text-primary-link" @click="onShowGuest">查看播客</span>
            </div>
            <el-row v-if="isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">产品标签：</span>
              <template v-for="item in productList">
                <span :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
              <span v-if="!productList.length > 0" class="col7784a1 font-s14" style="margin-top: 8px">/</span>
            </el-row>
            <el-row v-if="otherList.length > 0 && isShowBtn" type="flex" class="flex-wrap mgn-t10">
              <span class="col7784a1 font-s14">其它标签：</span>
              <template v-for="item in otherList">
                <span v-if="item.groupName !== '产品标签'" :key="item.tagId" class="scene-tag">{{ item.tagName }}</span>
              </template>
            </el-row>
            <div class="col7784a1 mgn-t10 font-s14">跟进代表：{{ sceneDetail.followerName?`${sceneDetail.followerName}`: '/' }}{{ sceneDetail.followerOrgName?`（${sceneDetail.followerOrgName}）`: '' }}</div>
            <div v-if="sceneDetail.projectName" class="col7784a1 mgn-t10 font-s14">关联项目：{{ sceneDetail.projectName }}</div>
            <div v-if="sceneDetail.strategyName" class="col7784a1 mgn-t10 font-s14">策略：{{ sceneDetail.strategyName }}</div>
            <div class="col7784a1 mgn-t10 font-s14">简介：{{ sceneDetail.liveDesc }}</div>
          </div>
        </el-row>
        <div class="funnel">
          <EchartsFunnel v-if="showFunnel" :option="option" />
        </div>
        <div class="icon-message-box">
          <el-popover placement="left" width="400" trigger="hover">
            <div>总活动任务：实际发生的任务，不包含手动撤回和过期撤回的任务</div>
            <div>已开始任务：代表发送给客户的任务数</div>
            <div>已完成学术任务：客户学习并完成的任务数</div>
            <img
              slot="reference"
              src="../../assets/iconImg/icon-mark.png"
              alt=""
              style="width: 100%; height: 100%; cursor: pointer"
            >
          </el-popover>
        </div>
      </el-row>
    </page-main>
    <page-main>
      <el-row
        type="flex"
        justify="space-between"
        align="middle"
        class="flex-wrap"
      >
        <span class="font-s16">基础概况</span>
        <el-row v-if="$auth('acadSaas.actMgmt.viewExecData')" type="flex">
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('rank')"
          >
            执行报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('rank', 'Represent')"
          >
            代表数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('doctor/data')"
          >
            医生数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('task/data')"
          >
            任务数据报表
          </el-button>
          <el-button
            size="mini"
            type="primary"
            class="w120"
            plain
            @click="onJumpPanGu('exam/data')"
          >
            答题报表
          </el-button>
        </el-row>
      </el-row>
      <el-row type="flex" class="align-center row-t" justify="space-around">
        <el-col>
          <div class="color-main font-weight font-s36 co-t">{{ Math.floor(sceneSummary.liveDuration / 60) || 0 }}</div>
          <div class="color-info font-base-weight">直播时长（min）</div>
        </el-col>
        <el-col>
          <div class="color-main font-weight font-s36 co-t">{{ sceneSummary.viewPeopleCount || 0 }}</div>
          <div class="color-info font-base-weight">观众数UV（人次）</div>
        </el-col>
        <el-col>
          <div class="color-main font-weight font-s36 co-t">{{ sceneSummary.browserCount || 0 }}</div>
          <div class="color-info font-base-weight">观看量PV（人次）</div>
        </el-col>
        <el-col>
          <div class="color-main font-weight font-s36 co-t">{{ Math.floor(sceneSummary.totalViewSeconds / 60) || 0 }}</div>
          <div class="color-info font-base-weight">观看总时长（min）</div>
        </el-col>
      </el-row>
    </page-main>
    <page-main class="customize-page-main">
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        />
      </el-tabs>
      <div class="table-box">
        <div v-if="activeTab === 'exam'">
          <EntrustListDetail
            v-if="sceneId && sceneDetail.activityQuestId"
            :id="sceneDetail.activityQuestId"
            :scene-id="sceneId"
          />
          <div v-else class="scene-empty">
            <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
            <div>当前直播未添加题目</div>
          </div>
        </div>
        <div v-else>
          <el-table v-loading="recordLoading" :data="recordList">
            <el-table-column label="分配时间" align="center" prop="createdAt">
              <template slot-scope="scope">
                <span>{{ $moment(scope.row.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配方式" align="center">
              <template slot-scope="scope">
                <span>分配给{{ scope.row.toType | allotUserType }}{{ scope.row.toType === 1 && scope.row.autoAttrTime ? `（${$moment(scope.row.autoAttrTime).format('YYYY-MM-DD HH:mm:ss')}）` : '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label="任务规则" align="center">
              <template slot-scope="scope">
                <div>代表执行{{ scope.row.validDayRepresent }}天 + 医生学习{{ scope.row.validDayDoctor }}天</div>
              </template>
            </el-table-column>
            <el-table-column label="任务总数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.codeCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="成功任务数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.successCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="失败任务数" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.failCount }}个</span>
              </template>
            </el-table-column>
            <el-table-column label="是否有委托" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.hasDelegate ? '是' : '否' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配状态" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.status === 1 ? '分配完成' : scope.row.status === 2 ? '分配中' : scope.row.status === 3 ? '分配失败' : '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="分配操作人" prop="userName" align="center" />
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-popover
                  v-model="openObj[scope.row.id]"
                  width="200"
                  trigger="manual"
                  placement="left"
                >
                  <div v-if="exportMsg" class="card-body">{{ exportMsg }}</div>
                  <div v-else class="card-body">是否下载当前分配记录？</div>
                  <el-row type="flex" justify="end">
                    <el-button size="mini" type="text" @click="onCloseModal">取消</el-button>
                    <el-button
                      v-if="exportMsg"
                      type="primary"
                      size="mini"
                      @click="onJumpDownCenter"
                    >
                      前往
                    </el-button>
                    <el-button
                      v-else
                      type="primary"
                      size="mini"
                      :loading="openObj[scope.row.id] === 'loading'"
                      @click="onDown(scope.row.id)"
                    >
                      确定
                    </el-button>
                  </el-row>
                  <span slot="reference" class="text-primary-link" @click.stop="onShowDownToast(scope.row.id, true)">下载分配记录表</span>
                </el-popover>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </page-main>
    <fixed-action-bar>
      <el-button v-if="$auth('acadSaas.actMgmt.assignTask')" type="primary" @click="onAllot">分配任务</el-button>
      <template v-if="sceneSource">
        <el-button plain type="primary" @click="onJumpConsole"> 中控台 </el-button>
        <el-button
          v-if="totalCodeCount - beginCodeCount > 0 && $auth('acadSaas.actMgmt.assignTask')"
          plain
          type="primary"
          @click="onOpenRecallPop"
        >
          撤回任务
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.edit')"
          plain
          type="primary"
          @click="onEdit"
        >
          编辑活动
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.del')"
          plain
          type="primary"
          @click="onOpenOperationConfirmPop"
        >
          删除活动
        </el-button>
        <el-button
          v-if="$auth('acadSaas.actMgmt.share')"
          plain
          type="primary"
          @click="onOpenSharePop"
        >
          分享活动
        </el-button>
        <el-button
          v-if="isShowBtn && $auth('acadSaas.actMgmt.etag')"
          plain
          type="primary"
          @click="showTagModal = true"
        >
          编辑标签
        </el-button>
        <ReportEntry v-slot="{reportList, checkReport}" :push-location="5">
          <el-button
            v-if="isShowBtn && reportList.length"
            plain
            type="primary"
            @click="checkReport({title: sceneDetail.title, sceneId})"
          >
            查看数据报告
          </el-button>
        </ReportEntry>
      </template>
    </fixed-action-bar>
    <SceneShareDepModal
      :visible="isShowSharePop"
      scene-title="直播"
      :is-creator="sceneDetail.isCreator"
      :is-submit="isShareLoading"
      :scene-type="sceneType"
      :scene-id="sceneId"
      @onSubmit="onSubmitShareDep"
      @onClose="onCloseModal"
    />
    <RecallSceneCodeModal
      :visible="isShowRecallPop"
      :scene-id="sceneId"
      :scene-type="sceneType"
      @onClose="onCloseModal"
    />
    <OperationConfirmPop
      :id="sceneId"
      :visible="isShowOperationConfirmPop"
      :message="message"
      title="删除操作确认"
      cancel-font="取消"
      confirm-font="确定删除"
      @onSubmit="onDel"
      @onClose="onCloseModal"
    />

    <WebinarSpeakerListModal
      title="播客详情"
      :visible="isShowGuestModal"
      :speaker-list="sceneDetail.speakers"
      @onClose="onCloseModal"
    />
    <LiveEmpty :is-show-pop="isShowEmpty" @onClose="isShowEmpty = false" />
    <ProductLabel
      :form.sync="form"
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="sceneTagList"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import EchartsFunnel from '@/baseComponents/EchartsFunnel.vue'
import RecallSceneCodeModal from '@/bizComponents/RecallSceneCodeModal'
import SceneShareDepModal from '@/bizComponents/SceneShareDepModal'
import WebinarSpeakerListModal from './child/WebinarSpeakerListModal'
import LiveEmpty from './child/LiveEmpty'
import ProductLabel from '@/bizComponents/ProductLabel'
import EntrustListDetail from './components/EntrustListDetail'
export default {
  name: 'LiveDetail', // 跟文件名称对应
  components: {
    SceneShareDepModal,
    RecallSceneCodeModal,
    OperationConfirmPop,
    EchartsFunnel,
    WebinarSpeakerListModal,
    LiveEmpty,
    ProductLabel,
    EntrustListDetail
  },
  filters: {
    allotUserType(type) {
      switch (type) {
        case 1:
          return '医生'
        case 2:
          return '代表'
        case 3:
          return '组织'
      }
    }
  },
  data() {
    return {
      // ------------------- 预览 -----------------------------
      dialogTableVisible: false, // 发送手机预览modal
      recordLoading: false,
      showPreviewStatus: '',
      bindTagLoading: false,
      showFunnel: false,
      isShowGuestModal: false,
      tabList: [
        {
          name: '委托调研题',
          key: 'exam'
        },
        {
          name: '分配记录表',
          key: 'allot'
        }
      ],
      activeTab: 'exam',
      showTagModal: false,
      userTagType: 7, // 标签分类
      sceneTagList: [],
      disabledIds: [],
      sceneSummary: {},
      openObj: {},
      isShowEmpty: false,
      exportMsg: '',
      isShowSharePop: false,
      isShowRecallPop: false,
      isShowOperationConfirmPop: false,
      isShareLoading: false,
      message: '',
      sceneId: '',
      sceneDetail: {},
      isClick: false,
      sceneType: 'live',
      recordList: [],
      summaryData: [
        { value: 60, name: '总活动任务：' },
        { value: 40, name: '已开始任务：' },
        { value: 20, name: '已完成学术任务：' }
      ],
      totalCodeCount: 0,
      beginCodeCount: 0,
      productList: [],
      otherList: [],
      option: {},
      form: {}
    }
  },
  computed: {
    ...mapGetters(['tagGroupList', 'liveQrCodePreviewData', 'academicSourceType']),
    isShowBtn() {
      return this.academicSourceType === 'ower'
    },
    speaker() {
      return this.sceneDetail.speakers || []
    },
    sceneSource() {
      return this.$route.query.from !== 'common'
    }
  },
  watch: {
    activeTab(newVal) {
      if (newVal === 'allot') {
        this.initRecord()
      }
    },
    sceneTagList: {
      deep: true,
      immediate: true,
      handler() {
        this.productList = []
        this.otherList = []
        this.sceneTagList.map(item => {
          if (item.groupName === '产品标签') {
            this.productList.push(item)
          } else {
            this.otherList.push(item)
          }
        })
      }
    }
  },
  mounted() {
    this.sceneId = this.$route.params.sceneId
    this.init()
  },
  methods: {
    ...mapActions(['getLiveDetail', 'getSceneCodeUsedStatistic', 'getSceneDetailTag', 'onDownSceneAllotRecordData', 'getSceneAllotRecordList', 'onDelLive', 'getWebinarSpeakerList', 'getLiveSummary', 'sceneShareToDep', 'getLivePreviewQrCode', 'onBindSceneTag']),
    getOption() {
      this.option = {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}'
        },
        series: [{
          name: '活动数据',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 0,
          width: 240,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          gap: 0,
          label: {
            show: true,
            position: 'right',
            color: '#272E40'
          },
          labelLine: {
            length: 30,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          color: [
            '#B1BFF3',
            '#8AA0EE',
            '#6380E8',
            '#3D61E3'],
          data: this.summaryData
        }]
      }
      this.showFunnel = true
    },
    onShowPreview() {
      if (this.showPreviewStatus === 'loading' || this.showPreviewStatus === 'success') return
      this.showPreviewStatus = 'loading'
      this.getLivePreviewQrCode(this.sceneId).then(
        () => {
          this.showPreviewStatus = 'success'
        },
        rea => {
          this.showPreviewStatus = 'failed'
          this.$message.error(rea.message)
        }
      )
    },
    onShowGuest() {
      if (this.sceneDetail.speakers.length > 0) {
        this.isShowGuestModal = true
      }

      this.getWebinarSpeakerList(this.sceneId).then(
        res => {
          this.sceneDetail.speakers = res
          console.log(res)
          this.isShowGuestModal = true
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    onSubmitShareDep(arr, isSelectedPubResLib) {
      this.isShareLoading = true
      const req = { sceneIds: [this.sceneId], sceneType: this.sceneType, factoryIds: arr, isSelectedPubResLib }
      this.sceneShareToDep(req).then(
        () => {
          this.isShareLoading = false
          this.isShowSharePop = false
          this.$message.success('分享成功')
        },
        rea => {
          this.isShareLoading = false
          this.$message.error(rea.message)
        }
      )
    },
    onSubmitTag(arr) {
      this.bindTagLoading = true
      const req = arr.map(v => {
        return {
          tagId: v.tagId,
          groupId: v.groupId,
          userTagType: this.userTagType,
          memberId: this.sceneId,
          knowledgePointIds: v.knowledgePointIds,
          strategyId: this.form.strategyId
        }
      })
      this.onBindSceneTag(req).then(
        () => {
          this.initTag()
          this.bindTagLoading = false
          this.showTagModal = false
          this.form.strategyDisabled = true
          this.$message.success('标签绑定成功')
        },
        rea => {
          this.showTagModal = false
          this.bindTagLoading = false
          this.$message.error(rea)
        }
      )
    },
    onJumpPanGu(path, type = '') {
      this.$qxsDataPageJump(`/${path}?sceneTitle=${encodeURIComponent(this.sceneDetail.title)}&sceneId=${this.sceneId}&rankType=${type}`)
      // window.location
    },
    initRecord() {
      this.recordLoading = true
      this.getSceneAllotRecordList(this.sceneId).then(
        res => {
          this.recordLoading = false
          this.recordList = res
        },
        () => {
          this.recordLoading = false
        }
      )
    },
    onShowDownToast(openKey = '') {
      this.openObj = { ...this.openObj, [openKey]: true }
    },
    onJumpDownCenter() {
      this.$router.push('/download/center')
    },
    onDown(id) {
      if (this.openObj[id] === 'loading') return
      this.openObj[id] = 'loading'
      const req = {
        codeAssignRecordId: id
      }
      this.onDownSceneAllotRecordData(req).then(
        res => {
          this.openObj[id] = false
          this.$message.success('数据下载成功，请前往下载中心查看')
          this.exportMsg = res.data.data
        },
        rea => {
          this.openObj[id] = false
          this.$message.error(rea.message)
        }
      )
    },
    onOpenRecallPop() {
      this.isShowRecallPop = true
    },
    init() {
      this.loading = true
      this.getLiveDetail(this.sceneId).then(
        res => {
          this.sceneDetail = res
          this.loading = false
          this.form.strategyId = res.strategyId
          this.form.strategyDisabled = !!res.strategyId
          this.initTag()
          // this.getCodeInfo()
          this.onCodeUsedStatistic()
          this.initSummary()
        },
        message => {
          this.loading = false
          this.$message({
            message: message,
            type: 'error'
          })
        }
      )
    },
    initSummary() {
      this.getLiveSummary(this.sceneId).then(
        res => {
          this.sceneSummary = res
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    initTag() {
      const groupIds = this.tagGroupList.map(v => v.id)
      const req = {
        memberIdList: [this.sceneId],
        userTagType: this.userTagType,
        tagType: 1,
        groupIds: groupIds
      }
      this.getSceneDetailTag(req).then(res => {
        this.disabledIds = []
        this.sceneTagList = res.map(v => {
          this.disabledIds.push(v.tagId)
          return { ...v, disabled: true }
        })
      })
    },
    // ------------------------ 预览 -------------------
    onCloseModal() {
      this.isShowRecallPop = false
      this.openObj = {}
      this.showTagModal = false
      this.isShowSharePop = false
      this.isShowOperationConfirmPop = false
      this.isShowGuestModal = false
    },
    onAllot() {
      if (!this.isShowBtn) {
        this.isShowEmpty = true
        return
      }
      this.$router.push(`/academic/scene/${this.sceneType}/rule/${this.sceneId}?disabledBack=${!this.sceneDetail.canEdit}`)
    },
    onEdit() {
      this.$router.push(`/academic/scene/${this.sceneType}/edit/${this.sceneId}`)
    },
    onOpenOperationConfirmPop() {
      this.message = this.sceneDetail.isRealCanDel ? '请确认是否删除，一经删除，不可恢复' : '请确认是否删除，删除后不可再分配，您也可以在个人中心回收站重新找回'
      this.isShowOperationConfirmPop = true
    },
    onCodeUsedStatistic() {
      this.getSceneCodeUsedStatistic(this.sceneId).then(
        res => {
          this.totalCodeCount = res.data.totalCodeCount
          this.beginCodeCount = res.data.beginCodeCount
          this.summaryData = [...this.summaryData].map((v, i) => {
            let name = ''
            if (i === 0) {
              name = `总活动任务：${res.data.totalCodeCount}个`
            } else if (i === 1) {
              name = `已开始任务：${res.data.beginCodeCount}个`
            } else if (i === 2) {
              name = `已完成学术任务：${res.data.finishedCodeCount}个`
            }
            v = { ...v, name: name }
            return v
          })
          this.getOption()
        },
        rea => {
          this.$message.error(rea.message)
        }
      )
    },

    onOpenSharePop() {
      this.isShowSharePop = true
    },
    onJumpConsole() {
      const routeUrl = this.$router.resolve(`/academic/live-console/${this.sceneId}/5`)
      window.open(routeUrl.href, '_blank')
    },
    onDel() {
      this.onDelLive(this.sceneId).then(
        () => {
          this.$message({
            message: '删除成功！',
            type: 'success'
          })
          this.$router.back()
          this.onCloseModal()
        },
        rea => {
          this.$message({
            message: rea,
            type: 'error'
          })
        }
      )
    }
  }
}
</script>

<style lang='scss' scoped>
.content-box {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 120px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.content-box::-webkit-scrollbar {
  width: 0 !important;
}
.card-body ::v-deep {
  padding: 12px 14px;
  flex-shrink: 0;
  position: relative;
  .el-tabs {
    margin-top: -32px;
  }
  .el-tabs__header {
    margin-left: -34px;
    margin-right: -34px;
    .el-tabs__nav-wrap {
      padding-left: 34px;
    }
  }
}
.table-box {
  min-height: 400px;
}
.row-t {
  padding-top: 40px;
  padding-bottom: 20px;
}
.co-t {
  padding-bottom: 5px;
}
.out-progress {
  width: 140px;
  height: 10px;
  background: #f0f0f0;
  border-radius: 7px;
}

.in-progress-doing {
  height: 10px;
  background: linear-gradient(270deg, #3dc8fb 0%, #417bf3 100%);
  border-radius: 7px;
}

.in-progress-end {
  height: 10px;
  background: linear-gradient(270deg, #00ea6e 0%, #14b4c2 100%);
  border-radius: 7px;
}
.video-cover {
  width: 107px;
  height: 60px;
  background: #d8d8d8;
  margin: auto;
}

.box-shadow {
  box-shadow: 2px -2px 4px 0px #e2e3e7;
}

.title-nav-box {
  position: relative;
  z-index: 10;
}

.scene-empty {
  color: rgba(119, 132, 161, 1);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 10px;
  }
}

.funnel {
  height:170px;
  width: 440px;
  flex-shrink: 0;
  position: relative;
}
.scene-tag {
  color: #3d61e3;
  background: rgba(61, 97, 227, 0.12);
  border-radius: 16px;
  height: 20px;
  flex-shrink: 0;
  line-height: 12px;
  padding: 4px 10px;
  margin-right: 10px;
}
.footer-box {
  height: 100px;
  padding-right: 120px;
  padding-left: 60px;
  display: flex;
  z-index: 1000;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #e9eaeb;
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  .el-button {
    margin-left: 20px;
  }
}
.icon-message-box {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 100;
  right: 15px;
}
.scene-cover {
  flex-shrink: 0;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
  width: 320px;
  height: 180px;
  background: #d8d8d8;
}
.time-wrapper {
  font-size: 12px;
  color: #fff;
  height: 22px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 5px;
  position: absolute;
  right: 0px;
  bottom: 0;
}
.qr-popover {
  width: 150px;
  height: 150px;
}
.title-content {
  background: rgba(245, 245, 245, 1);
  padding: 10px;
  color: rgba(150, 150, 150, 1);
  font-size: 12px;
}
.data-analysis-box {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
