<template>
  <div class="live-console">
    <page-main v-loading="loading" :title="`${typeText}频道 - ${details.title || ''}`">
      <div class="content">
        <div class="left">
          <div class="live-view">
            <div v-if="!details.showTip" class="video-box">
              <LivePlayer
                v-if="(details.playAddr && details.playAddr.length) || details.recordPlayVo"
                ref="live-player"
                :type="details.liveStatus >= 2 ? 'playback' : 'live'"
                :video-url="details.recordPlayVo"
                :live-url="details.playAddr"
                @ended="onPlayerEnder"
                @pause="onPlayerPause"
                @error="onPlayError"
                @play="onPlayerPlay"
              >
                <template #cover="slotProp">
                  <div
                    v-if="slotProp.playEnd"
                    class="live-state-time"
                    :style="`background-image: url(${details.waitingImg.normal})`"
                    @click="$refs['live-player'].dp.play()"
                  >
                    <p class="live-state">{{ playerErrorText(slotProp.playEnd) }}</p>
                  </div>
                </template>
              </LivePlayer>
              <!-- 红包倒计时 -->
              <div v-if="packet.packetLeftTime > 0" class="live-tag-left">
                <img :src="require('@/assets/live/icon_packet.png')" alt="">
                {{ packet.packetLeftTime || 0 }}s后开抢
              </div>
              <!-- 播放中图标 -->
              <div v-if="details.liveStatus === 1" class="live-tag-right"><img :src="require('@/assets/live/icon_living.png')" alt=""></div>
            </div>
            <div v-else class="video-tip">
              <p>录播处理中，可点回放剪辑按钮进行视频剪辑</p>
              <span>{{ typeText }}时长：{{ details.totalDuration }} 总观看人数：{{ onLineData.viewPeopleCount }}</span>
            </div>
            <div class="control">
              <div class="btn-wrap">
                <el-popover v-model="stateVisible" placement="top">
                  <div class="live-handle" style="padding: 10px;">
                    <p style="margin-bottom: 10px;">确认开始{{ typeText }}？</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button size="medium" @click="stateVisible = false">暂时不了</el-button>
                      <el-button type="primary" size="medium" @click="handleLive('START')">开始{{ typeText }}</el-button>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    class="del-btn"
                    :disabled=" details.liveStatus === 1 || details.sceneType === 6 && details.liveStatus !== 0 || details.sceneType === 5 && !details.canStart"
                    plain
                  >
                    开始{{ typeText }}
                  </el-button>
                </el-popover>
                <el-popover v-model="stopVisible" placement="top">
                  <div class="live-handle" style="padding: 10px;">
                    <p style="margin-bottom: 10px;">确认结束{{ typeText }}</p>
                    <div style="text-align: right; margin: 0;">
                      <el-button size="medium" @click="stopVisible = false">暂时不了</el-button>
                      <el-button type="primary" size="medium" @click="handleLive('STOP')">结束{{ typeText }}</el-button>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    class="del-btn"
                    :disabled="details.liveStatus > 2 || details.liveStatus === 0 || details.showTip"
                    plain
                  >
                    <svg-icon name="forbid" /> 结束{{ typeText }}
                  </el-button>
                </el-popover>
                <el-button
                  plain
                  class="del-btn"
                  :disabled="details.liveStatus === 0 && !details.showTip"
                  @click="showLivePlayback = true"
                >
                  回放剪辑
                </el-button>
              </div>
              <p>
                打开/关闭回放：
                <el-switch
                  :value="details.replayOpen"
                  active-color="#3D61E3"
                  inactive-color="#eeeeee"
                  @change="changeReplay"
                />
              </p>
            </div>
            <div class="share-type">
              <div class="live-link">
                <p class="label live-href">医生端观看地址：</p>
                <el-input :value="details.doctorClientUrl" disabled />
                <el-button type="text" @click="copyLink(details.doctorClientUrl)">复制链接</el-button>
              </div>
              <el-popover placement="top" width="250" trigger="hover">
                <div class="popover-content">
                  <img :src="details.doctorClientQRCode" alt="">
                  <p class="title">扫码到手机上预览</p>
                </div>
                <el-button slot="reference" type="text" class="share-mobile"> <svg-icon class="phone-icon" name="el-icon-mobile-phone" />手机观看 </el-button>
              </el-popover>
            </div>

            <div class="share-type">
              <div class="live-link">
                <p class="label live-href">代表端观看地址：</p>
                <el-input :value="details.representClientUrl" disabled />
                <el-button type="text" @click="copyLink(details.representClientUrl)">复制链接</el-button>
              </div>
              <el-popover placement="top" width="250" trigger="hover">
                <div class="popover-content">
                  <img :src="details.representClientQRCode" alt="">
                  <p class="title">扫码到手机上预览</p>
                </div>
                <el-button slot="reference" type="text" class="share-mobile"> <svg-icon class="phone-icon" name="el-icon-mobile-phone" />手机观看 </el-button>
              </el-popover>
            </div>

            <div class="live-link" style="grid-template-columns: 70px 1fr auto; flex: 1; gap: 0 8px;">
              <p>{{ typeText }}地址：</p>
              <el-input :value="liveHref" disabled />
              <el-button type="text" @click="copyLink(liveHref)">复制链接</el-button>
            </div>
            <div v-if="search.sceneType === '5'" class="flex" style="margin-top: 7px;">
              <div class="live-link" style="grid-template-columns: 70px 1fr auto; flex: 1; gap: 0 8px; margin-right: 20px;">
                <p>推流地址：</p>
                <el-input :value="details.pushAddrPrefix" disabled />
                <el-button type="text" @click="copyLink(details.pushAddrPrefix)">复制链接</el-button>
              </div>
              <div class="live-link" style="grid-template-columns: 60px 1fr auto; flex: 1; gap: 0 8px;">
                <p class="label live-href">推流码：</p>
                <el-select v-model="presentersName">
                  <el-option
                    v-for="item in details.speakers"
                    :key="item.userId"
                    :value="details.pushAddrSuffix + '&userId=' + item.userId"
                    :label="item.name"
                  />
                </el-select>
                <el-button type="text" @click="copyPushCode(presentersName)">复制链接</el-button>
              </div>
            </div>
          </div>
          <div class="special-entrance">
            <p class="title">嘉宾快速登录链接</p>
            <el-scrollbar class="scrollwrapper">
              <div size="small" class="link-wrap">
                <div v-for="item in details.speakers" :key="item.id" class="live-link link-item">
                  <p class="label">{{ item.name }}</p>
                  <el-input :value="item.fastJoinUrl" disabled />
                  <el-button type="text" @click="copyLink(item.fastJoinUrl)">复制链接</el-button>
                </div>
              </div>
            </el-scrollbar>
          </div>
          <div v-if="details.sceneType === 6" class="special-entrance">
            <p class="title">主持人快速登录链接</p>
            <el-scrollbar class="scrollwrapper">
              <div size="small" class="link-wrap">
                <div v-for="item in details.chairs" :key="item.id" class="live-link link-item">
                  <p class="label">{{ item.name }}</p>
                  <el-input :value="item.fastJoinUrl" disabled />
                  <el-button type="text" @click="copyLink(item.fastJoinUrl)">复制链接</el-button>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <div class="right">
          <online-info
            v-if="details.chatRoomId"
            :live-status="details.liveStatus"
            :speakers-list="details.speakers"
            :chat-room-id="details.chatRoomId"
            :on-line-data="onLineData"
            :bc="details.bc"
            :room-able="details.chatRoomConnectAble"
          />
        </div>
      </div>
    </page-main>
    <!-- tabs -->
    <el-tabs v-model="activeName" class="bottom-tabs">
      <el-tab-pane lazy label="红包记录" name="packet">
        <packet
          v-if="activeName === 'packet'"
          :live-title="details.title"
          :options="jobTitleList"
          :get-packet-time="getPacketTime"
          :red-packet-id="packet.redPacketId"
        />
      </el-tab-pane>
      <el-tab-pane lazy label="医生端观看记录" name="watch-list">
        <watch-list v-if="activeName === 'watch-list'" :options="jobTitleList" />
      </el-tab-pane>
      <el-tab-pane lazy label="代表端观看记录" name="represent-watch-list">
        <represent-watch-list v-if="activeName === 'represent-watch-list'" />
      </el-tab-pane>
      <el-tab-pane
        v-if="details.isPublic !== 1"
        lazy
        label="观看白名单"
        name="ordinary-list"
      >
        <ordinary-list v-if="activeName === 'ordinary-list'" :options="jobTitleList" />
      </el-tab-pane>
    </el-tabs>

    <LivePlayback v-model="showLivePlayback" :live-status="details.liveStatus" />
  </div>
</template>

<script>
import Packet from './components/packet.vue'
import WatchList from './components/watch-list.vue'
import RepresentWatchList from './components/represent-watch-list copy.vue'
import OrdinaryList from './components/ordinary-list.vue'
import LivePlayback from './components/live-playback/index.vue'
import OnlineInfo from './components/online-info.vue'

import { getLiveConsoleDetail, replayLive, handleLiveStatus, getImEnvelope, getDynamicData, getJobTitle, recordLiveTime, setRecordingRule } from '@/services/liveService'
import { useSendMsg } from '../tim/components/MsgSendBox/useSendMsg'
export default {
  name: 'LiveConsole',
  components: {
    Packet,
    WatchList,
    OrdinaryList,
    LivePlayback,
    OnlineInfo,
    RepresentWatchList
  },
  data() {
    return {
      recordTime: 0,
      packet: { packetLeftTime: 0, redPacketId: '' },
      activeName: 'packet',
      loading: false,
      showLivePlayback: false,
      stateVisible: false,
      stopVisible: false,
      errorText: '',
      jobTitleList: [],
      presentersName: '',
      details: {
        replayOpen: false,
        liveStatus: 0 // 0 即将开始 1 直播中 2 录播处理中 3 直播回放
      },
      search: {
        liveId: '',
        sceneType: 5
      },
      onLineData: {},
      playVideo: {
        rtmp: '',
        m3u8: '',
        flv: '',
        live: false, // h5_flv:true,
        autoplay: true,
        width: '100%',
        height: '100%',
        poster: { style: 'stretch', src: require('@/assets/live/video-bg.png') }
      },
      dataTimer: null,
      isRecordingTime: false,
      codeId: ''
    }
  },
  computed: {
    playerErrorText() {
      return liveStatus => {
        switch (this.details?.liveStatus) {
          case 0:
            return `${this.typeText}开始时间：${this.details.startAt}`
          case 1:
            return liveStatus ? '主播不在，先在直播间聊聊天吧~ ' : ''
          case 2:
            return `您观看的${this.typeText}已结束`
          default:
            break
        }
      }
    },
    playerContainer() {
      return this.$refs.playerContainer
    },
    liveHref() {
      const obj = {
        localhost: 'https://live.yaomaitong.net',
        'factory-dev.yaomaitong.net': 'https://live.yaomaitong.net',
        'factory-dev2.yaomaitong.net': 'https://live.yaomaitong.net',
        'factory.yaomaitong-pre.cn': 'https://live.yaomaitong-pre.cn',
        'factory.quxueshu.com': 'https://live.quxueshu.com'
      }
      return obj[window.location.hostname] || obj['localhost']
    },
    typeText() {
      return this.details.sceneType === 5 ? '直播' : this.details.sceneType === 6 ? '会议' : ''
    }
  },
  mounted() {
    this.search.liveId = this.$route.params.liveId
    this.search.sceneType = this.$route.params.sceneType
    this.getData()

    this.getOptionsList()
    this.getPacketTime()

    this.getOnLineNum()

    this.dataTimer = setInterval(() => {
      this.getOnLineNum()
    }, 5000)
  },
  beforeDestroy() {
    this.errorPlayTimer && clearInterval(this.errorPlayTimer)
    this.dataTimer && clearInterval(this.dataTimer)
    this.recordTimer && clearTimeout(this.recordTimer)
    this.timer && clearInterval(this.timer)
    this.recordTime && this.sendRecordTime()
    this.updateLiveStatusTimer && clearInterval(this.updateLiveStatusTimer)
  },
  methods: {
    /**
     * 获取中控台详情
     * 调用时机：初始化页面时
     */
    async getData() {
      try {
        const params = Object.assign({}, this.search)
        this.loading = true
        const { data } = await getLiveConsoleDetail(params)
        data.chairs = data.speakers.filter(item => item.speakerType === 0)
        data.speakers = data.speakers.filter(item => item.userType !== 2)
        this.details = data || {}
        this.$route.meta.title = this.details.title

        const options = {}
        if (this.details.liveStatus == 3 && this.details.recordPlayVo) {
          // 3结束
          const hlsObj = this.details.recordPlayVo.hls || {}
          const mp4Obj = this.details.recordPlayVo.mp4 || {}

          options.m3u8_hd = hlsObj.hd || ''
          options.m3u8_sd = hlsObj.sd || ''
          options.mp4_hd = mp4Obj.hd || ''
          options.mp4_sd = mp4Obj.sd || ''
          options.poster = null
          options.autoplay = false
        } else if (this.details.liveStatus < 2) {
          // 0未开始和1开始中
          this.details.playAddr?.map(item => {
            if (item.indexOf('.flv') > -1) {
              options['flv'] = item
            }
            if (item.indexOf('.m3u8') > -1) {
              options['m3u8'] = item
            }
            if (item.indexOf('rtmp') > -1) {
              options['rtmp'] = item
            }
            options.poster = null
            options.autoplay = true
          })
        }
        options.live = this.details.liveStatus == 1

        this.playVideo = { ...this.playVideo, ...options }
        const { speakers = [], pushAddrSuffix } = this.details
        if (speakers.length) {
          this.$store.commit('tim/user/setSpeakerList', speakers)
          const [isPresenterItem = {}] = speakers.filter(item => item.isPresenter)
          this.presentersName = `${pushAddrSuffix}&userId=${isPresenterItem.userId}`
          const qresenterIDList = speakers
            .map(item => {
              return `${item.userType}_${item.userId}`
            })
            .filter(v => v)
          this.$store.commit('tim/user/setQresenterIDList', qresenterIDList)
        }

        this.details.showTip = !!(this.details.liveStatus > 1 && !this.details.recordPlayVo && !this.details.playAddr)

        this.details.totalDuration = this.timeToMinute(this.details.duration)
        this.$store.commit('tim/settings/setLiveStatus', this.liveStatus)
        this.updateLiveStatus()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.$message({
          type: 'error',
          message: error.message
        })
      }
    },
    onPlayResize() {
      if (this.stopResizeEvent || !this.playerContainer || this.playerContainer.playing() || this.details.liveStatus != 1) return
      this.playerContainer.play() // 直播中 http刷新 无法自动播放
    },
    /**
     * 播放时间发生变化（即正在播放中）
     * 调用时机：开始播放后
     */
    onPlayTimeupdate() {
      if (this.playerContainer.playing() && this.errorText) {
        this.errorText = ''
      }
      if (!this.stopResizeEvent && this.details.liveStatus == 1) this.stopResizeEvent = true
    },
    /**
     * 开始播放、记录观看时间
     * 调用时机：点击播放按钮
     */
    onPlayerPlay() {
      // mp4主动play 直播中也会出发play事件
      // this.playerContainer.play()
      this.watchTime()
    },
    /**
     * 清除定时器、暂停视频
     * 调用时机：点击暂停按钮
     */
    onPlayerPause() {
      // mp4主动pause 直播中不会出发pause事件 需要通过error来暂停定时器
      // this.playerContainer.pause()
      this.sendRecordTime()
      this.clearRecordTimer()
    },
    /**
     * 视频播放结束
     * 调用时机：回方结束或直播结束
     */
    onPlayerEnder() {
      // 结束清除定时器
      this.sendRecordTime()
      this.clearRecordTimer()
    },
    /**
     * 记录观看时长
     * 调用时机：直播开始或回放开始
     */
    watchTime() {
      if (this.details.liveStatus < 1) return
      if (this.recordTimer) return
      this.recordTimer = setTimeout(() => {
        this.recordTime++
        if (this.recordTime > 9) {
          this.sendRecordTime()
        }

        this.clearRecordTimer()
        this.watchTime()
      }, 1000)
    },
    /**
     * 修改直播状态
     * 调用时机：点击开始或结束直播时
     */
    async editLiveStatus(type) {
      this.stateVisible = false
      this.stopVisible = false
      const params = {
        liveId: this.search.liveId,
        action: type
      }
      try {
        this.loading = true
        await handleLiveStatus(params)
        let msg = this.typeText
        this.loading = false
        if (type === 'START') {
          msg = `开始${msg}成功`
          this.details.liveStatus = 1
        } else if (type === 'STOP') {
          const msgParams = {
            type: 'createCustomMessage',
            payload: {
              description: ''
            }
          }
          msg = `结束${msg}成功`
          this.details.liveStatus = 2
          msgParams.payload.description = 'live_end_message'
          const { onSend } = useSendMsg(msgParams)
          onSend()
        }
        this.$message({
          type: 'success',
          message: msg
        })
        this.getData()
      } catch (error) {
        this.loading = false
        if (error.code === 12055) {
          this.$confirm(`您已开启了${this.typeText}倒计时，无法提前开始${this.typeText}，如有${this.typeText}开始时间变动，请前往修改${this.typeText}时间或者关闭${this.typeText}倒计时设置。`, '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            center: true,
            showCancelButton: false
          })
        } else {
          this.$message({
            type: 'error',
            message: error.message
          })
        }
      }
    },
    /**
     * 开始直播
     * 调用时机：点击开始直播
     */
    handleLive(type) {
      if (type === 'START') {
        // if (this.details.liveStatus != 0) return this.$message.info(`当前${this.typeText}状态不允许进行此操作`)
      } else if (type === 'STOP') {
        if (this.details.liveStatus != 1) return this.$message.info(`当前${this.typeText}状态不允许进行此操作`)
      }
      this.editLiveStatus(type)
    },
    /**
     * 创建红包倒计时、获取聊天室红包信息
     * 调用时机：发送红包后
     */
    async getPacketTime() {
      try {
        const params = {
          liveId: this.search.liveId
        }
        const { data } = await getImEnvelope(params)
        let flag = false
        data.redPacketVOS.map(item => {
          if (item.packetType === 0) {
            this.packet.packetLeftTime = Number.parseInt((item.sendTime - new Date().getTime()) / 1000)
            this.packet.redPacketId = item.redPacketId
            flag = true
          }
        })
        if (flag) {
          if (this.packet.packetLeftTime > 0) {
            if (this.timer) return
            this.timer = setInterval(() => {
              if (this.packet.packetLeftTime < 0) {
                clearInterval(this.timer)
                this.timer = null
                return
              }
              this.packet.packetLeftTime = this.packet.packetLeftTime - 1
            }, 1000)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 打开回放
    changeReplay(e) {
      replayLive({ liveId: this.search.liveId }).then(
        () => {
          this.details.replayOpen = e
        },
        rea => {
          this.$message.error(rea)
        }
      )
    },
    /**
     * 获取医生职称
     * 调用时机：初始化是调用，传给三个组件使用
     */
    async getOptionsList() {
      try {
        const { data } = await getJobTitle()
        this.jobTitleList = data || []
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 获取直播在线人数
     * 调用时机：进入页面开始，5秒一次
     */
    async getOnLineNum() {
      try {
        const params = { liveId: this.search.liveId }
        const { code, data } = await getDynamicData(params)
        if (code === 0) {
          this.onLineData = data
        }
      } catch (error) {
        console.log(error)
      }
    },
    /**
     * 复制内容
     * 调用时机：点击复制链接时
     */
    copyLink(copyText) {
      this.$copyText(copyText).then(
        () => {
          this.$message({
            message: '复制成功',
            type: 'success'
          })
        },
        () => {
          this.$message({
            showClose: true,
            message: '复制失败，请手动复制',
            type: 'error'
          })
        }
      )
    },

    copyPushCode(copyText) {
      this.copyLink(copyText)
      // 设置推流录制
      // 后端原先依赖回调，现在要求加一个主动的设置，不阻碍页面操作
      setRecordingRule({liveId: this.search.liveId}).then(() => {
        console.log('推流录制设置成功')
      }).catch(err => {
        console.log(err)
      })
    },

    // 秒转换分钟00:00:00格式
    timeToMinute(times) {
      let t
      if (times > -1) {
        const hour = Math.floor(times / 3600)
        const min = Math.floor(times / 60) % 60
        const sec = times % 60
        t = hour < 10 ? `0${hour}:` : `${hour}:`

        if (min < 10) {
          t += '0'
        }
        t += `${min}:`
        if (sec < 10) {
          t += '0'
        }
        t += sec.toFixed(2)
      }
      t = t.substring(0, t.length - 3)
      return t
    },

    // 资源加载失败
    onPlayError() {
      if (this.recordTime > 0) {
        this.sendRecordTime()
      }
      this.clearRecordTimer()
    },
    /**
     * 清除直播记录时长定时器
     * 调用时机：页面销毁或直播资源加载失败
     */
    clearRecordTimer() {
      this.recordTimer && clearTimeout(this.recordTimer)
      this.recordTimer = null
    },
    /**
     * 记录观看时长
     * 调用时机：直播正在播放时
     */
    sendRecordTime() {
      const seconds = this.recordTime > 60 ? 10 : this.recordTime

      if (seconds < 1) return
      // 记录观看时长
      const params = {
        liveId: this.search.liveId,
        recordId: this.details.viewRecordId,
        seconds: seconds,
        codeId: this.codeId || ''
      }
      if (this.isRecordingTime) return
      this.isRecordingTime = true

      recordLiveTime(params).then(
        () => {
          this.isRecordingTime = false
          this.recordTime = 0
        },
        rea => {
          console.log(`记录观看时长失败:${rea}`)
          this.isRecordingTime = false
          this.recordTime += seconds
        }
      )
    },
    updateLiveStatus() {
      this.updateLiveStatusTimer = setInterval(() => {
        if (this.details.liveStatus !== 0) return
        if (!this.details.autoStart) return
        const nowDate = this.$moment(new Date())
        const startAt = this.$moment(this.details.startAt)

        if (startAt.diff(nowDate, 'seconds', true) < 0) {
          this.details.liveStatus = 1
          clearInterval(this.updateLiveStatusTimer)
        }
      }, 5000)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.live-console {
  box-sizing: border-box;
  .content {
    display: flex;
    flex-flow: wrap;
    .left {
      flex: 4;
      border-right: 1px solid #eee;
      .live-view {
        padding: 20px;
        .control {
          margin-top: 20px;
          margin-bottom: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .btn-wrap {
            display: grid;
            grid-template-columns: repeat(3, auto);
            gap: 0 10px;
            .del-btn {
              width: 140px;
            }
          }
        }
        .share-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .live-link {
            display: flex;
            flex: 1;
            max-width: 700px;
            gap: 0;
            .live-href {
              width: 160px;
            }
            .el-input {
              margin-right: 20px;
            }
          }
          ::v-deep .share-mobile {
            > span {
              display: flex;
              align-items: center;
              .phone-icon {
                font-size: 26px;
                margin-right: 5px;
              }
            }
          }
        }
        .live-tag-left {
          position: absolute;
          top: 10px;
          left: 10px;
          display: flex;
          align-items: center;
          color: #fff;
        }
        .live-tag-right {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .live-state-time {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
          color: #fff;
          z-index: 10;
          .live-state {
            width: 100%;
            height: 60px;
            position: absolute;
            bottom: 0;
            text-align: right;
            line-height: 60px;
            padding-right: 20px;
            font-size: 18px;
            font-weight: bold;
            background: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.5) 58%, rgba(0, 0, 0, 0.8));
          }
        }
        .video-box {
          width: 100%;
          height: 500px;
          min-width: 400px;
          background-size: cover;
          background-color: #000;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          ::v-deep video {
            width: 100%;
            height: 100%;
          }
        }
        .video-tip {
          width: 100%;
          height: 500px;
          min-width: 400px;
          font-size: 24px;
          color: #fff;
          background-color: #4a4a4a;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > p {
            margin-bottom: 20px;
          }
          > span {
            font-size: 18px;
          }
        }
      }
      .special-entrance {
        margin-left: 20px;
        padding: 15px 0;
        border-top: 1px solid #eee;
        .title {
          font-size: 16px;
          margin-top: 10px;
          font-family: 'PingFangSC-Medium', 'PingFang SC', sans-serif;
          font-weight: 500;
          color: #0f0f0f;
          margin-bottom: 10px;
        }
        .scrollwrapper {
          height: 225px;
          .label {
            max-width: 100px;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .link-wrap {
      padding-right: 30px;
    }
    .live-link {
      display: grid;
      grid-template-columns: 100px 1fr 70px;
      align-items: center;
      gap: 0 20px;
      &.link-item {
        margin: 10px 0;
      }
      .el-input {
        justify-self: start;
      }
    }
    .right {
      min-height: 500px;
      flex: 2.5;
      flex-shrink: 0;
    }
  }
}
::v-deep .edit-online-num {
  .dialog-footer {
    text-align: center;
  }
  .input {
    input {
      border: none;
      border-radius: 0;
      padding-left: 0;
      border-bottom: 1px solid #ddd;
    }
  }
  .note {
    font-size: 14px;
    font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
    font-weight: 400;
    color: #b4b4b4;
    line-height: 22px;
  }
}
::v-deep .el-tabs__nav-wrap {
  .el-tabs__nav-scroll {
    padding: 0 30px;
  }
  &::after {
    height: 1px;
  }
}
.bottom-tabs {
  margin: 10px;
  background-color: #fff;
  ::v-deep .el-tabs__content {
    padding: 10px 35px 25px 35px;
  }
}
.popover-content {
  width: 100%;
  text-align: center;
  img {
    width: 100%;
    object-fit: cover;
  }
  .title {
    font-size: 18px;
    font-weight: 600;
  }
}
</style>
