import Layout from '@/layout'

export default {
  path: '/academic-saas',
  component: Layout,
  meta: {
    title: '学术SaaS',
    icon: 'academicsaas'
    // auth: ['CustomerCRM']
  },
  children: [
    {
      path: '/material',
      name: 'Material',
      redirect: '/material/list',
      meta: {
        title: '素材管理',
        auth: ['materialNav'],
        sidebar: true
      }
    },
    {
      path: '/material/list',
      name: 'MaterialList',
      component: () => import(/* webpackChunkName: "Material" */ '@/views/material/List'),
      meta: {
        title: '素材管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/material',
        cache: ['KnowledgeCreate', 'KnowledgeEdit', 'EditQuestion', 'EditExam', 'DataAnalysis'],
        auth: ['materialNav']
      }
    },
    {
      path: '/material/knowledge',
      name: 'Knowledge',
      component: () => import(/* webpackChunkName: "Material" */ '@/layout/empty'),
      meta: {
        sidebar: false,
        activeMenu: '/material',
        auth: ['materialNav']
      },
      children: [{
        path: 'create',
        name: 'KnowledgeCreate',
        component: () => import(/* webpackChunkName: "Material" */ '@/views/material/TabKnowledgeEdit'),
        meta: {
          title: '创建文献',
          activeMenu: '/material',
          auth: ['materialNav']
        }
      }, {
        path: 'edit',
        name: 'KnowledgeEdit',
        component: () => import(/* webpackChunkName: "Material" */ '@/views/material/TabKnowledgeEdit'),
        meta: {
          title: '编辑文献',
          activeMenu: '/material',
          auth: ['materialNav']
        }
      }]
    },
    {
      path: '/material/edit/exam',
      name: 'EditExam',
      component: () => import(/* webpackChunkName: "Material" */ '@/views/material/EditExam'),
      meta: {
        title: '新建题目',
        sidebar: false,
        activeMenu: '/material',
        auth: ['materialNav']
      }
    },
    // ===== 活动管理 =====
    {
      path: '/academic',
      name: 'Academic',
      redirect: '/academic/list',
      component: Layout,
      meta: {
        title: '活动管理',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/list',
      name: 'AcademicList',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/AcademicList.vue'),
      meta: {
        requiresAuth: true,
        title: '活动管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        cache: ['CreatePodcast', 'CreateSurvey', 'CreatePureSurvey', 'EditSurvey', 'PodcastDetail', 'CreatePodcastTask', 'MeetingDetail', 'LiveDetail', 'WebinarDetail', 'QuestionnaireDetail', 'VisitDetail', 'AcademicRule', 'CommonList', 'CommonMaterial', 'CreateVisit', 'EditVisit'],
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/common/list',
      name: 'CommonList',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/CommonList.vue'),
      meta: {
        requiresAuth: true,
        title: '公共资源库',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        cache: ['MeetingDetail', 'LiveDetail', 'WebinarDetail', 'QuestionnaireDetail', 'VisitDetail', 'AcademicRule', 'CommonMaterial'],
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/common/material',
      name: 'CommonMaterial',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/CommonMaterial.vue'),
      meta: {
        requiresAuth: true,
        title: '学术资料数据',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/meeting/detail/:sceneId',
      name: 'MeetingDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/MeetingDetail.vue'),
      meta: {
        requiresAuth: true,
        title: '科会详情',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/live',
      name: '',
      component: () => import(/* webpackChunkName: "Academic" */ '@/layout/empty'),
      meta: {
        title: '直播',
        sidebar: false,
        auth: ['academicNav']
      },
      children: [{
        path: 'approve',
        name: 'LiveApprove',
        component: () => import(/* webpackChunkName: "Academic" */ '@/layout/empty'),
        redirect: '/academic/live/approve/list',
        meta: {
          sidebar: false,
          activeMenu: '/academic'
        },
        children: [
          {
            path: 'detail/:id',
            name: 'LiveApproveDetail',
            component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/LiveApproveForm.vue'),
            meta: {
              title: '直播审批详情',
              sidebar: false,
              activeMenu: '/academic'
            }
          }
        ]
      }]
    },
    {
      path: '/academic/live/detail/:sceneId',
      name: 'LiveDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/LiveDetail'),
      meta: {
        requiresAuth: true,
        title: '直播详情',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/webinar/detail/:sceneId',
      name: 'WebinarDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/WebinarDetail'),
      meta: {
        requiresAuth: true,
        title: '会议详情',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/questionnaire/detail/:sceneId',
      name: 'QuestionnaireDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/QuestionnaireDetail'),
      meta: {
        requiresAuth: true,
        title: '调研详情',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/academicVisit/detail/:sceneId',
      name: 'VisitDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/VisitDetail'),
      meta: {
        requiresAuth: true,
        title: '调研详情',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/questionnaire/jump/tree/:sceneId',
      name: 'QuestionnaireJumpTree',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/QuestionnaireJumpTree'),
      meta: {
        requiresAuth: true,
        title: '跳题逻辑',
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/live-console/:liveId/:sceneType',
      name: 'LiveConsole',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/live-console/index.vue'),
      meta: {
        requiresAuth: true,
        title: '直播频道',
        back: false,
        sidebar: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    {
      path: '/academic/scene',
      name: 'AcademicSceneLayout',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/AcademicSceneLayout'),
      meta: {
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      },
      children: [
        // {
        //   path: 'meeting/edit/:id',
        //   name: 'EditMeeting',
        //   component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditMeeting'),
        //   meta: {
        //     requiresAuth: true,
        //     title: '科会编辑',
        //     sidebar: false,
        //     activeMenu: '/academic'
        //   }
        // },
        {
          path: 'MEDICAL_PODCAST/task/create',
          name: 'CreatePodcastTask',
          // component: () => import(/* webpackChunkName: "Academic" */'@/views/academic/directional-live/create'),
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditPodcastTask'),
          meta: {
            requiresAuth: true,
            title: '创建播客任务',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'MEDICAL_PODCAST/edit/:id',
          name: 'EditPodcastTask',
          // component: () => import(/* webpackChunkName: "Academic" */'@/views/academic/directional-live/create'),
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditPodcastTask'),
          meta: {
            requiresAuth: true,
            title: '播客任务编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'live/create',
          name: 'CreateLive',
          // component: () => import(/* webpackChunkName: "Academic" */'@/views/academic/directional-live/create'),
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditLive'),
          meta: {
            requiresAuth: true,
            title: '直播创建',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'live/edit/:id',
          name: 'EditLive',
          // component: () => import(/* webpackChunkName: "Academic" */'@/views/academic/directional-live/create'),
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditLive'),
          meta: {
            requiresAuth: true,
            title: '直播编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'webinar/create',
          name: 'CreateWebinar',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditWebinar'),
          meta: {
            requiresAuth: true,
            title: '会议创建',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'webinar/edit/:id',
          name: 'EditWebinar',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditWebinar'),
          meta: {
            requiresAuth: true,
            title: '会议编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'questionnaire/create',
          name: 'CreateQuestionnaire',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditQuestionnaire'),
          meta: {
            requiresAuth: true,
            title: '病例创建',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'questionnaire/edit/:id',
          name: 'EditQuestionnaire',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditQuestionnaire'),
          meta: {
            requiresAuth: true,
            title: '调研编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'survey/create',
          name: 'CreateSurvey',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditSurvey'),
          meta: {
            requiresAuth: true,
            title: '调研创建',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'survey/edit/:id',
          name: 'EditSurvey',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditSurvey'),
          meta: {
            requiresAuth: true,
            title: '调研编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'academicVisit/create',
          name: 'CreateVisit',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditVisit.vue'),
          meta: {
            requiresAuth: true,
            title: '拜访创建',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: 'academicVisit/edit/:id',
          name: 'EditVisit',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/EditVisit'),
          meta: {
            requiresAuth: true,
            title: '拜访编辑',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: ':sceneType/rule/:id',
          name: 'AcademicRule',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/AcademicRule.vue'),
          meta: {
            requiresAuth: true,
            title: '任务规则',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: ':sceneType/entrust/:sceneId',
          name: 'AcademicEntrust',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/AcademicEntrust.vue'),
          meta: {
            requiresAuth: true,
            title: '任务规则',
            sidebar: false,
            activeMenu: '/academic'
          }
        },
        {
          path: ':sceneType/allot/:id',
          name: 'AcademicAllot',
          component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/AcademicAllot'),
          meta: {
            requiresAuth: true,
            title: '任务分配',
            sidebar: false,
            activeMenu: '/academic'
          }
        }
      ]
    },
    {
      path: '/product/label',
      name: 'ProductLabel',
      component: () => import(/* webpackChunkName: "productLabel" */ '@/views/productLabel/ProductLabel.vue'),
      meta: {
        requiresAuth: true,
        title: '标签管理',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        cache: ['ProductLabelDetail'],
        auth: ['academicNav']
      }
    },
    {
      path: '/product/detail/:id',
      name: 'ProductLabelDetail',
      component: () => import(/* webpackChunkName: "productLabel" */ '@/views/productLabel/ProductLabelDetail.vue'),
      meta: {
        requiresAuth: true,
        title: '标签详情',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/academic',
        auth: ['academicNav']
      }
    },
    // ===== 委托服务 =====
    {
      path: '/entrust',
      name: 'Entrust',
      redirect: '/entrust/list',
      component: Layout,
      meta: {
        title: '委托服务',
        auth: ['entrustNav']
      }
    }, {
      path: '/entrust/list',
      name: 'EntrustList',
      component: () => import(/* webpackChunkName: "Entrust" */ '@/views/entrust/EntrustList.vue'),
      meta: {
        requiresAuth: true,
        title: '委托服务',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/entrust',
        auth: ['entrustNav']
      }
    },
    {
      path: '/entrust/detail/:id',
      name: 'EntrustDetail',
      component: () => import(/* webpackChunkName: "Entrust" */ '@/views/entrust/EntrustDetail.vue'),
      meta: {
        requiresAuth: true,
        title: '委托服务详情',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/entrust',
        auth: ['entrustNav']
      }
    },
    // ===== 我的审批 =====
    {
      path: '/approver',
      name: 'Approver',
      redirect: '/approver/list',
      component: Layout,
      meta: {
        title: '我的审批',
        auth: ['approverNav']
      }
    }, {
      path: '/approver/list',
      name: 'ApproverList',
      component: () => import(/* webpackChunkName: "Approver" */ '@/views/approver/ApproverList.vue'),
      meta: {
        requiresAuth: true,
        title: '我的审批',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/approver',
        cache: ['ApproverDetail', 'LiveApprovalDetail'],
        auth: ['approverNav']
      }
    },
    {
      path: '/approver/detail/:id',
      name: 'ApproverDetail',
      component: () => import(/* webpackChunkName: "Approver" */ '@/views/approver/ApproverDetail.vue'),
      meta: {
        requiresAuth: true,
        title: '审批详情',
        sidebar: false,
        breadcrumb: false,
        activeMenu: '/approver',
        auth: ['approverNav']
      }
    },
    {
      path: '/approver/live/detail/:id',
      name: 'LiveApprovalDetail',
      component: () => import(/* webpackChunkName: "Academic" */ '@/views/academic/LiveApproveForm.vue'),
      meta: {
        title: '直播审批详情',
        sidebar: false,
        activeMenu: '/approver',
        auth: ['approverNav']
      }
    }
  ]
}
