<template>
  <div>
    <template v-for="item in schemeList">
      <BaseIndexVue
        v-if="changeData"
        :key="item.id"
        :item="item"
        :root="schemeList[0]"
      />
    </template>
  </div>
</template>

<script>
import { setRootItem } from '@/baseComponents/commonBase/baseUtils'
import apiObjs from '@/apis/api-objs'
import { isMobile } from '@/utils/util.js'

export default {
  name: 'ApproverList',
  data() {
    return {
      changeData: false,
      isMobile: isMobile(),
      // schemeList
      schemeList: [
        {
          id: this.$nanoid(),
          baseVue: 'BasePageMain',
          that: 'this',
          class: 'customize-page-main',
          data: {
            isShowSearch: true,
            isShowMore: true,
            isShowSceneType: true,
            isVisible: false,
            isVisibleAllot: false,
            isLoading: false,
            searchObj: {
              searchKey: '',
              auditStatus: 'wait',
              sceneType: null,
              productTagGroup: '',
              tagAndGroupIds: [],
              pageNo: 1,
              pageSize: 10,
              auditStartTime: null,
              auditEndTime: null,
              applyType: ''
            },
            initDate: [],
            approveType: 'task',
            tagItemList: {
              PRODUCT_TAG_GROUP: []
            },
            PRODUCT_TAG_GROUP_ID: '',
            tableData: [],
            disposableAmount: 0,
            total: 100,
            reason: '',
            tableSelectList: [],
            ordinaryList: [],
            directionList: [],
            passReqApiObj: {
              task: {
                ...apiObjs.passSceneTaskApply,
                data: val => {
                  return val.ids
                }
              },
              live: {
                ...apiObjs.batchSuccessLiveCreate,
                dataMaps: {
                  liveAuditRecordIds: 'ids'
                }
              }
            },
            rejectReqApiObj: {
              task: {
                ...apiObjs.rejectSceneTaskApply,
                dataMaps: {
                  sceneAuditRecordIds: 'ids'
                }
              },
              live: {
                ...apiObjs.rejectLiveCreate,
                dataMaps: {
                  liveAuditRecordIds: 'ids'
                }
              }
            }
          },
          init: function({ root, runInitFun }) {
            const { getTableDataList, setDate, getTagList, onGetTagGroup } = root.initFun
            runInitFun(setDate)
            runInitFun(getTableDataList)
            runInitFun(onGetTagGroup).then(res => {
              res.forEach(item => {
                runInitFun(getTagList, { tagGroupId: item.id, groupCode: item.groupCode })
              })
            })
          },
          initFun: {
            onGetTagGroup: function(options, { root, setRootItem }) {
              return this.$axios.get(this.$API.getTagGroup).then(res => {
                const { data = [] } = res
                const groupList = []
                const list = []
                data.forEach(v => {
                  if (v.groupCode === 'PRODUCT_TAG_GROUP') {
                    list[0] = v
                    groupList[0] = {
                      groupId: v.id,
                      tagIds: []
                    }
                  }
                })
                setRootItem(root, 'data.searchObj.tagAndGroupIds', groupList)
                return list
              }, rea => this.$message.error(rea.message))
            },
            // 请求获取分组标签列表
            getTagList: function({ tagGroupId, groupCode }, { setRootItem, root }) {
              setRootItem(root, `data.${groupCode}_ID`, tagGroupId)
              const params = { tagGroupId }
              this.$axios.get(this.$API.tagList, { params }).then(res => {
                const { data } = res
                const list = []
                data.map(item => {
                  list.push({ label: item.tagName, value: item.tagId })
                })
                setRootItem(root.data.tagItemList, groupCode, list)
              }, rea => this.$message.error(rea.message))
            },
            setDate: function(options, { root, setRootItem }) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              setRootItem(root.data.searchObj, 'auditStartTime', `${this.$moment(start).format('YYYY-MM-DD')} ` + '00:00:00')
              setRootItem(root.data.searchObj, 'auditEndTime', `${this.$moment(end).format('YYYY-MM-DD')} ` + '23:59:59')
              setRootItem(root.data, 'initDate', [start, end])
            },
            // 批量审核任务
            auditTaskList: function(options, { root, setRootItem }) {
              if (!root.data.tableSelectList.length) return this.$message.error('请勾选审批活动')
              setRootItem(root, 'data.isVisibleAllot', true)
            },
            // 批量审核直播
            auditLiveList: function(options, { root }) {
              if (!root.data.tableSelectList.length) return this.$message.error('请勾选审批活动')
              for (const item of root.data.tableSelectList) {
                if (!item.productTagName) return this.$message.error('有部分活动未选择产品标签')
              }
              setRootItem(root, 'data.isVisibleAllot', true)
            },
            // 设置请求参数
            setRequire: function(options, { root, setRootItem }) {
              for (const [key, value] of Object.entries(options || {})) {
                setRootItem(root.data.searchObj, key, value)
              }
            },
            newReqData: function(options, { root }) {
              const statusObj = { wait: 0, success: 1, fail: 2, commissionedPlatformReviewInProgress: 3 }
              const typeObj = { meeting: 1, questionnaire: 4, live: 5, webinar: 6, visit: 8 }
              const { auditStatus, sceneType, applyType } = root.data.searchObj
              const data = {
                ...root.data.searchObj,
                sceneType: typeObj[sceneType],
                auditStatus: statusObj[auditStatus],
                applyType: applyType ? Number(applyType) : null
              }
              const approveType = root.data.approveType
              let reqObj = {}
              const dataMaps = {
                sceneType: {
                  name: 'sceneType',
                  fun: val => {
                    return val || 5
                  }
                },
                auditStartTime: 'auditStartTime',
                auditEndTime: 'auditEndTime'
              }
              // 导出列表参数
              if (options?.type === 'down') {
                reqObj = {
                  'task': {
                    ...apiObjs.exportSceneAuditData
                  },
                  'live': {
                    ...apiObjs.exportLiveAuditData,
                    dataMaps
                  }
                }
                return { reqObj: reqObj[approveType], data }
              }
              // 列表参数
              reqObj = {
                'task': {
                  ...apiObjs.sceneAuditList
                },
                'live': {
                  ...apiObjs.liveAuditList,
                  dataMaps
                }
              }
              return { reqObj: reqObj[approveType], data }
            },
            // 请求table列表
            getTableDataList: function(options, { root, runInitFun }) {
              const { newReqData } = root.initFun
              const { reqObj, data } = runInitFun(newReqData)
              this.$requireApi(reqObj, data).then(res => {
                const { data, totalCount } = res.data || {}
                root.data.tableData = data
                root.data.total = totalCount
                root.data.reason = ''
              }, rea => this.$message.error(rea.message))
            }
          },
          childSchemeList: [
            // Tab 切换
            {
              id: this.$nanoid(),
              baseVue: 'BaseTabs',
              value: 'getRootItem(root,item.valueKey)',
              valueKey: 'data.approveType',
              tabList: [
                {
                  label: '任务申请审批',
                  name: 'task',
                  auth: 'acadSaas.myApproval.taskApplication'
                },
                {
                  label: '直播活动审批',
                  name: 'live',
                  auth: 'acadSaas.myApproval.liveProject'
                }
              ].filter(item => this.$auth(item.auth)),
              methods: {
                // eslint-disable-next-line no-unused-vars
                tabClick: function(options, { root, runInitFun, setRootItem }) {
                  setRootItem(root, 'data.approveType', options.value)
                  if (options.value === 'task') {
                    setRootItem(root, 'data.isShowSceneType', true)
                  } else {
                    setRootItem(root, 'data.isShowSceneType', false)
                  }
                  setRootItem(root, 'data.tableSelectList', [])
                  setRootItem(root, 'data.isShowSearch', true)

                  const { setRequire, getTableDataList, setDate } = root.initFun
                  runInitFun(setDate)
                  runInitFun(setRequire, {
                    pageNo: 1,
                    auditStatus: 'wait',
                    searchKey: '',
                    sceneType: null,
                    applyType: null,
                    productTagGroup: '',
                    tagAndGroupIds: root.data.searchObj.tagAndGroupIds.map(it => {
                      it.tagIds = []
                      return it
                    })
                  })
                  runInitFun(getTableDataList)
                }
              }
            },
            // eslint-disable-next-line multiline-ternary
            ...(this.$auth(['acadSaas.myApproval.taskApplication', 'acadSaas.myApproval.liveProject']) ? [
              // 审核状态切换
              {
                id: this.$nanoid(),
                baseVue: 'BaseButtonGroup',
                leftText: '审核状态：',
                key: 'data.searchObj.auditStatus',
                value: 'getRootItem(root, item.key)',
                buttonList: function({root}) {
                  return [
                    {
                      label: 'wait',
                      name: '待审核'
                    },
                    {
                      label: 'commissionedPlatformReviewInProgress',
                      name: '委托平台审核中'
                    },
                    {
                      label: 'success',
                      name: '审核成功'
                    },
                    {
                      label: 'fail',
                      name: '审核失败'
                    }
                  ].filter(item => {
                    if (root.data.approveType !== 'task') {
                      return item.label !== 'commissionedPlatformReviewInProgress'
                    }
                    return true
                  })
                },
                methods: {
                  click: function(options, { root, setRootItem, runInitFun }) {
                    setRootItem(root, 'data.isShowSearch', true)
                    setRootItem(root, 'data.tableSelectList', [])
                    const { setRequire, getTableDataList } = root.initFun
                    runInitFun(setRequire, { pageNo: 1, auditStatus: options.value })
                    runInitFun(getTableDataList)
                  }
                }
              },
              // 搜索条件
              {
                id: this.$nanoid(),
                baseVue: 'BaseRow',
                colCount: 2,
                style: {
                  marginTop: '10px'
                },
                colStyle: [
                  {},
                  {
                    textAlign: 'right',
                    justifyContent: 'flex-end'
                  }
                ],
                childSchemeList: [
                  {
                    id: this.$nanoid(),
                    isShow: 'getRootItem(root,item.isShowKey)',
                    isShowKey: 'data.isShowSearch',
                    columnIndex: 1,
                    baseVue: 'BaseRow',
                    colCount: 1,
                    colStyle: [
                      {
                        display: 'flex'
                      }
                    ],
                    childSchemeList: [
                      {
                        id: this.$nanoid(),
                        columnIndex: 1,
                        baseVue: 'BaseInput',
                        placeholder: '搜索申请人/活动名称',
                        value: 'getRootItem(root,item.key)',
                        key: 'data.searchObj.searchKey',
                        clearable: true,
                        style: {
                          width: '180px'
                        },
                        methods: {
                          input: function(options, { root, runInitFun }) {
                            const { setRequire, getTableDataList } = root.initFun
                            runInitFun(setRequire, { pageNo: 1, searchKey: options.value })
                            runInitFun(getTableDataList)
                          }
                        }
                      },
                      {
                        id: this.$nanoid(),
                        columnIndex: 1,
                        baseVue: 'BaseDatePicker',
                        key: 'data.initDate',
                        value: 'getRootItem(root, item.key)',
                        type: 'daterange',
                        clearable: false,
                        startPlaceholder: '开始时间',
                        endPlaceholder: '结束时间',
                        defaultTime: ['00:00:00', '23:59:59'],
                        leftText: function({ root }) {
                          return root.data.searchObj.auditStatus === 'wait' ? '申请时间：' : '审核时间：'
                        },
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        minDate: null,
                        maxDate: null,
                        pickerOptions: `function({item}) {
                        return {
                          disabledDate: function(time) {
                            if (item.minDate !== null && item.maxDate === null) {
                              const minMonth = item.minDate.getMonth()
                              const lastYear = new Date(item.minDate).setMonth(minMonth - 12)
                              const nextYear = new Date(item.minDate).setMonth(minMonth + 12)
                              return time.valueOf() < lastYear.valueOf() || time.valueOf() > nextYear.valueOf()
                            }
                            return false
                          },
                          onPick: function({minDate, maxDate}) {
                            item.minDate = minDate
                            item.maxDate = maxDate
                          }
                        }
                      }`,
                        style: {
                          marginLeft: '10px',
                          color: '#5a5a5a',
                          width: '340px'
                        },
                        methods: {
                          change: function(options, { root, runInitFun, setRootItem }) {
                            setRootItem(root, 'data.initDate', [options.value[0], options.value[1]])
                            const { setRequire, getTableDataList } = root.initFun
                            runInitFun(setRequire, { pageNo: 1, auditStartTime: options.value[0], auditEndTime: options.value[1] })
                            runInitFun(getTableDataList)
                          }
                        }
                      },
                      {
                        id: this.$nanoid(),
                        isShow: 'getRootItem(root,item.isShowKey)',
                        isShowKey: 'data.isShowSceneType',
                        columnIndex: 1,
                        baseVue: 'BaseSelect',
                        clearable: true,
                        placeholder: '活动类型',
                        style: {
                          width: '120px',
                          marginLeft: '10px'
                        },
                        value: 'getRootItem(root,item.key)',
                        key: 'data.searchObj.sceneType',
                        selectList: [
                          { label: '科会', value: 'meeting' },
                          { label: '调研/病例', value: 'questionnaire' },
                          { label: '直播', value: 'live' },
                          { label: '会议', value: 'webinar' }
                        ],
                        methods: {
                          change: function(options, { root, runInitFun }) {
                            const { setRequire, getTableDataList } = root.initFun
                            runInitFun(setRequire, { pageNo: 1, sceneType: options.value })
                            runInitFun(getTableDataList)
                          }
                        }
                      },
                      {
                        id: this.$nanoid(),
                        isShow: 'getRootItem(root,item.isShowKey)',
                        isShowKey: 'data.isShowSceneType',
                        columnIndex: 1,
                        baseVue: 'BaseSelect',
                        clearable: true,
                        placeholder: '申请任务类型',
                        style: {
                          width: '120px',
                          marginLeft: '10px'
                        },
                        value: 'getRootItem(root,item.key)',
                        key: 'data.searchObj.applyType',
                        selectList: [
                          { label: '普通任务', value: '1' },
                          { label: '定向邀约', value: '2' }
                        ],
                        methods: {
                          change: function(options, { root, runInitFun }) {
                            const { setRequire, getTableDataList } = root.initFun
                            runInitFun(setRequire, { pageNo: 1, applyType: options.value })
                            runInitFun(getTableDataList)
                          }
                        }
                      },
                      {
                        id: this.$nanoid(),
                        columnIndex: 1,
                        baseVue: 'BaseSelect',
                        placeholder: '产品标签',
                        class: 'select-complex',
                        selectClass: 'select',
                        rightClass: 'right-btn',
                        style: {
                          width: '120px',
                          marginLeft: '10px'
                        },
                        clearable: true,
                        value: 'getRootItem(root,item.key)',
                        key: 'data.searchObj.productTagGroup',
                        selectList: 'getRootItem(root,item.selectListKey)',
                        selectListKey: 'data.tagItemList.PRODUCT_TAG_GROUP',
                        methods: {
                          change: function(options, { root, setRootItem, runInitFun }) {
                            setRootItem(root.data.searchObj, 'productTagGroup', options.value)
                            setRootItem(root.data.searchObj.tagAndGroupIds[0], 'tagIds', options.value ? [options.value] : [])
                            setRootItem(root.data.searchObj, 'pageNo', 1)
                            const { getTableDataList } = root.initFun
                            runInitFun(getTableDataList)
                          },
                          click: function(options, { root }) {
                            this.activeTabName = root.data[`${options.key}_ID`]
                            this.showSceneTagModal = true
                          }
                        }
                      }
                    ]
                  },
                  {
                    id: this.$nanoid(),
                    isShow: '!getRootItem(root,item.isShowKey)',
                    isShowKey: 'data.isShowSearch',
                    columnIndex: 1,
                    baseVue: 'BaseRow',
                    colCount: 1,
                    childSchemeList: [
                      // {
                      //   id: this.$nanoid(),
                      //   columnIndex: 1,
                      //   baseVue: 'BaseButton',
                      //   type: 'info',
                      //   plain: true,
                      //   text: '批量审批通过',
                      //   methods: {
                      //     click: function(options, {root, runInitFun}) {
                      //       const {auditTaskList, auditLiveList} = root.initFun
                      //       if (root.data.approveType === 'task') {
                      //         runInitFun(auditTaskList)
                      //       } else {
                      //         runInitFun(auditLiveList)
                      //       }
                      //     }
                      //   }
                      // },
                      {
                        id: this.$nanoid(),
                        columnIndex: 1,
                        baseVue: 'BaseButton',
                        type: 'info',
                        plain: true,
                        text: '批量驳回',
                        methods: {
                          click: function(options, { root, setRootItem }) {
                            if (!root.data.tableSelectList.length) return this.$message.error('请勾选审批活动')
                            setRootItem(root, 'data.isVisible', true)
                          }
                        }
                      }
                    ]
                  },
                  {
                    id: this.$nanoid(),
                    isShow: function({ root }) {
                      return root.data.searchObj.auditStatus === 'wait'
                    },
                    columnIndex: 2,
                    baseVue: 'BaseButton',
                    type: 'primary',
                    plain: true,
                    text: `function({root}) {
                    return root.data.isShowSearch ? '批量操作' : '取消批量操作'
                  }`,
                    methods: {
                      click: function(options, { root, setRootItem }) {
                        setRootItem(root, 'data.isShowSearch', !root.data.isShowSearch)
                      }
                    }
                  },
                  {
                    id: this.$nanoid(),
                    columnIndex: 2,
                    baseVue: 'BaseButton',
                    type: 'text',
                    text: '导出数据为Excel表',
                    isShow: function() {
                      return !this.isMobile
                    },
                    methods: {
                      click: function(options, { root, runInitFun }) {
                        if (!root.data.tableData.length) return this.$message.error('暂无数据')
                        const { newReqData } = root.initFun
                        const { reqObj, data } = runInitFun(newReqData, { type: 'down' })
                        this.$requireApi(reqObj, data).then(res => {
                          this.$message.success(res.data)
                        }, rea => this.$message.error(rea.message))
                      }
                    }
                  }
                ]
              },
              // 任务审核列表
              {
                id: this.$nanoid(),
                isShow: 'getRootItem(root,item.isShowKey)',
                isShowKey: 'data.isShowSceneType',
                baseVue: 'BaseTable',
                selection: '!getRootItem(root,item.selectionKey)',
                selectionKey: 'data.isShowSearch',
                style: {
                  marginTop: '10px'
                },
                methods: {
                  selectionChange: function(options, { setRootItem, root }) {
                    const orList = []
                    const dirList = []
                    options.value.map(it => {
                      it.applyType === 1 ? orList.push(it) : dirList.push(it)
                    })
                    setRootItem(root, 'data.tableSelectList', options.value)
                    setRootItem(root, 'data.ordinaryList', orList)
                    setRootItem(root, 'data.directionList', dirList)
                  }
                },
                tableData: 'getRootItem(root,item.tableDataKey)',
                tableDataKey: 'data.tableData',
                tableColumn: [
                  {
                    label: '审核活动',
                    prop: 'sceneTitle',
                    showOverflowTooltip: true
                  },
                  {
                    label: '活动类型',
                    prop: 'sceneType',
                    align: 'center',
                    slot: {
                      column: [
                        {
                          baseVue: 'BaseSpan',
                          style: {
                            justifyContent: 'center'
                          },
                          text: function({ item }) {
                            const obj = {
                              '1': '科会',
                              '4': '调研/病例',
                              '5': '直播',
                              '6': '会议',
                              '8': '拜访'
                            }
                            return obj[String(item.row.sceneType)]
                          }
                        }
                      ]
                    }
                  },
                  {
                    label: '产品标签',
                    prop: 'productTagName',
                    align: 'center'
                  },
                  {
                    label: '申请日期',
                    prop: 'applyTime',
                    align: 'center'
                  },
                  {
                    label: '申请人',
                    prop: 'applicantName',
                    align: 'center'
                  },
                  {
                    label: '申请人所属组织',
                    prop: 'applicantOrgName',
                    align: 'center',
                    showOverflowTooltip: true
                  },
                  {
                    label: '申请任务类型',
                    prop: 'applyType',
                    align: 'center',
                    slot: {
                      column: [
                        {
                          baseVue: 'BaseSpan',
                          style: {
                            justifyContent: 'center'
                          },
                          text: function({ item }) {
                            const obj = {
                              '1': '普通任务',
                              '2': '定向邀约'
                            }
                            return obj[String(item.row.applyType)]
                          }
                        }
                      ]
                    }
                  },
                  {
                    label: '申请任务数',
                    prop: 'totalCount',
                    align: 'center'
                  },
                  {
                    label: '任务规则',
                    prop: 'taskRules',
                    align: 'center',
                    showOverflowTooltip: true
                  },
                  {
                    label: '操作',
                    prop: 'activate',
                    align: 'center',
                    fixed: 'right',
                    slot: {
                      column: [
                        {
                          id: this.$nanoid(),
                          baseVue: 'BaseButton',
                          type: 'text',
                          text: function(options) {
                            const { item } = options
                            return item.row.status === 0 ? '前往审批' : '查看详情'
                          },
                          methods: {
                            click: function(options, { root, runInitFun }) {
                              const { item } = options
                              const { getTableDataList } = root.initFun
                              this.$EventBus.$on('getList', () => {
                                runInitFun(getTableDataList)
                                this.$EventBus.$off('getList')
                              })
                              this.$router.push({ name: 'ApproverDetail', params: { id: `${item.row.id}` } })
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
              // 直播审核列表
              {
                id: this.$nanoid(),
                isShow: '!getRootItem(root,item.isShowKey)',
                isShowKey: 'data.isShowSceneType',
                baseVue: 'BaseTable',
                selection: '!getRootItem(root,item.selectionKey)',
                selectionKey: 'data.isShowSearch',
                style: {
                  marginTop: '10px'
                },
                methods: {
                  selectionChange: function(options, { setRootItem, root }) {
                    const orList = []
                    const dirList = []
                    setRootItem(root, 'data.tableSelectList', options.value)
                    setRootItem(root, 'data.ordinaryList', orList)
                    setRootItem(root, 'data.directionList', dirList)
                  }
                },
                tableData: 'getRootItem(root,item.tableDataKey)',
                tableDataKey: 'data.tableData',
                tableColumn: [
                  {
                    label: '直播标题',
                    prop: 'sceneTitle',
                    showOverflowTooltip: true
                  },
                  {
                    label: '产品标签',
                    prop: 'productTagName',
                    align: 'center',
                    showOverflowTooltip: true
                  },
                  {
                    label: '播客',
                    prop: 'speakers',
                    align: 'center',
                    showOverflowTooltip: true
                  },
                  {
                    label: '职称',
                    prop: 'title',
                    align: 'center'
                  },
                  {
                    label: '申请人',
                    prop: 'applicantName',
                    align: 'center'
                  },
                  {
                    label: '申请状态',
                    prop: 'status',
                    align: 'center',
                    slot: {
                      column: [
                        {
                          baseVue: 'BaseSpan',
                          style: {
                            justifyContent: 'center'
                          },
                          text: function({ item }) {
                            const obj = {
                              '-1': '撤销',
                              '0': '待审核',
                              '1': '审核通过',
                              '2': '审核失败'
                            }
                            return obj[String(item.row.status)]
                          }
                        }
                      ]
                    }
                  },
                  {
                    label: '申请人所属组织',
                    prop: 'orgName',
                    align: 'center',
                    showOverflowTooltip: true
                  },
                  {
                    label: '申请日期',
                    prop: 'applyTime',
                    align: 'center'
                  },
                  {
                    label: '操作',
                    prop: 'activate',
                    align: 'center',
                    fixed: 'right',
                    slot: {
                      column: [
                        {
                          id: this.$nanoid(),
                          baseVue: 'BaseButton',
                          type: 'text',
                          text: function(options) {
                            const { item } = options
                            return item.row.status === 0 ? '前往审批' : '查看详情'
                          },
                          methods: {
                            click: function(options, { root, runInitFun }) {
                              const { item } = options
                              const { getTableDataList } = root.initFun
                              this.$EventBus.$on('getList', () => {
                                runInitFun(getTableDataList)
                                this.$EventBus.$off('getList')
                              })
                              this.$router.push(`/approver/live/detail/${item.row.id}?from=common&source=approver`)
                            }
                          }
                        }
                      ]
                    }
                  }
                ]
              },
              // 分页器
              {
                id: this.$nanoid(),
                baseVue: 'BasePagination',
                pageSize: 'getRootItem(root,item.pageSizeKey)',
                pageSizeKey: 'data.searchObj.pageSize',
                currentPage: 'getRootItem(root,item.currentPageKey)',
                currentPageKey: 'data.searchObj.pageNo',
                pageSizes: [10, 20, 50, 100],
                background: true,
                total: 'getRootItem(root,item.totalKey)',
                totalKey: 'data.total',
                layout: 'total, sizes, prev, pager, next',
                style: {
                  marginTop: '10px'
                },
                small: true,
                methods: {
                  currentChange: function(options, { root, setRootItem, runInitFun }) {
                    setRootItem(root.data.searchObj, 'pageNo', options.value)
                    const { getTableDataList } = root.initFun
                    runInitFun(getTableDataList)
                  },
                  sizeChange: function(options, { root, runInitFun }) {
                    const { getTableDataList, setRequire } = root.initFun
                    runInitFun(setRequire, { pageNo: 1, pageSize: options.value })
                    runInitFun(getTableDataList)
                  }
                }
              },
              // 批量驳回弹窗
              {
                id: this.$nanoid(),
                baseVue: 'BaseDialog',
                visible: 'getRootItem(root,item.visibleKey)',
                visibleKey: 'data.isVisible',
                title: '驳回原因',
                width: '30%',
                content: [
                  {
                    id: this.$nanoid(),
                    baseVue: 'BaseInput',
                    type: 'textarea',
                    value: 'getRootItem(root,item.key)',
                    key: 'data.reason',
                    placeholder: '请填写驳回原因',
                    autosize: { minRows: 10, maxRows: 20 },
                    maxlength: 200,
                    methods: {
                      input: function(options, { root, setRootItem }) {
                        setRootItem(root, 'data.reason', options.value)
                      }
                    }
                  }
                ],
                footer: [
                  {
                    id: this.$nanoid(),
                    baseVue: 'BaseButton',
                    type: ' info',
                    plain: true,
                    text: '取消',
                    style: {
                      width: '92px'
                    },
                    methods: {
                      click: `function({item} = options, {setRootItem, root}) {
                        setRootItem(root, 'data.isVisible', false)
                        setRootItem(root, 'data.reason', '')
                      }`
                    }
                  },
                  {
                    id: this.$nanoid(),
                    baseVue: 'BaseButton',
                    type: 'primary',
                    text: '完成',
                    style: {
                      width: '92px'
                    },
                    methods: {
                      click: function(options, { setRootItem, root, runInitFun }) {
                        if (!root.data.reason) return this.$message.error('请填写驳回原因')
                        const data = {
                          ids: root.data.tableSelectList.map(it => it.id),
                          reason: root.data.reason
                        }
                        this.$requireApi(root.data.rejectReqApiObj[root.data.approveType], data).then(() => {
                          setRootItem(root, 'data.isVisible', false)
                          this.$message.success('批量驳回成功')
                          const { getTableDataList } = root.initFun
                          runInitFun(getTableDataList)
                        }, rea => this.$message.error(rea.message))

                      }
                    }
                  }
                ],
                methods: {
                  handleClose: function(options, { setRootItem, root }) {
                    setRootItem(root, options.item.visibleKey, false)
                    setRootItem(root, 'data.reason', '')
                  }
                }
              },
              // 批量通过弹窗
              {
                id: this.$nanoid(),
                baseVue: 'BaseDialog',
                visible: 'getRootItem(root,item.visibleKey)',
                visibleKey: 'data.isVisibleAllot',
                title: function({ root }) {
                  if (root.data.approveType === 'task') {
                    return '确认分配学术任务'
                  }
                  return '直播申请'
                },
                width: function({ root }) {
                  return root.data.approveType === 'task' ? '60%' : '400px'
                },
                content: function({ root }) {
                  if (root.data.approveType === 'task') {
                    return [
                      {
                        id: this.$nanoid(),
                        isShow: function({ root }) {
                          return root.data.ordinaryList.length
                        },
                        baseVue: 'BaseSpan',
                        leftText: '普通任务申请'
                      },
                      {
                        id: this.$nanoid(),
                        isShow: function({ root }) {
                          return root.data.ordinaryList.length
                        },
                        baseVue: 'BaseTable',
                        height: 200,
                        style: {
                          marginTop: '10px'
                        },
                        tableData: 'getRootItem(root,item.tableDataKey)',
                        tableDataKey: 'data.ordinaryList',
                        tableColumn: [
                          {
                            label: '活动名称',
                            prop: 'sceneTitle',
                            showOverflowTooltip: true
                          },
                          {
                            label: '员工姓名',
                            prop: 'applicantName',
                            align: 'center',
                            showOverflowTooltip: true
                          },
                          {
                            label: '所属组织',
                            prop: 'applicantOrgName',
                            align: 'center',
                            showOverflowTooltip: true
                          },
                          {
                            label: '任务数量(个)',
                            align: 'center',
                            prop: 'totalCount'
                          },
                          {
                            label: '任务规则',
                            prop: 'taskRules',
                            align: 'center',
                            showOverflowTooltip: true
                          }
                        ]
                      },
                      {
                        id: this.$nanoid(),
                        isShow: function({ root }) {
                          return root.data.directionList.length
                        },
                        baseVue: 'BaseSpan',
                        leftText: '定向邀约申请',
                        style: {
                          marginTop: '10px'
                        }
                      },
                      {
                        id: this.$nanoid(),
                        isShow: function({ root }) {
                          return root.data.directionList.length
                        },
                        baseVue: 'BaseTable',
                        height: 200,
                        style: {
                          marginTop: '10px'
                        },
                        tableData: 'getRootItem(root,item.tableDataKey)',
                        tableDataKey: 'data.directionList',
                        tableColumn: [
                          {
                            label: '活动名称',
                            prop: 'sceneTitle',
                            showOverflowTooltip: true
                          },
                          {
                            label: '医生姓名',
                            prop: 'doctorNames',
                            align: 'center',
                            showOverflowTooltip: true
                          },
                          {
                            label: '所属代表',
                            prop: 'applicantName',
                            align: 'center',
                            showOverflowTooltip: true
                          },
                          {
                            label: '任务规则',
                            prop: 'taskRules',
                            align: 'center',
                            showOverflowTooltip: true
                          }
                        ]
                      }
                    ]
                  } else {
                    return [
                      {
                        id: this.$nanoid(),
                        baseVue: 'BaseSpan',
                        leftText: '确定通过该直播申请？',
                        style: {
                          marginTop: '35px',
                          color: '#0f0f0f',
                          justifyContent: 'center'
                        }
                      },
                      {
                        id: this.$nanoid(),
                        baseVue: 'BaseSpan',
                        leftText: '通过后活动将在活动管理中呈现',
                        style: {
                          marginTop: '5px',
                          marginBottom: '35px',
                          color: '#0f0f0f',
                          justifyContent: 'center'
                        }
                      }
                    ]
                  }
                },
                footer: [
                  {
                    id: this.$nanoid(),
                    baseVue: 'BaseButton',
                    type: ' info',
                    plain: true,
                    text: '取消',
                    methods: {
                      click: `function({item} = options, {setRootItem, root}) {
                        setRootItem(root, 'data.isVisibleAllot', false)
                      }`
                    }
                  },
                  {
                    id: this.$nanoid(),
                    baseVue: 'BaseButton',
                    loading: 'getRootItem(root,item.loadingKey)',
                    loadingKey: 'data.isLoading',
                    type: 'primary',
                    text: '确认',
                    methods: {
                      click: function(options, { setRootItem, root, runInitFun }) {
                        setRootItem(root, 'data.isLoading', true)
                        const data = {
                          ids: root.data.tableSelectList.map(it => it.id)
                        }
                        this.$requireApi(root.data.passReqApiObj[root.data.approveType], data).then(() => {
                          setRootItem(root, 'data.isLoading', false)
                          setRootItem(root, 'data.isVisibleAllot', false)
                          setRootItem(root, 'data.isShowSearch', false)
                          this.$message.success('批量审批中！')
                          const { getTableDataList } = root.initFun
                          runInitFun(getTableDataList)
                        }, rea => {
                          setRootItem(root, 'data.isLoading', false)
                          this.$message.error(rea.message)
                        })

                      }
                    }
                  }
                ],
                methods: {
                  handleClose: `function({item} = options, {setRootItem, root}) {
                      setRootItem(root, item.visibleKey, false)
                    }`
                }
              }] : [])
          ]
        }
      ]
    }
  },
  created() {
    this.schemeList[0].that = this
  },
  mounted() {
    if (!this.$auth('acadSaas.myApproval.taskApplication')) {
      this.schemeList[0].data.isShowSceneType = false
    }
    const tabList = [
      {
        label: '任务申请审批',
        name: 'task',
        auth: 'acadSaas.myApproval.taskApplication'
      },
      {
        label: '直播活动审批',
        name: 'live',
        auth: 'acadSaas.myApproval.liveProject'
      }
    ]
    const authList = tabList.filter(item => this.$auth(item.auth))
    this.schemeList[0].data.approveType = authList[0].name
    this.changeData = true
  }
}
</script>
