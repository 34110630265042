<!--
 * @Author: your name
 * @Date: 2021-08-11 19:52:29
 * @LastEditTime: 2021-09-01 18:05:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/child/QuestionnaireJumpModal.vue
-->
<template>
  <el-dialog
    :visible='visible'
    destroy-on-close
    title="跳题逻辑"
    :close-on-click-modal="false"
    :show-close="false"
    class="dialog-box"
  >
    <el-row type="flex"  align="middle" justify="space-between">
      <span>共{{jumpCount}}条跳题逻辑</span>
      <el-row type="flex" align="middle">
        <span class="text-primary-link mgn-r8" @click="onLookJumpList">查看整体逻辑</span>
        <el-button type="primary" class="w140 mgn-l10" icon="el-icon-plus" @click="onAdd">新增逻辑</el-button>
      </el-row>
    </el-row>
    <div class="content" ref="scroll-view" v-loading='loading'>
      <div class="scene-empty" v-if="isEmpty">
        <div><img :src="require('@/assets/common/empty.png')" alt=""></div>
        <div>目前暂无跳题逻辑</div>
      </div>
      <template v-for="(result,index) in jumpList">
        <el-row type="flex" :key='index' align="middle" justify="space-between" class="jump-item">
          <el-row type="flex">
            <el-row type="flex" align="middle">
              <span class="mgn-r10 flex-shrink">如果</span>
              <el-select v-model="selectedExamMap[result.id]" @change='onSelectExam(result.id)'>
                <!-- <template slot="prefix">
                  <div>
                    第{{currentSelectExam(selectedExamMap[result.id]).examIndex}}题
                  </div>
                </template> -->
                <el-option v-for="item in sourceList(result.id)"
                 :key="item.examId" :title="item.title | examTitleFilter" :value="item.examId" :label="item.title"
                 v-show='item.show'>
                  <el-row type="flex" justify="space-between">
                    <span class="opt-item">{{item.title | examTitleFilter}}</span>
                  </el-row>
                </el-option>
              </el-select>
            </el-row>
            <el-row type="flex" align="middle" v-if="currentSelectExam(selectedExamMap[result.id]).answerType === 'single'">
              <span class="mgn-r10 mgn-l10 flex-shrink">选择了</span>
              <el-select v-model="selectedAnswerMap[result.id]" @change="onSelectAnswer(result.id)">
                <!-- <template slot="prefix">
                  <div>
                    第{{currentSelectExam(selectedExamMap[result.id])}}题
                  </div>
                </template> -->
                <el-option v-for="(item) in currentAnswerList(selectedExamMap[result.id],result.id)" :key="item.answerId" :value="item.answerId" :title="item.title" :label="item.title" :disabled='item.disabled'>
                  <el-row type="flex" justify="space-between">
                    <span class="opt-item">{{item.title}}</span>
                  </el-row>
                </el-option>
                <el-option value='' label="任意选项" v-if="!hasSelectedExamAnswer(result.id)"></el-option>
              </el-select>
            </el-row>
            <el-row type="flex" align="middle">
              <span class="mgn-r10 mgn-l10 flex-shrink">则跳转到</span>
              <el-select v-model="targetSelectedExamMap[result.id]">
                <!-- <template slot="prefix">
                  <div>
                    第{{currentSelectExam(targetSelectedExamMap[result.id]).examIndex}}题
                  </div>
                </template> -->
                <el-option v-for="item in targetExamList(selectedExamMap[result.id])" :key="item.examId" :value="item.examId" :title='item.title' :label="item.title">
                  <el-row type="flex" justify="space-between">
                    <span class="opt-item">{{item.title | examTitleFilter}}</span>
                  </el-row>
                </el-option>
                <el-option value='' label="结束">结束</el-option>
              </el-select>
            </el-row>
          </el-row>
          <span class="text-primary-link mgn-l30" @click="onDel(result.id, result.hasSave)">删除</span>
        </el-row>
      </template>
    </div>
    <div slot="footer">
      <el-row type="flex" class="pdg-b20 colc8c9ce font-s14">备注：建议题目创建完成后，再设置跳题逻辑。增加、删除、编辑、移动题目都会影响跳题逻辑的设置</el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-button plain  @click="onClose">取消</el-button>
        <el-button :loading="isSubmit"  type="primary" @click="onSubmit">确定</el-button>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  name: 'SceneExamJumpModal',
  data () {
    return {
      jumpList: [],
      selectedExamMap:{}, // 已选的题目列表
      selectedAnswerMap: {}, // 已选的答案列表
      loading: false,
      isEmpty: false,
      isSubmit: false,
      targetSelectedExamMap: {} //  跳转目标的题目列表
    }
  },
  props: {
    sceneId: {
      type: String,
      default: ""
    },
    sceneType: {
      type: String,
      default: 'questionnaire'
    },
    examList : {
      type: Array,
      default () {
        return []
      }
    },
    addExamJumpItem: {
      type: Object,
      default() {
        return {}
      }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  filters: {
    examTypeFilter (type) {
      switch (type) {
        case 'single':
          return '单选题'
        case 'multiple':
          return '多选题'
        case 'text_fill':
          return '问答题'
        case 'blank_fill':
          return '填空题'
        case 'sort':
          return '排序题'
        case 'scale':
          return '量表题'
      }
    },
    answerFilter (iv) {
      return arr[iv]
    },
    examTitleFilter (text) {
      return text.replace(/<filter><\/filter>/g,'______')
    }
  },
  computed: {
    sceneTypeId () {
      switch (this.sceneType) {
        case 'questionnaire':
          return 4
      }
    },
    sourceList () {
      return (currentJumpId = '') => {
        let arr = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T']
        let list = JSON.parse(JSON.stringify(this.examList))
        return [...list].map(v=>{
          let currentExamAnswerCount = 0
          v.show = true
          for (let t in this.selectedExamMap) {
            // 遍历已经选择的 题目列表
            if (v.answerType === 'single' && v.examId === this.selectedExamMap[t] && currentJumpId !== t && this.selectedExamMap[t] && this.selectedAnswerMap[t] === '') {
              // 单选选择任意选项的不显示
              v.show = false
            }
            if (v.answerType !== 'single' && v.examId === this.selectedExamMap[t] && currentJumpId !== t && this.selectedExamMap[t] && (this.targetSelectedExamMap[t]||this.targetSelectedExamMap[t] === '')) {
              // 其他类型 进行选择过的不显示
              v.show = false
            }
            if (v.examId === this.selectedExamMap[t] && this.selectedExamMap[t] !== this.selectedExamMap[currentJumpId]) {
              // 计算单选题答案选择个数
              ++currentExamAnswerCount
            }
          }
          if (v.answerType === 'single' && currentExamAnswerCount === v.answers?.length) {
            // 单选选择所有答案后隐藏
            v.show = false
          }
          let title = `Q${v.examIndex}：${v.title}`
            v.title = title.replace(/<filter><\/filter>/g,'______')
            let answers = v.answers || []
            v.answers = [...answers].map((c,i)=>{
              c = {...c,title:`${arr[i]}：${c.title}`}
              return c
            })
          return v
        })
      }
    },
    jumpCount () {
      return this.jumpList.length
    },
    targetExamList() {
      return (currentExamId) => {
        let currentItem = [...this.sourceList()].filter(v=>v.examId ==currentExamId)[0] || {}
        return this.sourceList().filter(v=>v.examIndex > currentItem.examIndex)
      }
    },
    currentSelectExam () {
      return (currentExamId) => {
        return this.sourceList().filter(v=>v.examId === currentExamId)[0] || {}
      }
    },
    hasSelectedExamAnswer () {
      return (currentJumpId) => {
        let hasSelect = false
        for (let k in this.selectedExamMap) {
          if (this.selectedExamMap[k] === this.selectedExamMap[currentJumpId] && k!=currentJumpId) {
            hasSelect = true
          }
        }
        return hasSelect
      }
    },
    currentAnswerList () {
      return (currentExamId,currentJumpId) => {
        let obj = this.sourceList().filter(v=>v.examId === currentExamId)[0] || {}
        let answerList = obj.answers || []

        let selectedList = []
        for (let k in  this.selectedAnswerMap) {
          if (k!== currentJumpId) {
            selectedList.push(this.selectedAnswerMap[k])
          }
        }
        return answerList.map(v=> {
          if (selectedList.indexOf(v.answerId) > -1) {
            v = {...v,disabled: true}
          }
          return v
        })
      }
    }
  },
  watch: {
    visible (show) {
      if (show) {
        this.init()
      } else {
        this.selectedExamMap = {}
        this.selectedAnswerMap = {}
        this.targetSelectedExamMap = {}
      }
    }
  },
  methods: {
    ...mapActions(['onCreateSceneExamJumpRule','getSceneExamJumpRuleList','onDelSceneExamJumpRule','onEditSceneExamJumpRule']),
    onSelectExam (currentJumpId) {
      // 切换题目 删除 对应的跳题
      delete this.selectedAnswerMap[currentJumpId]
      delete this.targetSelectedExamMap[currentJumpId]
    },
    onSelectAnswer (currentJumpId) {
      // 切换答案 删除 对应的跳题
      delete this.targetSelectedExamMap[currentJumpId]
    },
    initScrollTop (currentY=0,targetY=0){
  		let needScrollTop = targetY - currentY
  		let _currentY = currentY
  		this.timer = setTimeout(()=>{
  			const dist = Math.ceil(needScrollTop / 20)
  			_currentY += dist
  			this.$refs['scroll-view'].scrollTop = currentY
  			// window.scroll(_currentY, currentY)
  			if (needScrollTop > 10 || needScrollTop < -10) {
  				this.initScrollTop(_currentY, targetY)
  			} else {
  				// window.scroll(_currentY, targetY)
  				this.$refs['scroll-view'].scrollTop = currentY
  				this.timer&&clearTimeout(this.timer)
  			}
  		},1)
	  },
    onLookJumpList () {
      window.open(`/academic/questionnaire/jump/tree/${this.sceneId}`)
    },
    init () {
      this.loading = true
      this.selectedExamMap = {}
      this.selectedAnswerMap = {}
      this.targetSelectedExamMap = {}
      this.getSceneExamJumpRuleList(this.sceneId).then(res=>{
        this.loading = false
        let needSetNewJump = true // 默认需要设置新的跳题逻辑
        this.jumpList = res?.map(v=>{
          v = {...v,hasSave: true, canDel: true}
          if (v.examId === this.addExamJumpItem.examId) {
            // 有相同的 代表已经设置过当前题目跳题逻辑
            needSetNewJump = false
          }
          this.selectedExamMap = {...this.selectedExamMap,[v.id]:v.examId || ''}
          this.selectedAnswerMap = {...this.selectedAnswerMap,[v.id]:v.examAnswerId || ''}
          this.targetSelectedExamMap = {...this.targetSelectedExamMap,[v.id]:v.nextExamId || ''}
          return v
        })
        // this.isEmpty = this.jumpList.length === 0
        if (needSetNewJump) {
          let id = new Date().getTime()
          this.jumpList = [...this.jumpList,{hasSave: false, id: id}]
          this.selectedExamMap = {...this.selectedExamMap,[id]:this.addExamJumpItem.examId}
        }
        console.log(this.addExamJumpItem)
        this.initScrollTop(this.$refs['scroll-view'].scrollTop,this.$refs['scroll-view'].scrollHeight)
      },rea=>{
        this.loading = false
      })
    },
    onDel (currentJumpId,hasSave) {
        if (!hasSave) {
          this.jumpList = [...this.jumpList].filter(v=>v.id!==currentJumpId)
          delete this.selectedExamMap[currentJumpId]
          delete this.selectedAnswerMap[currentJumpId]
          delete this.targetSelectedExamMap[currentJumpId]
          return
        }
        this.onDelSceneExamJumpRule({examId:this.selectedExamMap[currentJumpId], sceneId:this.sceneId,examAnswerId: this.selectedAnswerMap[currentJumpId],nextExamId: this.targetSelectedExamMap[currentJumpId]}).then(res=>{
          this.$message.success('逻辑规则已删除')
            this.jumpList = [...this.jumpList].filter(v=>v.id!==currentJumpId)
            delete this.selectedExamMap[currentJumpId]
            delete this.selectedAnswerMap[currentJumpId]
            delete this.targetSelectedExamMap[currentJumpId]
        },rea=>{

        })
    },
    onAdd () {
      this.isEmpty =  false
      if (this.jumpList.length > 0) {
        let currentObj = this.jumpList[this.jumpList.length - 1] || {}
        let currentJumpId = currentObj['id'] || ''
        if (!currentObj.hasSave) {
          this.onCreate(currentJumpId)
        } else {
          this.jumpList = [...this.jumpList, {hasSave: false, id: new Date().getTime()}]
        }
      } else {
          this.jumpList = [...this.jumpList, {hasSave: false, id: new Date().getTime()}]
      }
      this.$nextTick(()=>{
        this.initScrollTop(this.$refs['scroll-view'].scrollTop,this.$refs['scroll-view'].scrollHeight)
      })
    },
    onClose () {
      this.$emit('onSubmit',this.jumpList.filter(v=>v.hasSave).map(k=>k.examId))
      this.$emit('onClose')
    },
    onValid (currentId) {
      if (!currentId) return false
      let valid = true
      if (!this.selectedExamMap[currentId]) {
        // 未选择题目
        valid = false
      } else  if (this.targetSelectedExamMap[currentId] !== '' && !this.targetSelectedExamMap[currentId]) {
        // 是否设置过目标题目
        valid = false
      } else if (this.currentSelectExam(this.selectedExamMap[currentId]).answerType === 'single') {
        // 单选题
        if (this.selectedAnswerMap[currentId] !== '' && !this.selectedAnswerMap[currentId]) {
          valid = false
        }
      }
      if (!valid) {
        this.$message.error('请完善跳题逻辑规则')
      }
      return valid
    },
    onCreate (currentJumpId , hasNext = true) {
      if (!this.onValid(currentJumpId)) return
      this.isSubmit = true
      let req = {
        examId: this.selectedExamMap[currentJumpId] || '',
        sceneId: this.sceneId,
        sceneType: this.sceneTypeId,
        examAnswerId: this.selectedAnswerMap[currentJumpId] || '',
        nextExamId: this.targetSelectedExamMap[currentJumpId] || ''
      }
      this.onCreateSceneExamJumpRule(req).then(res=>{
        this.$message.success('规则创建成功')
        this.$emit('onSubmit',this.jumpList.filter(v=>v.hasSave).map(k=>k.examId))
        this.isSubmit = false
        this.jumpList = [...this.jumpList].map(v=>{
          if (currentJumpId === v.id) {
            v = {...v, hasSave: true,canDel:true}
            // delete this.selectedExamMap[currentJumpId]
            // delete this.selectedAnswerMap[currentJumpId]
            // delete this.targetSelectedExamMap[currentJumpId]
            // this.selectedExamMap[currentJumpId] = v.examId
            // this.selectedAnswerMap[currentJumpId] = v.examAnswerId
            // this.targetSelectedExamMap[currentJumpId] = v.nextExamId
          }
          return v
        })
        if (hasNext) {
          this.jumpList = [...this.jumpList, {hasSave: false, id: new Date().getTime()}]
        } else {
          this.onClose()
        }
      },rea=>{
        this.isSubmit = false
        this.$message.error(rea)
      })
    },
    onEdit () {
      let req = []
      let canClose = true
      let createJumpId = '' // 创建跳题的id 需要编辑后再进行将为创建的跳题进行创建
      this.jumpList.map(v=>{
        if (v.hasSave) {
          req.push({
            id: v.id,
            examId: this.selectedExamMap[v.id],
            sceneId: this.sceneId,
            sceneType: this.sceneTypeId,
            examAnswerId: this.selectedAnswerMap[v.id] || '',
            nextExamId: this.targetSelectedExamMap[v.id]
          })
        } else {
          createJumpId = v.id
        }
        if (!this.onValid(v.id)) {
          canClose = false
        }
      })
      if (!req.length && createJumpId) {
        this.onCreate(createJumpId,false)
        return
      }
      if (!req.length || !canClose) return
      this.onEditSceneExamJumpRule(req).then(res=>{
        this.onCreate(createJumpId,false)
        this.onClose()
      },rea=>{
        this.$message.error(rea)
      })
    },
    onSubmit () {
      this.onEdit()
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
.dialog-box {
  ::v-deep .el-dialog {
    min-width: 600px !important;
  }
}
  .content {
    height: 270px;
    padding-top: 15px;
    overflow-y: auto;
    position: relative;
  }
  .scene-empty {
    color: rgba(119, 132, 161, 1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 10px;
    }
  }
  .el-select ::v-deep {
    .el-input__prefix {
      color:#606266;
      left: calc(100% - 72px);
      top: 10px;
    }
  }
  .opt-item {
    max-width: 250px;
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .jump-item {
    padding: 8px 20px;
    &:nth-child(odd) {
      background: #F6F7FB;
    }
    &:nth-child(even) {
      background: #ffffff;
    }
  }
</style>
