<template>
  <el-dialog
    width="1000px"
    v-bind="$attrs"
    class="chart-detail-dialog customize-el-dialog"
    :show-close="false"
    v-on="$listeners"
    @open="open"
  >
    <div class="layout">
      <div class="left">
        <div ref="chart-item" v-loading="loading" class="chart-item">
          <!-- v-if="!loading" -->
          <DataChart
            ref="dataChart"
            :form-chart-option="chartData.formChartOption"
            :js-code-snippet="chartData.jsCodeSnippet"
            :data="chartData.smartQueryResultVO"
            :show-type-name="showTypeText(chartData.smartQueryResultVO && chartData.smartQueryResultVO.desc.showDesc.showType).value"
          />
        </div>
        <div class="chart-footer">
          <p class="source">
            <span v-if="showSource && chartData.dataServicePushId">
              该图表来源于<el-link :underline="false" style="color: #4064df;" @click="toDetail(chartData.dataServicePushId)">《{{ chartData.serviceTitle }}》</el-link>
            </span>
          </p>
          <p v-if="type !== 'check'" class="btn-wrap">
            <el-button @click="onDel">移出看板</el-button>
          </p>
        </div>
      </div>
      <div>
        <div class="placeholder-wrap">
          <el-form
            ref="form"
            :rules="formRules"
            class="form"
            :model="chartForm"
          >
            <el-form-item>
              <div style="display: flex; justify-content: space-between;">
                <el-cascader
                  ref="cascader"
                  :key="chartData.isCheckSelect"
                  v-model="echoOrg"
                  class="cascader"
                  :show-all-levels="false"
                  collapse-tags
                  :options="options"
                  placeholder="组织选择"
                  clearable
                  :props="props"
                  @change="handleQueryInfos"
                />
                <el-button type="primary" icon="el-icon-search" @click="screen">查询</el-button>
              </div>
            </el-form-item>

            <div class="title">
              <template v-if="placeholder.length">
                <h4>筛选</h4>
                <el-button type="text" @click="chartForm.placeholderWhereInfos.push({ custom_id: $nanoid(), value: '' })">添加</el-button>
              </template>
            </div>
            <div v-if="placeholder.length">
              <div v-for="(item, index) in chartForm.placeholderWhereInfos" :key="item.custom_id" class="placeholder-item">
                <el-form-item
                  :key="item.id"
                  style="width: 120px;"
                  :prop="`placeholderWhereInfos.${index}.colDesc`"
                  :rules="[{ required: true, message: '请选择筛选项', trigger: 'blur' }]"
                >
                  <el-select
                    :key="item.colDesc"
                    value-key="colDesc"
                    :value="item"
                    @change="handleColDesc($event, item)"
                  >
                    <el-option
                      v-for="optionsItem in placeholder"
                      :key="optionsItem.colDesc"
                      :label="optionsItem.colDesc"
                      :value="optionsItem"
                    />
                  </el-select>
                </el-form-item>
                <!-- <el-form-item :key="item.id" :prop="`placeholderWhereInfos.${index}.sqlMemberId`" :rules="[{required: true, message: '请选择条件'}]">
                  <el-select v-model="item.sqlMemberId" clearable>
                    <el-option
                      v-for="seatItem in operatorList"
                      :key="seatItem.sqlMemberId"
                      :label="seatItem.showText"
                      :value="seatItem.sqlMemberId"
                    />
                  </el-select>
                </el-form-item> -->
                <el-form-item :key="item.id" :prop="`placeholderWhereInfos.${index}.value`" :rules="[{ required: true, validator: validateValue, trigger: 'blur' }]">
                  <el-date-picker
                    v-if="item.dataType === 3"
                    v-model="item.value"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    format="yyyy-MM-dd"
                    type="daterange"
                    unlink-panels
                    placeholder="选择日期"
                    :picker-options="pickerOptions"
                    :default-time="['00:00:00', '23:59:59']"
                    :editable="false"
                    clearable
                  />
                  <template v-else-if="item.dataType === 1">
                    <el-select
                      v-if="item.enumeration && item.enumeration.length"
                      v-model="item.value"
                      style="width: 100%;"
                      clearable
                      placeholder="请择"
                    >
                      <el-option
                        v-for="enumerationItem in item.enumeration"
                        :key="enumerationItem.value"
                        :label="enumerationItem.label"
                        :value="enumerationItem.value"
                      >
                        <span style="float: left;">{{ enumerationItem.label }}</span>
                        <!-- <span style="float: right; color: #8492a6; font-size: 13px;">{{ enumerationItem.value }}</span> -->
                      </el-option>
                    </el-select>
                    <div v-else style="display: flex; flex-shrink: 0; overflow: hidden; align-items: center; gap: 5px;">
                      <el-input-number
                        v-model.trim.number="item.value[0]"
                        style="width: 100%;"
                        clearable
                        placeholder="最小值"
                      />
                      -
                      <el-input-number
                        v-model.trim.number="item.value[1]"
                        style="width: 100%;"
                        clearable
                        placeholder="最大值"
                      />
                    </div>
                  </template>
                  <el-input
                    v-else
                    v-model.trim="item.value"
                    clearable
                    :placeholder="item.colRemark"
                  />
                </el-form-item>
                <el-form-item :key="item.id">
                  <el-button type="text" @click="onDelPlaceholderWhereInfos(item)">
                    <svg-icon name="del" style="width: 20px;" />
                  </el-button>
                </el-form-item>
              </div>
            </div>
          </el-form>
          <div v-if="chartData.intro" class="report-description">
            <h4>报表说明</h4>
            <div style="color: #757575; line-height: 18px; margin-top: 10px;">
              {{ chartData.intro }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button v-if="type === 'check'" style="width: 90px;" @click="$emit('update:visible', false)"> 关 闭 </el-button>
      <template v-else>
        <el-button style="width: 90px;" @click="$emit('update:visible', false)"> 取 消 </el-button>
        <el-button type="primary" style="width: 90px;" @click="onSubmit"> 确 认 </el-button>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import { showTypeText } from '@/utils/data-chart-show-type'
import { argolog, eventNames } from '@/utils/argo-log'
export default {
  name: 'ChartEditDialog',
  inject: {
    getDataList: {
      from: 'getDataList',
      default: () => {}
    }
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: 'check'
    },
    dataCockpitBoardIds: {
      type: Array,
      default: () => []
    },
    showSource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chartForm: {
        modelId: '',
        queryInfos: [],
        placeholderWhereInfos: []
      },
      chartData: {},
      loading: true,
      placeholder: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },
      formRules: {
        placeholderWhereInfos: {}
      },
      echoOrg: []
    }
  },
  computed: {
    operatorList() {
      return this.$store.state.report.utilData.operatorMap[1]
    },
    showTypeText,
    handleChart: {
      get() {
        return this.data
      },
      set(val) {
        this.$emit('update:data', val)
      }
    },
    options() {
      return this.$store.state.org.orgList
    },
    props() {
      return {
        multiple: this.chartData.isCheckSelect,
        value: 'orgId',
        label: 'orgName',
        children: 'child',
        // emitPath: false,
        checkStrictly: true
      }
    }
  },
  methods: {
    async open() {
      try {
        const modelParams = await this.getChartDetail()
        const placeholder = await this.getPlaceholder(this.handleChart.modelId)
        // 有占位时在请求
        if (placeholder.length) {
          await this.$store.dispatch('report/utilData/getOperatorList', {type: 1})
        }
        // TODO:
        this.chartForm = await this.formatEchoParams(modelParams, placeholder)
        // 回显组织
        const arr = this.chartForm.queryInfos?.map(item => item.queryObjectId) || []
        if (arr?.length > 0) {
          const { data } = await this.$axios.post(this.$API.organizationV1OrgList, arr)
          let fullPathArr = data.map(item => item.split(',').reverse())
          if (arr.length === 1) {
            fullPathArr = fullPathArr.flat(1)
          }
          this.echoOrg = fullPathArr
        }
      } catch (error) {
        error.code && this.$message.error(error.message)
      }

      this.$refs.dataChart.myCharts.getZr().on('click', event => {
        console.log('event: ', event)
        // 获取点击的 DOM 元素
        const target = event.target
        // TODO: 根据打印结果修改判断条件
        if (target.__title === '保存为图片'/* 需要根据实际属性判断 */) {
          // 上报日志
          const { modelId: model_id, modelName: model_name } = this.handleChart
          const { queryInfos } = this.chartForm
          argolog.track(eventNames.org_model_download, {
            org_ids: queryInfos?.map(item => item.queryObjectId),
            org_names: queryInfos?.map(item => item.queryObjectShowTitle),
            model_id,
            model_name
          })
        }
      })
    },
    // 选择占位字段时处理不同类型的value值的类型
    async handleColDesc(val, row) {
      for (let i = 0; i < this.chartForm.placeholderWhereInfos.length; i++) {
        const item = this.chartForm.placeholderWhereInfos[i]
        if (item.custom_id === row.custom_id) {
          item.value = ''
          this.$set(item, 'colDesc', val.colDesc)
          this.$set(item, 'dataType', val.dataType)
          if (item.dataType === 3) {
            item.value = []
          } else if (item.dataType === 2) {
            item.value = ''
          } else if (item.dataType === 1) {
            item.value = []
            const enumeration = await this.getEnumeration(val.tableAndColNames[0])
            this.$set(item, 'enumeration', enumeration || [])
            if (item.enumeration.length) {
              item.value = null
            } else {
              item.value = []
            }
          }
        }
      }
    },
    // 获取对应占位的枚举
    async getEnumeration(item) {
      try {
        const params = {
          ...item
        }
        // TODO:
        // 入参应该是占位项colDesc和modelId, 但韭菜说他会保证所有枚举值都一样，暂时不改 2023-03-10
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1ColValue, { params })
        return data?.split(',').map(dataItem => {
          return {
            label: dataItem.split(':')[1],
            value: dataItem.split(':')[0]
          }
        })
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 获取报表详情
    async getChartDetail() {
      try {
        const params = {
          smartModelId: this.handleChart.modelId
        }
        this.loading = true
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1SmartModelDetail, { params })

        const opt = typeof JSON.parse(data.chartOptions || '{}') === 'string' ? {} : JSON.parse(data.chartOptions || '{}')
        data.formChartOption = opt
        data.modelParams = JSON.parse(data.modelParams || '{}')
        data.modelParams.placeholderWhereInfos = data.modelParams.placeholderWhereInfos || []

        this.chartData = data
        this.loading = false

        return data.modelParams
      } catch (error) {
        this.loading = false
        this.$message.error(error.message)
      }
    },
    // 保存驾驶舱
    async onSubmit() {
      try {
        this.chartForm.modelId = this.handleChart.modelId
        let params = Object.assign({}, this.chartForm)
        params = this.formatSubmitParams(params)

        await this.$axios.post(this.$API.dataCockpitBoardV1ModelParamSave, params)
        this.$message.success('保存成功')
        this.$emit('update:visible', false)
        this.getDataList && this.getDataList()
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 移出看板
    async onDel() {
      this.$MessageDialog({
        title: '操作提示',
        message: '<div style="margin: 20px 0;">确认是否移出该报表？</div>'
      }).then(async() => {
        try {
          const params = {
            smartModelId: this.handleChart.modelId,
            dataCockpitBoardIds: this.dataCockpitBoardIds.filter(item => item)
          }
          await this.$axios.post(this.$API.dataCockpitBoardV1ModelRemove, params)
          this.$message.success('移出成功')
          this.$emit('update:visible', false)
          this.getDataList && this.getDataList()
        } catch (error) {
          this.$message.error(error)
        }
      })
    },
    // 处理组织数据
    handleQueryInfos() {
      this.$nextTick(() => {
        const nodes = this.$refs.cascader.getCheckedNodes()
        this.chartForm.queryInfos = nodes
          .map(item => {
            if (item) {
              const {
                data: { orgName, orgId }
              } = item
              return {
                queryObjectShowTitle: orgName,
                queryObjectId: orgId,
                queryObjectType: 3
              }
            }
          })
          .filter(item => item)
      })
    },
    // 跳转到服务详情
    toDetail(id) {
      this.$emit('update:visible', false)
      this.$router.push({
        name: 'ReportingActivity',
        query: {
          id
        }
      })
    },
    // 占位条件查询
    async getPlaceholder(smartModelId) {
      try {
        const params = {
          smartModelId
        }
        const { data } = await this.$axios.get(this.$API.dataCockpitBoardV1QueryPlaceholder, { params })
        this.placeholder = data
        return data
      } catch (error) {
        this.$message.error(error.message)
      }
    },
    // 删除占位条���
    onDelPlaceholderWhereInfos(row) {
      this.chartForm.placeholderWhereInfos = this.chartForm.placeholderWhereInfos.filter(item => item.custom_id !== row.custom_id)
    },
    // 查询
    screen() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            let params = {
              ...this.chartForm,
              modelId: this.handleChart.modelId
            }
            params = this.formatSubmitParams(params)
            this.loading = true
            const res = await this.$axios.post(this.$API.queryModelDataDirect, params)
            // 阻止isCheckSelect被覆盖
            res.data.isCheckSelect = this.chartData.isCheckSelect
            this.chartData = res.data
            this.loading = false

            // 上报日志
            const { modelId: model_id, modelName: model_name } = this.handleChart
            const { placeholderWhereInfos, queryInfos } = this.chartForm
            argolog.track(eventNames.org_model_query, {
              org_ids: queryInfos?.map(item => item.queryObjectId),
              org_names: queryInfos?.map(item => item.queryObjectShowTitle),
              model_id,
              model_name,
              condition: placeholderWhereInfos.map(item => item.colDesc)
            })
          } catch (error) {
            this.loading = false
            this.$message.error(error.message)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 处理提交参数
    formatSubmitParams(params) {
      params.placeholderWhereInfos = params.placeholderWhereInfos
        .map(item => {
          if (item.dataType === 3) {
            // 时间
            return [
              {
                sqlMemberId: this.getSqlMemberId('大于'),
                colDesc: item.colDesc,
                dataType: item.dataType,
                value: item.value[0]
              },
              {
                sqlMemberId: this.getSqlMemberId('小于'),
                colDesc: item.colDesc,
                dataType: item.dataType,
                value: item.value[1]
              }
            ]
          } else if (item.dataType === 1) {
            // number
            if (item.enumeration && item.enumeration.length !== 0) {
              // 枚举
              return {
                sqlMemberId: this.getSqlMemberId('等于'),
                colDesc: item.colDesc,
                dataType: item.dataType,
                value: item.value
              }
            } else {
              return [
                {
                  sqlMemberId: this.getSqlMemberId('大于'),
                  colDesc: item.colDesc,
                  dataType: item.dataType,
                  value: item.value[0]
                },
                {
                  sqlMemberId: this.getSqlMemberId('小于'),
                  colDesc: item.colDesc,
                  dataType: item.dataType,
                  value: item.value[1]
                }
              ]
            }
          } else if (item.dataType === 2) {
            // 字符串
            return {
              colName: item.colName,
              sqlMemberId: this.getSqlMemberId('等于'),
              colDesc: item.colDesc,
              dataType: item.dataType,
              value: item.value
            }
          }
        })
        .flat(2)
      return params
    },
    // 处理回显参数
    async formatEchoParams(params, placeholder) {
      const formatMarge = (obj, item) => {
        obj[item.colDesc] = obj[item.colDesc] || {}
        for (const key in item) {
          if (key === 'value') {
            obj[item.colDesc][key] = obj[item.colDesc][key] || []
          } else {
            obj[item.colDesc][key] = obj[item.colDesc][key] ? obj[item.colDesc][key] : item[key]
          }
        }

        if (item.sqlMemberId === this.getSqlMemberId('大于')) {
          obj[item.colDesc].value[0] = item.value
        } else if (item.sqlMemberId === this.getSqlMemberId('小于')) {
          obj[item.colDesc].value[1] = item.value
        }
      }

      const formatMap = {}
      const formatArr = []
      for (const [index, item] of params.placeholderWhereInfos.entries()) {
        item.index = index
        item.custom_id = this.$nanoid()
        if (item.dataType === 3) {
          formatMarge(formatMap, item)
        } else if (item.dataType === 1) {
          const [val] = placeholder.filter(placeholderItem => placeholderItem.colDesc === item.colDesc)
          item.enumeration = (await this.getEnumeration(val.tableAndColNames[0])) || []
          if (item.enumeration && !item.enumeration.length) {
            formatMarge(formatMap, item)
          } else {
            formatArr.push(item)
          }
        } else {
          formatArr.push(item)
        }
      }

      for (const key in formatMap) {
        const row = {
          colDesc: key,
          dataType: formatMap[key].dataType,
          value: formatMap[key].value,
          index: formatMap[key].index,
          custom_id: formatMap[key].custom_id
        }
        formatArr.push(row)
      }
      params.placeholderWhereInfos = formatArr.filter(v => v).sort((current, previous) => current.index - previous.index)
      return params
    },
    // 获取对应条件的sqlMemberId
    getSqlMemberId(str) {
      const [row = {}] = this.operatorList?.filter(item => item.showText === str)
      return row.sqlMemberId
    },
    // 表单验证
    validateValue(rule, value, callback) {
      if (value === null || value === undefined || value === '') {
        callback(new Error('值不能为空'))
        return
      }
      const type = Object.prototype.toString.call(value).slice(8, -1)
      switch (type) {
        case 'Array':
          if (value.filter(v => typeof v === 'number' || typeof v === 'string').length === 0) {
            callback(new Error('请输入范围'))
          } else {
            callback()
          }
          break
        case 'String':
          if (value.trim().length === 0) {
            callback(new Error('请输入'))
          } else {
            callback()
          }
          break
        case 'Number':
          if (isNaN(value)) {
            callback(new Error('值必须是数值型'))
          } else {
            callback()
          }
          break
        default:
          callback(new Error('值必须是数组、字符串或数值型'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-detail-dialog {
  ::v-deep .el-dialog__body {
    height: 430px;
    min-width: 800px;
    padding: 0;
    @media screen and (max-width: 768px) {
      height: 390px;
    }
  }
  ::v-deep .el-dialog__footer {
    padding-top: 10px;
  }
  .chart-footer {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .source {
      > span {
        display: flex;
        align-items: center;
      }
    }
  }
  .layout {
    width: 100%;
    height: 100%;
    display: flex;
    border-bottom: 1px solid #f3f3f3;
    .left {
      width: calc(100% - 20px - 400px);
      flex: 1;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      border-right: 1px solid #f3f3f3;
      padding-right: 10px;
      padding-top: 10px;
      padding-left: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid transparent;
      .chart-item {
        width: 100%;
        height: 100%;
        flex: 1;
        flex-shrink: 0;
        background-color: #f3f3f3;
      }
    }
    > div:nth-child(2) {
      width: 400px;
      padding-left: 10px;
      padding-top: 10px;
      .title {
        width: 100%;
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
      }
      .placeholder-wrap {
        height: 100%;
        padding: 0;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .form {
          ::v-deep .placeholder-item {
            display: grid;
            gap: 5px;
            grid-template-columns: 0.4fr 1fr auto;
            > div {
              flex-shrink: 0;
              .el-form-item__content {
                > div {
                  .el-input__inner {
                    border-radius: 0;
                  }
                }
              }
            }
          }
        }
      }
      .report-description {
        min-height: 150px;
      }
    }
  }
  .form {
    .cascader {
      ::v-deep .el-cascader__tags {
        width: 100%;
        display: flex;
        > span:nth-child(1) {
          width: 50%;
        }
      }
    }
  }
}
</style>
