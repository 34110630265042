<!--
 * @Author: your name
 * @Date: 2021-08-10 12:30:46
 * @LastEditTime: 2022-04-13 17:58:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/academic/EditQuestionnaire.vue
-->
<template>
  <div>
    <el-row type="flex" class="container-wrapper">
      <!-- 侧边栏选择模板 -->
      <el-card class="scene-slide-left">
        <add-question-card
          :show-type="showType"
          :type="0"
          @onAddExam="onAddExam"
        />
        <!-- 其它功能 -->
        <el-row v-show="!isEdit" class="fix-wrapper">
          你可以
          <el-button type="text" size="middle" @click="onDown">点击这里下载</el-button>
          模版，填写完成后，
          <UploadSheetToJson
            class="link-wrapper"
            start="A2:"
            @onUpload="onUploadedExam"
          >
            <span class="text-primary-link">点击这里上传</span>
          </UploadSheetToJson>
          批量导入题目
        </el-row>
      </el-card>
      <!-- 题目模板展示 -->
      <ExamList
        ref="scrollNode"
        :exam-list="examList"
        :scene-id="sceneId"
        :suitable-type="type"
        :current-index="currentIndex"
        :show-correct="true"
        @onTiggerExam="onTiggerExam"
        @onTitleTiggerExam="onTitleTiggerExam"
        @onSave="onSave"
      />
      <!-- 右边 相似题目提醒 -->
      <SimilarExamList
        :similar-exam-list="similarExamList"
        :show-index="showIndex"
        :current-exam-item="currentExamItem"
        @onReplaceExam="onReplaceExam"
        @onSave="onSave"
        @onChangeNextExam="changeNextExam"
      />
    </el-row>
    <!-- 操作提示 -->
    <div v-show="isShowToast" class="toast-box" :class="{ errorBg: isErrorBg }">
      {{ toastMessage }}
    </div>
    <!-- 底部操作 -->
    <fixed-action-bar>
      <ksh-button
        v-if="$route.query.from !== 'addExamModal'"
        plain
        type="primary"
        @click="onOpenOperationConfirmPop"
      >
        取消
      </ksh-button>
      <ksh-button type="primary" :loading="isSubmit" @click="onSubmit">
        保存
      </ksh-button>
    </fixed-action-bar>
    <!-- 弹窗 -->
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      message="当前页面未编辑完成，您确定要离开当前编辑页面吗？"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="onClosePop"
      @onSubmit="onSubmitPop"
    >
      <div slot="title">确认操作</div>
    </OperationConfirmPop>
    <VideoItemUpload
      :visible="isShowExamVideoModal"
      :video-title="currentExamResourceActionName"
      :video-list="currentExamVideoList"
      @onSubmit="onSuccessExamVideo"
      @onClose="onCloseExamReSourceUploadModal"
    />
    <ImageItemUpload
      :visible="isShowExamImageModal"
      :image-list="currentExamImageList"
      :bucket-type="125"
      :file-size-limit="10"
      @onSubmit="onSuccessExamImage"
      @onClose="onCloseExamReSourceUploadModal"
    />
    <VideoPlayModal
      :visible="showVideoPreviewModal"
      :title="currentExamResourceActionName"
      :video-url="videoPreviewUrl"
      @onClose="onCloseExamReSourceUploadModal"
    />
  </div>
</template>
<script>
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
// import ExamItem from '@/bizComponents/ExamItem'
import SimilarExamList from '@/components/SimilarExamList'
import VideoItemUpload from '@/bizComponents/VideoItemUpload'
import ImageItemUpload from '@/bizComponents/ImageItemUpload'
import VideoPlayModal from '@/bizComponents/VideoPlayModal'
import { mapActions } from 'vuex'
import { setGuid } from '@/utils/util'
import { checkExcel } from '@/utils/exam'
import { saveAs } from 'file-saver'
import { updateExam } from '@/services/materialService.js'
import AddQuestionCard from '@/components/AddQuestionCard'
import ExamList from '@/components/ExamList'
import UploadSheetToJson from '@/bizComponents/UploadSheetToJson'
export default {
  name: 'EditExam',
  filters: {
    similarAnswerFilter(answers) {
      const output = []
      const arr = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T'
      ]
      answers.map((v, i) => {
        if (v.isCorrect) {
          output.push(arr[i])
        }
      })
      return output.join('、')
    }
  },
  components: {
    AddQuestionCard,
    VideoItemUpload,
    ImageItemUpload,
    VideoPlayModal,
    SimilarExamList,
    // ExamItem,
    OperationConfirmPop,
    ExamList,
    UploadSheetToJson
  },
  data() {
    return {
      sceneId: '',
      type: '',
      isLoading: false,
      sceneTypeId: 4,
      canEdit: true,
      templateList: [],
      hasSetJump: false,
      toastMessage: '',
      isErrorBg: false,
      currentExamImageList: [],
      currentExamResourceActionName: '',
      currentExamVideoList: [],
      isShowExamImageModal: false,
      selectDoctorVisible: false,
      isShowExamVideoModal: false,
      selectedDoctorList: [],
      currentExamResourceActionId: '',
      showVideoPreviewModal: false,
      videoPreviewUrl: '',
      isShowToast: false,
      actionExamId: '',
      pdfId: '',
      pdfTitle: '',
      isShowOperationConfirmPop: false,
      similarExamList: [],
      isSubmit: false,
      isPublish: false,
      msg: '',
      isInfo: '',
      sceneType: 'questionnaire',
      examList: [], // 1.single 2.multiple 3.blank_fill 4.text_fill 5.end
      disabledBack: false,
      radio: 1,
      showType: {
        single: true,
        multiple: true,
        blank_fill: true,
        text_fill: true,
        sort: true,
        scale: true
      },
      fileList: [],
      productList: [],
      replaceExamId: '',
      showIndex: 0,
      currentExamItem: {},
      similarMessage: '替换成功'
    }
  },
  computed: {
    projectText() {
      return '题目'
    },
    titleLimit() {
      return 100
    },
    currentIndex() {
      return currentExamId => {
        let current = 0
        let output = 0
        this.examList.map(v => {
          if (v.answerType !== 'page_end' && !v.richTextContent) {
            current++
            if (currentExamId === v.id) {
              output = current
            }
          }
        })
        return output
      }
    },
    isEdit() {
      return !!this.sceneId
    },
    filterCorrect() {
      return (list, examExpand) => {
        const newList = JSON.parse(JSON.stringify(list))
        const strArr = []
        if (examExpand) {
          const examExpandList = examExpand.split(',').map(v => Number(v))
          examExpandList.forEach(it => {
            newList.forEach(v => {
              if (v.answerId == it) {
                strArr.push(v.orderIndex)
              }
              if (v.orderIndex == it) {
                strArr.push(v.orderIndex)
              }
            })
          })
        }
        return strArr
      }
    }
  },
  mounted() {
    this.sceneId = this.$route.query.id
    this.type = this.$route.query.type
    if (this.sceneId) {
      this.init()
    }
    this.onGetProductList()
  },
  methods: {
    ...mapActions([
      'onTrainCreateExam',
      'getSimilarExamList',
      'getVideoUrl',
      'getExamDetail'
    ]),
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onOpenDoctorModal() {
      this.selectDoctorVisible = true
    },
    onShowExamResourceModal(resourceType, item) {
      // 题目资源弹窗
      this.currentExamResourceActionId = item.id
      switch (resourceType) {
        case 1:
          this.isShowExamImageModal = true
          this.currentExamImageList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 1)
            .map(k => {
              return {
                ...k.resource,
                id: k.id || ''
              }
            })
          break
        case 2:
          this.currentExamResourceActionName = item.title
          this.isShowExamVideoModal = true
          this.currentExamVideoList = [...(item.examResourceBOList || [])]
            .filter(v => v.resourceType === 2)
            .map(k => k.resource)
          break
        default:
          break
      }
    },
    onShowVideoPreviewModal(url, videoId) {
      if (url) {
        this.videoPreviewUrl = url
        this.showVideoPreviewModal = true
      } else if (videoId) {
        this.getVideoUrl(videoId).then(
          res => {
            const resData = res.playUrl || []
            this.videoPreviewUrl = resData.filter(
              v => v.indexOf('.mp4') > -1
            )[0]
            if (!this.videoPreviewUrl)
              return this.$message.error('视频资源加载中，请稍后尝试')
            this.showVideoPreviewModal = true
          },
          rea => {
            this.$message.error(rea)
          }
        )
      }
    },
    onCloseExamReSourceUploadModal() {
      this.isShowExamImageModal = false
      this.isShowExamVideoModal = false
      this.currentExamImageList = []
      this.currentExamVideoList = []
      this.currentExamResourceActionId = ''
      this.currentExamResourceActionName = ''
      this.videoPreviewUrl = ''
      this.showVideoPreviewModal = false
    },
    onSuccessExamImage(imageList) {
      this.currentExamImageList = [...imageList]
      this.examList = [...this.examList].map(v => {
        if (this.currentExamResourceActionId === v.id) {
          const examResourceVideoList = [
            ...(v.examResourceBOList || [])
          ].filter(k => k.resourceType === 2)
          const examResourceImageList = [...this.currentExamImageList].map(
            (d, i) => {
              return {
                id: d.id || '',
                resourceType: 1,
                resource: d,
                resourceIndex: i
              }
            }
          )
          v = {
            ...v,
            examResourceBOList: [
              ...examResourceVideoList,
              ...examResourceImageList
            ]
          }
        }
        return v
      })
      this.currentExamResourceActionName = ''
      this.currentExamResourceActionId = ''
      this.currentExamImageList = []
      this.isShowExamImageModal = false
    },
    onSuccessExamVideo(videoList) {
      this.currentExamVideoList = [...videoList]
      this.examList = [...this.examList].map(v => {
        if (this.currentExamResourceActionId === v.id) {
          const examResourceImageList = [
            ...(v.examResourceBOList || [])
          ].filter(k => k.resourceType === 1)
          const examResourceVideoList = [...this.currentExamVideoList].map(
            d => {
              return {
                id: '',
                resourceType: 2,
                resource: d,
                resourceIndex: 0
              }
            }
          )
          v = {
            ...v,
            examResourceBOList: [
              ...examResourceVideoList,
              ...examResourceImageList
            ]
          }
        }
        return v
      })
      this.isShowExamVideoModal = false
      this.currentExamResourceActionId = ''
      this.currentExamResourceActionName = ''
      this.currentExamImageList = []
    },
    onDown() {
      saveAs(
        'https://cdnfile.yaomaitong.cn/excel/examModelUploadV2.xlsx',
        '素材题目导入模版.xlsx'
      )
    },
    onUploadedExam(json) {
      this.examList = checkExcel(json, this.$refs.scrollNode.newExamList, this.$refs.scrollNode.optionsList, this.productList)
    },
    goBack() {
      this.$router.push('/material/list')
    },
    onClosePop() {
      this.isShowOperationConfirmPop = false
    },
    onSubmitPop() {
      this.goBack()
    },
    answerTypeFunc(type) {
      let output = ''
      switch (type) {
        case 'single':
          output = 0
          return 0
        case 'multiple':
          output = 1
          return 1
        case 'blank_fill':
          output = 2
          return 2
        case 'text_fill':
          output = 3
          return 3
        case 'sort':
          output = 5
          return 5
        case 'scale':
          output = 6
          return 6
      }
      return output
    },
    changeNextExam() {
      if (this.showIndex < this.similarExamList.length - 1) {
        this.showIndex++
      } else {
        this.showIndex = 0
      }
    },
    onReplaceExam(currentItem) {
      this.examList = this.$refs.scrollNode.newExamList
      let replaceExam = {}
      if (
        currentItem.examTypeEnum === 'multiple' ||
        currentItem.examTypeEnum === 'single'
      ) {
        const child = currentItem.answers.map(v => {
          return {
            title: v.answer,
            answerId: v.examAnswerId,
            orderIndex: v.orderIndex,
            isCorrect: v.isCorrect
          }
        })
        replaceExam = {
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          leastAnswerCount: currentItem.leastAnswerCount,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          id: setGuid(),
          answers: child,
          isSetCorrectAnswer: currentItem.isSetCorrectAnswer
        }
      } else if (currentItem.examTypeEnum === 'sort') {
        const child = currentItem.answers.map(v => {
          return {
            title: v.answer,
            answerId: v.examAnswerId,
            orderIndex: v.orderIndex,
            isCorrect: v.isCorrect
          }
        })
        replaceExam = {
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          leastAnswerCount: currentItem.leastAnswerCount,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          id: setGuid(),
          answers: child,
          isSetCorrectAnswer: currentItem.isSetCorrectAnswer,
          examExpand: currentItem.examExpand
        }
      } else if (currentItem.examTypeEnum === 'scale') {
        const child = currentItem.answers.map(v => {
          return {
            title: v.answer,
            answerId: v.examAnswerId,
            orderIndex: v.orderIndex,
            isCorrect: v.isCorrect
          }
        })
        replaceExam = {
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          scaleQuestionList: currentItem.scaleQuestionList,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          id: setGuid(),
          answers: child,
          isSetCorrectAnswer: currentItem.isSetCorrectAnswer
        }
      } else {
        replaceExam = {
          id: setGuid(),
          title: currentItem.title,
          examId: currentItem.examId,
          examRichTextContent: currentItem.examRichTextContent,
          knowledgePointId: currentItem.knowledgePointId,
          knowledgePointName: currentItem.knowledgePointName,
          answerType: currentItem.examTypeEnum,
          isRealCanDel: currentItem.isRealCanDel,
          isSetCorrectAnswer: currentItem.isSetCorrectAnswer,
          answers: null
        }
      }
      const sourceList = [...(currentItem.examResourceBOList || [])].map(d => {
        if (d.resourceType === 1) {
          return {
            id: d.id,
            resource: d.imageVo,
            resourceIndex: d.resourceIndex,
            resourceType: d.resourceType
          }
        } else {
          const playUrl =
            [...(d.playUrls || [])].filter(c => c.indexOf('.mp4') > -1)[0] || ''
          return {
            id: d.id,
            resource: { videoId: d.resource, url: playUrl },
            resourceIndex: d.resourceIndex,
            resourceType: d.resourceType
          }
        }
      })
      this.examList = [...this.examList].map(v => {
        if (v.id === this.replaceExamId) {
          v = {
            ...v,
            ...replaceExam,
            save: !v.isInset,
            examResourceBOList: sourceList,
            answers: replaceExam.answers,
            tagInfos: currentItem.tagInfos
          }
        }
        return v
      })
      this.similarExamList = []
      this.$message.success(this.similarMessage)
    },
    onSave(obj) {
      this.examList = this.$refs.scrollNode.newExamList
      this.examList = [...this.examList].map(item => {
        if (obj.id === item.id) {
          item = { ...item, ...obj, save: obj.save }
        }
        return item
      })
      this.similarExamList = []
    },
    onTiggerExam(obj) {
      this.isLoading = true
      this.$axios.post(this.$API.getSimilarList, obj).then(
        res => {
          this.isLoading = false
          if (res) {
            this.similarExamList = res.data || []
            this.replaceExamId = obj.id
            this.currentExamItem = obj
            // 如果有similar=100, 直接替换
            if (!this.similarExamList.length) {
              this.onSave(this.currentExamItem)
            } else if (this.similarExamList[0].similar === 100) {
              this.similarMessage = '发现题目高度相似，已自动替换'
              this.onReplaceExam(this.similarExamList[0])
            } else {
              this.similarMessage = '替换成功'
            }
          }
        },
        () => {
          this.isLoading = false
        }
      )
    },
    onTitleTiggerExam(obj) {
      this.replaceExamId = obj.id
      this.onReplaceExam(obj)
    },
    onAddExam(type, index) {
      this.$refs.scrollNode.onAddExam(type, index)
    },
    initScrollTop(currentY = 0, targetY = 0) {
      const needScrollTop = targetY - currentY
      let _currentY = currentY
      this.timer = setTimeout(() => {
        const dist = Math.ceil(needScrollTop / 20)
        _currentY += dist
        this.$refs.scrollNode.scrollTop = currentY
        // window.scroll(_currentY, currentY)
        if (needScrollTop > 10 || needScrollTop < -10) {
          this.initScrollTop(_currentY, targetY)
        } else {
          // window.scroll(_currentY, targetY)
          this.$refs.scrollNode.scrollTop = currentY
          this.timer && clearTimeout(this.timer)
        }
      }, 1)
    },
    init() {
      this.getExamDetail(this.sceneId).then(res => {
        const resItem = res.data || {}
        const answers = resItem.answers?.map(it => {
          return {
            answerId: it.examAnswerId,
            title: it.answer,
            isCorrect: it.isCorrect,
            orderIndex: it.orderIndex
          }
        })
        const item = {
          ...res.data,
          save: true,
          answers,
          id: setGuid(),
          examResourceBOList: [],
          answerType: res.data.examTypeEnum
        }
        this.examList = [item]
      })
    },
    onGetProductList() {
      this.$axios.get(this.$API.tagList).then(
        res => {
          this.productList = res?.data || []
        },
        ear => {
          this.$message.error(ear.message)
        }
      )
    },
    onCreateQuestion(data) {
      this.onTrainCreateExam(data).then(
        () => {
          this.$message.success('保存成功')
          // this.$emit('onSubmit', res.data || [])
          setTimeout(() => {
            this.isSubmit = false
            this.goBack()
          }, 1000)
        },
        rea => {
          this.isSubmit = false
          this.$message.error(rea)
        }
      )
    },
    onUpdataQuestion(data) {
      updateExam(data).then(
        () => {
          // this.$emit('onSubmit', res.data || [])
          this.$message({
            message: '题目编辑成功',
            type: 'success'
          })
          setTimeout(() => {
            this.isSubmit = false
            this.goBack()
          }, 1000)
        },
        rea => {
          this.isSubmit = false
          this.$message.error(rea.message)
        }
      )
    },
    onSubmit() {
      this.examList = this.$refs.scrollNode.newExamList
      if (!this.examList.length) return this.$message.error('请新建题目')
      const Data = []
      for (const item of this.examList) {
        if (!item.save) {
          return this.$message.error('请点击完成编辑再保存内容')
        }
        const tagInfoList = (item.memberTagInfo || item.tagInfos)?.map(v => {
          return {
            userTagType: 15,
            tagId: v.tagId,
            groupId: v.groupId,
            tagName: v.tagName,
            knowledgePointIds: v.knowledgePointIds
          }
        })
        // const examExpand = this.filterCorrect(
        //   item.answers,
        //   item.examExpand
        // ).toString()
        Data.push({
          examId: item.examId,
          categoryId: item.categoryId,
          title: item.title,
          answerType: item.answerType,
          examRichTextContent: item.examRichTextContent,
          knowledgePointIds: item.knowledgePointId,
          leastAnswerCount: item.leastAnswerCount,
          scaleQuestionList: item.scaleQuestionList,
          answers: item.answers || [],
          analysis: item.analysis,
          examExpand: item.examExpand,
          examAnswerSettingBO: item.examAnswerSettingBO,
          suitableType: this.type,
          isSetCorrectAnswer: item.isSetCorrectAnswer,
          memberTagInfo: tagInfoList
        })
      }
      this.isSubmit = true
      if (this.isEdit) {
        this.onUpdataQuestion(Data[0])
      } else {
        this.onCreateQuestion(Data)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.container-wrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 80px;
}
.scene-slide-left ::v-deep {
  width: 16vw;
  min-width: 250px;
  background-color: #fff !important;
  padding-top: 20px;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  margin-right: 10px;
  position: relative;
  padding-bottom: 100px;
  border: none;
  border-radius: 0;
  box-shadow: none;
  .el-card__body {
    position: static;
  }
  .tag {
    min-width: 80px;
    color: #5a5a5a;
    border-radius: 1px;
    background: #fafafa;
    &.active,
    &:hover {
      color: $color-primary;
      background-color: rgba($color: $color-primary, $alpha: 0.12);
    }
  }
  .fix-wrapper {
    position: absolute;
    left: 1.5vw;
    padding-right: 15px;
    bottom: 100px;
    flex-wrap: wrap;
    .link-wrapper {
      display: inline;
      // flex-wrap: nowrap;
    }
  }
}
.scene-slide-right {
  width: 16vw;
  background-color: #fff;
  margin-left: 10px;
  overflow-y: auto;
  .tag {
    min-width: 80px;
  }
}
.similar-title {
  padding: 10px;
  background: #ffdbda;
  width: 100%;
}
.red-text {
  color: #e8312f;
  font-weight: bold;
}
.warn-tips {
  font-size: 20px;
  margin-right: 4px;
  color: rgba(246, 98, 94, 1);
}
.similar-bottom {
  padding: 12px;
  .el-row {
    padding-top: 10px;
    .similar-btn {
      width: 150px;
    }
  }
}
.change-exam {
  cursor: pointer;
  color: #a3a3a3;
}
.similar-exam-wrapper {
  padding-bottom: 20px;
}
.scene-layout {
  background-color: #fff;
  padding-top: 20px;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-bottom: 110px;
  overflow-y: auto;
  flex: 1;
}
.scene-form-item {
  margin-bottom: 12px;
}
.scene-form-line-item {
  padding: 20px 3vw 25px;
  margin-left: -3vw;
  margin-right: -3vw;
  border-bottom: 1px solid #e9eaeb;
}
.scene-form-label {
  flex-shrink: 0;
  color: #272e40;
  line-height: 50px;
  min-width: 136px;
  padding-right: 10px;
  text-align: right;
}
.scene-form-content {
  flex: 1;
}
.tag-wrapper {
  flex-wrap: wrap;
  // flex: 1;
}
.scene-form-input ::v-deep {
  flex: 1;
  .el-input__inner {
    height: 50px;
    line-height: 50px;
    border-radius: 0;
    border-color: #e9eaeb;
    color: #272e40;
    font-size: 14px;
    padding-left: 25px;
  }
  .el-textarea__inner {
    border-radius: 0;
    border-color: #e9eaeb;
    color: #272e40;
    font-size: 14px;
    padding-top: 15px;
    padding-left: 25px;
  }
  .el-input__count {
    font-size: 14px;
    color: #c8c9ce;
  }
}
.custom-tag {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 20px;
}
.cover-exam-wrapper {
  height: 50px;
}
.wait-btn-wrapper {
  padding-top: 10px;
}
.upload-wrapper {
  width: 160px;
  height: 90px;
  color: #3d61e3;
  font-size: 12px;
  background-color: #e2e3e7;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border: 1px dashed #3d61e3;
  }
}
.exam-item {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #272e40;
  font-size: 14px;
  .color-info {
    padding-left: 50px;
  }
  .el-icon-delete {
    color: #e1e2e6;
    font-size: 20px;
    cursor: pointer;
  }
}
.toast-box {
  color: #fff;
  background: green;
}
.rich-template-title {
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 20px;
}
.rich-template-wrapper {
  cursor: pointer;
  padding: 20px 8px;
  margin-left: -25px;
  margin-right: -25px;
  margin-bottom: 15px;
  border: 2px solid #eee;
  &:hover {
    border-color: #3d61e3;
  }
}
.errorBg {
  background: #d8384a;
}
.ex-tip-item {
  width: 360px;
  padding: 10px;
}
.exam-tip-wrapper {
  margin-top: 20px;
  height: 50px;
  border: 1px solid #e9eaeb;
  background: #fff;
}
.template {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.upload-demo {
  display: inline-block;
}

</style>
