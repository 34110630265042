<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      center
      class="customize-el-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="form-item">
        <el-row type="flex">
          <span>调研名称：</span>
          <el-input
            v-model="form.questionnaireTitle"
            placeholder="请输入调研名称"
            maxlength="40"
            show-word-limit
            class="scene-form-input"
          />
        </el-row>
        <el-row type="flex">
          <span>调研简介：</span>
          <el-input
            v-model="form.questionnaireDesc"
            type="textarea"
            placeholder="请输入调研简介"
            rows="8"
            maxlength="400"
            show-word-limit
            class="scene-form-input"
          />
        </el-row>
        <el-row type="flex">
          <span>答题设置：</span>
          <template>
            <el-radio-group v-model="form.examType" @change="onChangeExamType">
              <el-radio :label="0">分对错</el-radio>
              <el-radio :label="1">不分对错</el-radio>
              <div v-if="form.answerType === 1 && $route.params.sceneType !== 'meeting'" style="margin-left: 30px; color: #c8c9cc;">请至少添加6道题目</div>
            </el-radio-group>
          </template>
        </el-row>
        <el-row type="flex">
          <span>答题方式：</span>
          <el-radio-group v-model="form.answerType">
            <el-radio :label="2">顺序答题（所有题目）</el-radio>
            <el-radio :label="1">
              随机答题
              <span class="font-weight tips">
                （随机抽取 <el-input-number
                  v-model="form.randomExamCount"
                  :min="Math.max(1, meetingMinExamCount)"
                  :max="49"
                  :step="1"
                  step-strictly
                  :precision="0"
                  class="input_number"
                /> 道题目）
              </span>
            </el-radio>
          </el-radio-group>
        </el-row>
        <el-row type="flex">
          <span />
          <div class="exam-list">
            <div class="wait-btn-wrapper">
              <el-button
                size="small"
                @click="onOpenAddExamPop"
              >
                添加问题
                <svg-icon name="el-icon-plus" />
              </el-button>
            </div>
            <div v-if="examList.length">
              <ksh-table
                :data="examList"
                width="100%"
              >
                <el-table-column
                  :show-overflow-tooltip="true"
                  prop="title"
                  fixed
                  :label="'已选题目（共'+examList.length+'题）'"
                >
                  <template slot-scope="scope">
                    {{ scope.row.title | textFilter }}{{ scope.row.examTypeEnum | examTypeFilter }}
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  label="产品标签"
                >
                  <template slot-scope="scoped">
                    <span>{{ onGetTagName(scoped.row.tagInfos) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="form.answerType === 2"
                  prop="trainRank"
                  align="center"
                  label="排序"
                  width="150px"
                >
                  <template slot-scope="scope">
                    <div>
                      <el-button
                        :disabled="scope.$index===0"
                        class="mgn-r10"
                        type="text"
                        size="small"
                        @click="onUp(scope)"
                      >
                        上移
                      </el-button>
                      <el-button
                        :disabled="scope.$index===examList.length - 1"
                        type="text"
                        size="small"
                        @click="onDown(scope)"
                      >
                        下移
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  width="150px"
                  align="center"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      style="color: #e8312f;"
                      @click="onDelExam(scope)"
                    >
                      删除
                    </el-button>
                  </template>
                </el-table-column>
              </ksh-table>
            </div>
          </div>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
    <AddExamModal
      :visible="isShowAddExamModal"
      :limit="50"
      :min-limit="form.answerType === 1 ? meetingMinExamCount + 1 : meetingMinExamCount"
      :tag-id="tagId"
      :select-list="examList"
      :exam-type="form.examType"
      @onSubmit="onSubmitExam"
      @onClose="onClosePop"
    />
  </div>
</template>

<script>
import AddExamModal from '@/bizComponents/AddExamModal.vue'
export default {
  name: 'AddEntrustDialog',
  components: {
    AddExamModal
  },
  filters: {
    textFilter(text) {
      return text.replace(/<filter><\/filter>/g, ' ______ ')
    },
    examTypeFilter(type) {
      switch (type) {
        case 'single':
          return '（单选题）'
        case 'multiple':
          return '（多选题）'
        case 'text_fill':
          return '（问答题）'
        case 'blank_fill':
          return '（填空题）'
        case 'sort':
          return '（排序题）'
        case 'scale':
          return '（量表题）'
      }
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    activityQuestId: {
      type: String,
      default: ''
    },
    sceneId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        activityQuestId: '',
        questionnaireTitle: '',
        questionnaireDesc: '',
        examType: 0,
        answerType: 2,
        randomExamCount: 5
      },
      examList: [],
      isShowAddExamModal: false,
      tagId: '',
      meetingMinExamCount: 5
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('close', val)
      }
    },
    title() {
      return this.isEdit ? '编辑问卷' : '新增问卷'
    }
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          const params = {
            uOrgId: this.$store.state.org.factoryId
          }
          this.$axios.get(this.$API.getDoctorCertifyRule, { params }).then(res => {
            if (this.$route.params.sceneType === 'meeting') {
              this.meetingMinExamCount = res.data.meetingMinExamCount
              this.form.randomExamCount = this.meetingMinExamCount
            }
          }, err => {
            this.$message.error(err.message)
          })
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.isEdit && this.getDetail()
  },
  methods: {
    getDetail() {
      // 获取调研详情
      if (!this.activityQuestId) return
      this.$axios.get(this.$API.getDelegationDetail, { params: {activityQuestId: this.activityQuestId} }).then(res => {
        const { data = {} } = res
        this.form = {
          activityQuestId: this.activityQuestId,
          questionnaireTitle: data.title,
          questionnaireDesc: data.intro,
          examType: data.examType,
          answerType: data.answerType,
          randomExamCount: data.randomExamCount
        },
        this.examList = res.data.examVos || []
      }, err => {
        this.$message.error(err.message)
      })
    },
    submitForm() {
      if (!this.form.questionnaireTitle) {
        this.$message.error('请输入调研名称')
        return
      }
      if (!this.form.questionnaireDesc) {
        this.$message.error('请输入调研简介')
        return
      }
      if (!this.examList.length) {
        this.$message.error('请添加问题')
        return
      }
      if (this.form.answerType === 1) {
        if (this.examList.length < this.meetingMinExamCount + 1) {
          this.$message.error(`题目数量不能少于${this.meetingMinExamCount + 1}道`)
          return
        }
      } else {
        if (this.examList.length < this.meetingMinExamCount) {
          this.$message.error(`题目数量不能少于${this.meetingMinExamCount}道`)
          return
        }
      }
      if (this.form.answerType === 1 && this.form.randomExamCount > this.examList.length) {
        this.$message.error('随机抽取题目数量不能大于题目总数')
        return
      }
      if (this.form.answerType === 1 && !this.form.randomExamCount) {
        this.$message.error('请输入随机抽取题目数量')
        return
      }
      const obj = {}
      this.examList.forEach((item, i) => {
        obj[item.examId] = {
          examIndex: i,
          isRequired: true
        }
      })
      const data = {
        ...this.form,
        examIdAndIndex: obj
      }
      this.$emit('submit', data)
    },
    onChangeExamType() {
      this.examList = []
    },
    onSubmitExam(arr) {
      this.examList = arr
    },
    onOpenAddExamPop() {
      // 获取活动产品标签
      this.$axios.get(this.$API.getSceneTag, {params: {sceneId: this.sceneId }}).then(res => {
        this.tagId = res.data.id
        this.isShowAddExamModal = true
      }, err => {
        this.$message.error(err.message)
      })
    },
    onClosePop() {
      this.isShowAddExamModal = false
    },
    onUp(options) {
      this.examList.splice(options.$index, 1)
      this.examList.splice(options.$index - 1, 0, options.row)
    },
    onDown(options) {
      this.examList.splice(options.$index, 1)
      this.examList.splice(options.$index + 1, 0, options.row)
    },
    onDelExam(options) {
      this.examList.splice(options.$index, 1)
    },
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-') || '-'
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.form-item {
  margin-left: 10px;
  margin-bottom: 30px;
  .el-row {
    align-items: baseline;
    span {
      width: 80px;
    }
  }
  div {
    margin-bottom: 5px;
    color: #5a5a5a;
  }
}
.input_number {
  ::v-deep .el-input--mini .el-input__inner {
    font-weight: bold;
  }
}
.exam-content {
  .wait-btn-wrapper {
    margin-bottom: 10px;
  }
}
.exam-list {
  width: 100%;
}
</style>
