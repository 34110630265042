<template>
  <div class="">
    <div v-if="activityQuestId" class="data-analysis-box">
      <el-button type="primary" @click="onDataAnalysis">数据分析</el-button>
    </div>
    <div class="title-content">
      <div v-if="entrustList.length" class="mgn-b5">
        委托调研名称：
        <el-select
          v-model="activityQuestId"
          @change="getDetail"
        >
          <el-option
            v-for="item in entrustList"
            :key="item.activityQuestId"
            :label="item.title"
            :value="item.activityQuestId"
          />
        </el-select>
      </div>
      <div v-if="detail.intro" class="mgn-b5">委托调研简介：{{ detail.intro }}</div>
      <div class="mgn-b5">题目设置：{{ detail.examType === 0? '分对错': '不分对错' }}</div>
      <div v-if="detail.answerType === 1">答题方式：{{ `${'共' + examList.length + '题，答题时随机展示' + detail.randomExamCount + '题'}` }}</div>
      <div v-else>答题方式：{{ `${'共' + examList.length + '题，答题时按顺序展示'}` }}</div>
    </div>
    <div class="exam-content">
      <exam-item
        v-for="(item, index) in examList"
        :key="item.examId"
        :item="item"
        :index="index + 1"
      />
    </div>
  </div>
</template>

<script>
import ExamItem from '@/bizComponents/ExamItem'

export default {
  name: '',
  components: {
    ExamItem
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    sceneId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activityQuestId: '',
      entrustList: [],
      detail: {},
      examList: []
    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {
    this.activityQuestId = this.id || ''
    this.getEntrustList()
  },
  methods: {
    onDataAnalysis() {
      const url = this.$router.resolve({name: 'DataAnalysis', params: {
        id: this.activityQuestId || ''
      }, query: {
        businessType: 1
      }
      }).href
      window.open(url, '_blank')
    },
    getEntrustList() {
      // 获取调研列表
      this.$axios.get(this.$API.historyDelegationList, {params: {sceneId: this.sceneId}}).then(res => {
        this.entrustList = res.data || []
        this.getDetail()
      }, err => {
        this.$message.error(err.message)
      })
    },
    getDetail() {
      // 获取调研详情
      if (!this.activityQuestId) return
      this.$axios.get(this.$API.getDelegationDetail, { params: {activityQuestId: this.activityQuestId} }).then(res => {
        this.detail = res.data || {}
        this.examList = res.data.examVos || []
      }, err => {
        this.$message.error(err.message)
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.title-content {
  padding: 10px;
  color: #969696;
  background: #f5f5f5;
  font-size: 12px;
  margin-bottom: 10px;
}
.exam-content {
  height: 500px;
  overflow-y: auto;
}
.data-analysis-box {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
</style>
