<template>
  <div class="exam-wrapper" @mouseenter="onMouseOver" @mouseleave="onMouseOut">
    <div>
      <el-row type="flex">
        <span v-if="isEdit" class="little-title">题目：</span>
        <el-autocomplete
          v-if="isEdit"
          v-model.trim="title"
          :debounce="300"
          :disabled="isUsed"
          :placeholder="titlePlaceholder"
          :maxlength="200"
          :trigger-on-focus="false"
          show-word-limit
          class="input-sze"
          style="padding-top: 0; width: 100%;"
          :fetch-suggestions="querySearchAsync"
          @select="handleSelect"
        />
        <span v-if="!isEdit" style="overflow-wrap: anywhere;">{{ index }}. {{ title }} {{ item.answerType === 'single' ? '（单选题）' : item.leastAnswerCount ? '（多选题，至少选' + item.leastAnswerCount + '项）' : '（多选题）' }}</span>
      </el-row>
      <div v-if="!isEdit" v-safe-html="item.examRichTextContent" class="rich-content" />
      <el-row v-if="isEdit && item.answerType === 'multiple'" type="flex" class="exam-answer-wrapper">
        <span class="little-title">设置：</span>
        <ksh-select
          v-model="leastAnswerCount"
          clearable
          placeholder="至少选择几项"
          :disabled="isPreSet"
        >
          <el-option
            v-for="chooseListItem in chooseList"
            :key="chooseListItem.leastAnswerCount"
            :label="'至少选择' + chooseListItem.leastAnswerCount + '项'"
            :value="chooseListItem.leastAnswerCount"
          />
        </ksh-select>
      </el-row>
      <el-row
        v-for="(v, i) in answers"
        :key="i"
        type="flex"
        align="middle"
        class="exam-answer-wrapper"
        :style="`margin-left: ${isEdit ? '' : '13px'}`"
      >
        <template v-if="isEdit">
          <span class="little-title" style="padding-right: 5px;">{{ `${alphabet[i]}.` }}</span>
          <ksh-input
            v-model.trim="v.title"
            :placeholder="`选项${alphabet[i]}`"
            maxlength="100"
            show-word-limit
            style="width: 300px;"
            :disabled="isUsed"
          />
          <div class="answer-operation cursor">
            <el-checkbox
              :value="v.isCorrect"
              :disabled="isPreSet"
              @change="onSetAnswer(item, v, $event)"
            >
              推荐/正确选项
            </el-checkbox>
          </div>
          <el-row type="flex" class="answer-opt-wrapper">
            <i
              class="el-icon-circle-plus-outline opt-label"
              :class="{
                disable: answers.length > alphabet.length - 1 || isUsed,
              }"
              @click="onAddAnswer(i)"
            />
            <i class="el-icon-remove-outline opt-label" :class="{ disable: answers.length < 3 || isUsed }" @click="onDelAnswer(i)" />
          </el-row>
        </template>
        <template v-else>
          <span class="answer-item" :class="{ radio: item.answerType === 'single' }">{{ `${alphabet[i]}.${v.title}` }} {{ v.isCorrect ? '（推荐/正确选项）' : '' }}</span>
        </template>
      </el-row>
      <el-row type="flex" style="align-items: baseline;">
        <span class="little-title">标签/关键信息：</span>
        <div class="scene-form-content">
          <ProductLabelKnowledge :selected-tag-list="memberTagInfo" :disabled-ids="disabledIds" @change="onTagChange" />
          <div v-if="isEdit">
            <el-button plain type="info" @click="chooseKnowledge">选择 <svg-icon name="el-icon-plus" /> </el-button>
          </div>
        </div>
        <el-row v-if="isEdit" type="flex" style="align-items: baseline;">
          <span class="little-title">分类：</span>
          <ksh-select v-model="categoryId" clearable placeholder="选择分类">
            <el-option
              v-for="optionsListItem in optionsList"
              :key="optionsListItem.categoryId"
              :label="optionsListItem.title"
              :value="optionsListItem.categoryId"
            />
          </ksh-select>
        </el-row>
      </el-row>
      <el-row type="flex">
        <span v-if="isEdit || analysis" class="little-title">解析：</span>
        <ksh-input
          v-if="isEdit"
          v-model.trim="analysis"
          placeholder="请输入题目解析"
          maxlength="200"
          type="textarea"
          show-word-limit
          class="input-sze"
        />
        <span v-if="!isEdit && analysis">{{ analysis }}</span>
      </el-row>
      <el-row type="flex">
        <span v-if="isEdit && showRichText" class="little-title">富文本：</span>
        <Editor
          v-show="isEdit && showRichText"
          ref="editor"
          :ueditor-id="item.id"
          :init-content="examRichTextContent"
          :height="400"
          class="editor"
        />
      </el-row>
      <el-row
        v-if="!isEdit && (hasImage || hasVideo) && isNeed"
        type="flex"
        justify="start"
        align="middle"
      >
        <i class="el-icon-picture-outline" style="font-size: 16px; padding-right: 5px;" />
        <el-button v-if="hasImage" type="text" class="active-btn">
          查看图片
          <el-image class="image-preview" :src="require('@/assets/common/empty_tb.png')" :preview-src-list="imageList" />
        </el-button>
        <el-button
          v-if="hasVideo"
          type="text"
          class="active-btn"
          @click="onOpenPreview(item)"
        >
          查看视频
        </el-button>
      </el-row>
    </div>
    <div v-if="item.hasSet" class="tips-w">* 此题设置了跳题逻辑</div>
    <el-row
      v-if="showAction"
      type="flex"
      justify="space-between"
      align="middle"
      class="opt-wrapper"
    >
      <el-row type="flex" align="middle">
        <el-row v-if="hasHover || showPop">
          <el-popover
            placement="bottom"
            trigger="click"
            :value="showPop"
            @show="onShow"
            @hide="onHide"
          >
            <AddOtherExam @addExam="onAddExam" />
            <span slot="reference" class="text-primary-link">
              <i class="el-icon-plus font-weight" />
              <span class="mgn-l5">在此题后加入新题</span>
            </span>
          </el-popover>
          <span v-if="isEdit" class="text-primary-link mgn-l20" @click="onChangeRichText">
            <i v-if="!showRichText" class="el-icon-plus font-weight" />
            <span class="mgn-l5 add-rich-text" :class="{ active: showRichText }">
              {{ showRichText ? '删除题目描述（图文）' : '添加题目描述（图文）' }}
            </span>
          </span>
          <span v-if="showJump && isNeed" class="text-primary-link mgn-l20" @click.stop="onJump">{{ item.hasSet ? '编辑跳题逻辑' : '设置跳题逻辑' }}</span>
        </el-row>
      </el-row>
      <el-row>
        <el-row v-if="isEdit">
          <el-button type="primary" plain @click="onDel"> 删除 </el-button>
          <el-button type="primary" @click="onSave">完成编辑</el-button>
        </el-row>
        <exam-opt-action
          v-else-if="!item.hasSet"
          :show-move="isNeed"
          :show-edit="!item.quoted"
          @onDel="onDel"
          @onEdit="onEdit"
          @onMoveUp="onMoveUp"
          @onMoveDown="onMoveDown"
        />
      </el-row>
    </el-row>
    <ProductLabel
      :visible.sync="knowledgeDialog"
      :disabled-ids="disabledIds"
      :selected-tag-list="memberTagInfo"
      :options="{
        SPREAD_DEPARTMENT_GROUP: {
          isShowTab: false,
        },
        CUSTOMIZE_TAG_GROUP: {
          isShowTab: false,
        },
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>
<script>
import Editor from '@/baseComponents/Editor'
import AddOtherExam from '@/components/AddOtherExam'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'ExamSimple',
  components: {
    Editor,
    AddOtherExam,
    ProductLabel,
    ProductLabelKnowledge
  },
  props: {
    showAction: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    showJump: {
      type: Boolean,
      default: true
    },
    item: {
      type: Object,
      default() {
        return {}
      }
    },
    isNeed: {
      //  素材问卷不需要
      type: Boolean,
      default: false
    },
    optionsList: {
      type: Array,
      default: () => []
    },
    suitableType: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      title: '',
      categoryId: '',
      examRichTextContent: '',
      hasHover: false,
      showPop: false,
      answers: [
        { title: '', isCorrect: false },
        { title: '', isCorrect: false },
        { title: '', isCorrect: false },
        { title: '', isCorrect: false }
      ],
      message: '',
      showRichText: false,
      knowledgeDialog: false,
      leastAnswerCount: '',
      analysis: '',
      answerType: '',
      chooseList: [],
      isSetCorrectAnswer: false,
      isUsed: false,
      memberTagInfo: [],
      usedIds: [],
      isPreSet: false,
      alphabet: Array.from(new Array(26), (ele, index) => {
        return String.fromCharCode(65 + index)
      }),
      isExport: false,
      inputSearchList: [],
      loading: false,
      hasShowCorrectDia: false
    }
  },
  computed: {
    titlePlaceholder() {
      return this.item.answerType === 'single' ? '【单选题】请输入题目' : '【多选题】请输入题目'
    },
    isEdit() {
      return !this.item.save
    },
    hasImage() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 1).length > 0
    },
    videoUrl() {
      const ot = [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).map(k => k.resource)[0] || {}
      return ot.url || ''
    },
    videoId() {
      const ot = [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).map(k => k.resource)[0] || {}
      return ot.videoId || ''
    },
    imageList() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 1).map(k => k.resource.middle)
    },
    hasVideo() {
      return [...(this.item.examResourceBOList || [])].filter(v => v.resourceType === 2).length > 0
    },
    disabledIds() {
      if (!this.isEdit) {
        const arr = []
        this.memberTagInfo?.map(v => {
          arr.push(v.tagId)
          v.knowledgePointInfos?.map(k => {
            arr.push(k.knowledgePointId)
          })
        })
        return arr
      } else {
        return this.isExport ? [] : this.usedIds
      }
    }
  },
  mounted() {
    this.title = this.item.title || ''
    this.categoryId = this.item.category ? this.item.category.categoryId : null
    this.memberTagInfo = this.item.tagInfos || []
    this.usedIds = this.item.tagInfos?.map(v => v.tagId)
    this.examRichTextContent = this.item.examRichTextContent || ''
    this.showRichText = !!this.item.examRichTextContent
    this.leastAnswerCount = this.item.leastAnswerCount || ''
    this.analysis = this.item.analysis || ''
    this.answerType = this.item.answerType || ''
    this.isSetCorrectAnswer = this.item.isSetCorrectAnswer
    this.isPreSet = this.item.isSetCorrectAnswer
    this.isUsed = !this.item.isRealCanDel
    this.isExport = this.item.isExport || false
    this.answers = this.item.answers || this.item.questionnaireAnswerBoList || this.answers
    for (let i = 1; i < this.answers.length; i++) {
      this.chooseList.push({ leastAnswerCount: i + 1 })
    }
  },
  methods: {
    querySearchAsync(queryString, cb) {
      if (!queryString) return []
      this.loading = true
      this.$axios.get(this.$API.fuzzyMatchList, {params: {searchKey: queryString, answerType: this.answerType === 'single' ? 0 : 1}}).then(
        res => {
          this.loading = false
          this.inputSearchList = res.data || []
          const results = this.inputSearchList.map(v => {
            return {...v, value: v.title}
          })
          cb(results)
        },
        (rea => {
          this.loading = false
          this.$message.error(rea.message)
        })
      )
    },
    handleSelect(item) {
      this.title = item.value
      this.$emit('onTiggerTitle', { ...item, id: this.item.id })
    },
    onSubmitTag(selectedList) {
      this.knowledgeDialog = false
      this.memberTagInfo = [...selectedList]
    },
    onTagChange(selectedList) {
      this.memberTagInfo = [...selectedList]
    },
    onOpenVideoPop() {
      if (!this.title) return this.$message.error('请输入题目标题')
      this.$emit('onEditExamResource', 2, { ...this.item, title: this.title })
    },
    onOpenPreview() {
      this.$emit('onVideoPreview', this.videoUrl, this.videoId)
    },
    onOpenImagePop() {
      this.$emit('onEditExamResource', 1, { ...this.item })
    },
    onOpenImagePreview(item) {
      console.log(item, '查看图片')
    },
    // 上传图片 end
    onAddExam(type) {
      this.showPop = false
      this.$emit('onAddExam', type)
    },
    onJump() {
      this.$emit('onJump')
    },
    onSetAnswer(item, it, event) {
      if (this.isPreSet) return
      if (item.answerType === 'single') {
        if (event) {
          this.answers.forEach(answer => {
            if (answer !== it) {
              this.$set(answer, 'isCorrect', false)
            }
          })
        }
        this.$set(it, 'isCorrect', event)
      } else if (item.answerType === 'multiple') {
        this.$set(it, 'isCorrect', event)
      }
    },
    onAddAnswer(currentIndex) {
      if (this.answers.length > this.alphabet.length - 1 || this.isUsed) return
      this.answers.splice(currentIndex + 1, 0, { title: '' })
      this.chooseList.push({ leastAnswerCount: this.answers.length })
    },
    onDelAnswer(currentIndex) {
      if (this.answers.length < 3 || this.isUsed) return
      this.leastAnswerCount = ''
      this.answers = [...this.answers].filter((v, i) => i !== currentIndex)
      this.chooseList = [...this.chooseList].filter((v, i) => i !== this.answers.length - 1)
    },
    onMouseOver() {
      this.hasHover = true
    },
    onMouseOut() {
      this.hasHover = false
    },
    showMessage(msg) {
      this.$message.error(msg)
    },
    onShow() {
      this.showPop = true
    },
    onHide() {
      this.showPop = false
    },
    chooseKnowledge() {
      this.knowledgeDialog = true
    },
    onValid() {
      if (!this.title) {
        this.showMessage('请输入题目题目')
        return false
      }
      let msg = ''
      this.isSetCorrectAnswer = false
      let answerNum = 0
      const titles = []
      this.answers.map((v, i) => {
        v.orderIndex = i + 1
        if (!v.title) {
          msg += `选项${this.alphabet[i]}未填写。`
        }
        if (v.isCorrect) {
          this.isSetCorrectAnswer = true
          answerNum += 1
        }
        titles.push(v.title)
      })
      if (msg) {
        this.showMessage(msg)
        return false
      }
      // 判断是否选择了标签/关键信息
      if (this.memberTagInfo.length === 0) {
        this.showMessage('请选择标签/关键信息')
        return false
      }
      if (this.item.answerType === 'multiple' && answerNum === 1) {
        this.showMessage('请设置至少两个答案')
        return false
      }
      const nameSet = new Set(titles)
      if (nameSet.size !== titles.length) {
        this.showMessage('选项不能重复')
        return false
      }
      if (this.item.answerType === 'multiple' && this.isSetCorrectAnswer) {
        if (this.leastAnswerCount > answerNum) {
          this.showMessage('至少选几项与推荐/正确选项数不符')
          return false
        }
      }
      if (this.item.answerType === 'single' && answerNum > 1) {
        this.$MessageDialog({
          title: '提示',
          message: '此题为单选题，设置了多个推荐/正确选项，是否切换成多选题？',
          submitText: '切换'
        })
          .then(() => {
            this.changeType()
          })
          .catch(() => {
            return false
          })
        return false
      }
      // 如果没有设置推荐/正确选项，提示是否设置
      if (!this.isSetCorrectAnswer && !this.hasShowCorrectDia) {
        this.$MessageDialog({
          title: '温馨提示',
          message: '<div style="padding: 20px;font-size: 14px;">为了收集更全面、有价值的数据，建议您为问卷每题设定<span style="color: #3D61E3;">推荐/正确选项</span>。这将助力深入分析，让调研结果更精准、有意义。</div>',
          submitText: '我知道了',
          cancelText: ''
        })
          .then(() => {
            this.hasShowCorrectDia = true
            return true
          })
        return false
      }
      return true
    },
    changeType() {
      this.answerType = 'multiple'
      this.$emit('onSave', {
        ...this.item,
        answers: this.answers,
        title: this.title,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        leastAnswerCount: this.leastAnswerCount,
        examRichTextContent: this.showRichText ? this.$refs.editor.getValue() : '',
        suitableType: this.suitableType,
        analysis: this.analysis,
        answerType: this.answerType,
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: true
      })
    },
    onSave() {
      if (!this.onValid()) return
      this.examRichTextContent = this.showRichText ? this.$refs.editor.getValue() : ''
      const data = {
        ...this.item,
        answers: this.answers,
        title: this.title,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        leastAnswerCount: this.leastAnswerCount,
        examRichTextContent: this.showRichText
          ? this.$refs.editor.getValue()
          : '',
        analysis: this.analysis,
        suitableType: this.suitableType,
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: true
      }
      this.$emit('onTigger', data)
    },
    onDel() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          () => {
            this.$emit('onDel')
          },
          this.item.examId
        )
        return
      }
      this.$emit('onDel')
    },
    onEdit() {
      if (this.item.hasSet) {
        this.$emit(
          'onValid',
          () => {
            this.$emit('onSave', {
              ...this.item,
              answers: this.answers,
              title: this.title,
              memberTagInfo: this.memberTagInfo,
              categoryId: this.categoryId,
              leastAnswerCount: this.leastAnswerCount,
              examRichTextContent: this.showRichText ? this.$refs.editor.getValue() : '',
              suitableType: this.suitableType,
              save: false
            })
          },
          this.item.examId
        )
        return
      }
      this.$emit('onSave', {
        ...this.item,
        answers: this.answers,
        title: this.title,
        memberTagInfo: this.memberTagInfo,
        categoryId: this.categoryId,
        leastAnswerCount: this.leastAnswerCount,
        examRichTextContent: this.showRichText
          ? this.$refs.editor.getValue()
          : '',
        analysis: this.analysis,
        suitableType: this.suitableType,
        isSetCorrectAnswer: this.isSetCorrectAnswer,
        save: false
      })
      this.$refs.editor.onSetContent()
    },
    onTig(item) {
      if (!this.title) {
        return
      }
      this.$emit('onTiggerTitle', { ...item, id: this.item.id })
    },
    onMoveUp() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveUp')
        })
        return
      }
      this.$emit('onMoveUp')
    },
    onMoveDown() {
      if (this.item.hasSet) {
        this.$emit('onValid', () => {
          this.$emit('onMoveDown')
        })
        return
      }
      this.$emit('onMoveDown')
    },
    onChangeRichText() {
      this.examRichTextContent = this.$refs.editor.clearValue()
      this.showRichText = !this.showRichText
    }
  }
}
</script>
<style lang="scss" scoped>
.exam-wrapper ::v-deep {
  position: relative;
  padding-top: 20px;
  padding-bottom: 75px;
  .el-input__inner {
    border-radius: 0;
  }
  .input-sze {
    .el-input__inner {
      height: 50px;
    }
  }
}
.exam-answer-wrapper,
.input-sze {
  padding-bottom: 6px;
  padding-top: 6px;
  position: relative;
  .answer-opt-wrapper {
    margin-left: 5px;
  }
  .opt-label {
    cursor: pointer;
    margin-right: 10px;
    font-size: 20px;
    color: $color-primary;
  }
  .answer-item {
    position: relative;
    padding-left: 25px;
    color: #7784a1;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      width: 12px;
      height: 12px;
      border-radius: 4px;
      border: 2px solid #e1e2e6;
      flex-shrink: 0;
    }
    &.radio {
      &::before {
        border-radius: 50%;
      }
    }
  }
}
.tips-w {
  padding-top: 5px;
  color: #bbb;
}
.opt-wrapper {
  position: absolute;
  height: 40px;
  bottom: 20px;
  left: 0;
  right: 0;
}
.alert-message {
  z-index: 1000;
  margin-bottom: 10px;
  width: 520px;
}
.active-btn {
  color: rgba(25, 140, 255, 1);
  text-decoration: underline;
  position: relative;
  .image-preview {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
.add-rich-text {
  &.active {
    color: #e8312f;
    margin-top: 5px;
  }
}
.editor {
  width: 100%;
  line-height: initial;
}
.little-title {
  color: #5a5a5a;
  width: 110px;
  text-align: right;
  flex: none;
}
.button-default {
  margin: 10px 0;
  ::v-deep {
    .el-button--default {
      border: 1px solid #dcdfe6;
      color: #606266;
    }
  }
}
.answer-operation {
  text-align: center;
  margin: 0 10px;
  padding: 0 10px;
  color: #5a5a5a;
}
.answer-surce {
  background: #d2f1c9;
  color: #290;
}
.choose-again {
  cursor: pointer;
  text-decoration: underline;
  margin-left: 10px;
  color: #3d61e3;
}
.rich-content {
  word-break: break-all;
  ::v-deep {
    img {
      width: 300px;
    }
  }
}
.exam-answer-wrapper ::v-deep .disable {
  color: #c0c4cc;
}
.scene-form-content {
  flex: 1;
}
</style>
