<template>
  <div class="recall-scene-code-modal">
    <el-dialog :visible.sync="showDialog" append-to-body>
      <div slot="title" class="font-weight">
        撤回任务<span>{{
          !isSelfBuy
            ? "（可撤回我及我以下组织分配的学术任务）"
            : "（可撤回我分配的学术任务）"
        }}</span>
      </div>
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <template v-for="item in baseTabsList">
            <el-tab-pane
              v-if="item.show !== sceneType"
              :key="item.name"
              :label="item.label"
              :disabled="loading"
              :name="item.name"
            />
          </template>
        </el-tabs>
      </template>
      <template>
        <div class="code-list-box">
          <div class="code-list-title">选择可撤回的任务：</div>
          <el-scrollbar>
            <div v-if="codeList.length > 0" class="code-list-label">
              <template v-for="(item, codeIndex) in codeList">
                <CodeLabel
                  :key="codeIndex"
                  :item="item"
                  :index="codeIndex"
                  :less-count="item.canRecallCount"
                  :scene-type="sceneType"
                  :show-title="true"
                  @onClick="onSelectCodeLabel"
                />
              </template>
            </div>
            <div v-else>-</div>
          </el-scrollbar>
        </div>
      </template>
      <template>
        <div class="recall-scene-code-modal-checkbox">
          <div class="checkbox-title flex-between flex-wrap">
            <el-checkbox
              v-model="checkAll"
              :indeterminate="isIndeterminate"
              @change="handleCheckAllChange"
            >
              <span
                :class="{ 'has-select': totalNum > 0 }"
              >全部（本次撤回任务{{ totalNum }}个）</span>
            </el-checkbox>
            <!-- <el-input
              class="search-input"
              :placeholder="`请搜索${placeholderStr}`"
              prefix-icon="el-icon-search"
              v-model="searchObj.searchName"
              @input="searchDoctorName">
            </el-input> -->
          </div>
          <el-scrollbar
            ref="checkScrollbar"
            v-loading="loading"
            class="scroll-list"
          >
            <div v-show="recallCodeList.length > 0" style="display: flex;">
              <div>
                <el-checkbox-group
                  v-model="checkedRecallCodeList"
                  @change="handleCheckedRecallCodeListChange"
                >
                  <div style="width: 100%; height: 50px;" />
                  <template v-if="activeName === 'represent'">
                    <div
                      v-for="item in recallCodeList"
                      :key="item.representId + '-' + item.orgId"
                      class="checkbox-item"
                    >
                      <el-checkbox
                        :label="item.representId + '-' + item.orgId"
                      >
                        <span />
                      </el-checkbox>
                    </div>
                  </template>
                  <template v-if="activeName === 'department'">
                    <div
                      v-for="item in recallCodeList"
                      :key="item.factoryId"
                      class="checkbox-item"
                    >
                      <el-checkbox
                        :label="item.factoryId"
                      >
                        <span />
                      </el-checkbox>
                    </div>
                  </template>
                  <template v-if="activeName === 'doctor'">
                    <div
                      v-for="item in recallCodeList"
                      :key="item.codeId"
                      class="checkbox-item"
                    >
                      <el-checkbox
                        :label="item.codeId"
                      >
                        <span />
                      </el-checkbox>
                    </div>
                  </template>
                </el-checkbox-group>
              </div>
              <div
                v-if="activeName === 'represent'"
                class="checkbox-message-box"
              >
                <div
                  style="
                    text-align: center;
                    padding-right: 30px;
                    line-height: 50px;
"
                >
                  <el-row justify="center" align="middle">
                    <el-col :span="4">代表姓名</el-col>
                    <el-col :span="6">所属组织</el-col>
                    <el-col :span="5">可撤回任务数</el-col>
                    <el-col :span="9">撤回</el-col>
                  </el-row>
                </div>
                <template v-for="item in recallCodeList">
                  <div
                    :key="item.representId + '-' + item.orgId"
                    class="checkbox-message-item"
                  >
                    <el-row justify="center" align="middle">
                      <el-col :span="4">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.representName"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.representName || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="6">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.orgName"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.orgName || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="5">
                        <el-button
                          type="text"
                          class="text-ellipsis"
                          style="width: 100%; color: #272e40;"
                        >
                          <span>任务数：{{ item.canRecallCount || 0 }}个</span>
                        </el-button>
                      </el-col>
                      <el-col :span="9">
                        <div :class="{ empty: item.isEmpty }">
                          <span>撤回</span>
                          <el-input-number
                            v-model="item.inputVal"
                            placeholder="请输入个数"
                            style="width: 140px; margin: 0 10px;"
                            :min="0"
                            :max="item.canRecallCount"
                            @input="handleInput($event, item)"
                          />
                          <span>个</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
              <div
                v-if="activeName === 'department'"
                class="checkbox-message-box"
              >
                <div
                  style="
                    text-align: center;
                    padding-right: 30px;
                    line-height: 50px;
"
                >
                  <el-row justify="center" align="middle">
                    <el-col :span="7">组织名称</el-col>
                    <el-col :span="8">可撤回任务数</el-col>
                    <el-col :span="9">撤回</el-col>
                  </el-row>
                </div>

                <template v-for="item in recallCodeList">
                  <div :key="item.factoryId" class="checkbox-message-item">
                    <el-row justify="center" align="middle">
                      <el-col :span="7">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.factoryName"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.factoryName || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="8">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="
                            '任务：' +
                              (item.canRecallCount || 0) +
                              '个'
                          "
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            <span>可撤回任务：</span>
                            <span>{{ item.canRecallCount || 0 }}</span>
                            <span>个</span>
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="9">
                        <div :class="{ empty: item.isEmpty }">
                          <span>撤回</span>
                          <el-input-number
                            v-model="item.inputVal"
                            placeholder="请输入个数"
                            style="width: 140px; margin: 0 10px;"
                            :min="0"
                            :max="item.canRecallCount"
                            @input="handleInput($event, item)"
                          />
                          <span>个</span>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
              <div v-if="activeName === 'doctor'" class="checkbox-message-box">
                <div
                  style="
                    text-align: center;
                    padding-right: 30px;
                    line-height: 50px;
"
                >
                  <el-row justify="center" align="middle">
                    <el-col :span="3">医生姓名</el-col>
                    <el-col :span="3">职称</el-col>
                    <el-col :span="3">科室</el-col>
                    <el-col :span="4">医院</el-col>
                    <el-col :span="3">医生编号</el-col>
                    <el-col :span="3">服务代表</el-col>
                    <el-col :span="5">代表所属组织</el-col>
                  </el-row>
                </div>
                <template v-for="item in recallCodeList">
                  <div :key="item.codeId" class="checkbox-message-item">
                    <el-row justify="center" align="middle">
                      <el-col :span="3">
                        <el-button
                          type="text"
                          class="text-ellipsis"
                          style="width: 100%; color: #272e40;"
                        >
                          {{ item.name || "—" }}
                        </el-button>
                      </el-col>
                      <el-col :span="3">
                        <el-button
                          type="text"
                          class="text-ellipsis"
                          style="width: 100%; color: #272e40;"
                        >
                          {{ item.titleName || "—" }}
                        </el-button>
                      </el-col>
                      <el-col :span="3">
                        <el-button
                          type="text"
                          class="text-ellipsis"
                          style="width: 100%; color: #272e40;"
                        >
                          {{ item.department || "—" }}
                        </el-button>
                      </el-col>
                      <el-col :span="4">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.hospital"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.hospital || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="3">
                        <el-button
                          type="text"
                          class="text-ellipsis"
                          style="width: 100%; color: #272e40;"
                        >
                          {{ item.code || "—" }}
                        </el-button>
                      </el-col>
                      <el-col :span="3">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.representName"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.representName || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="5">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          :content="item.bindOrgName"
                          placement="top"
                        >
                          <el-button
                            type="text"
                            class="text-ellipsis"
                            style="width: 100%; color: #272e40;"
                          >
                            {{ item.bindOrgName || "—" }}
                          </el-button>
                        </el-tooltip>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
            </div>
            <div v-show="!recallCodeList.length > 0" class="table-empry">
              暂无数据
            </div>
          </el-scrollbar>
          <el-pagination
            v-if="activeName !== 'department'"
            :current-page="searchObj.pageNo"
            :page-size="searchObj.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="totalCount"
            style="margin-top: 10px;"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </template>
      <div slot="footer" class="flex-center">
        <el-button
          class="w100 mgn-l20"
          size="medium"
          @click="onClose"
        >
          取消
        </el-button>
        <el-button
          :loading="btnLoading || isBackCallSuccess"
          class="w100"
          size="medium"
          type="primary"
          @click="onSubmit"
        >
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CodeLabel from '@/baseComponents/CodeLabel'
import { mapActions, mapGetters } from 'vuex'
import { inviteCodeType } from '@/utils/util'

export default {
  name: 'RecallSceneCodeModal',
  components: {
    CodeLabel
  },
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: true
    },
    isSelfBuy: {
      type: Boolean,
      default: false
    },
    isBackCall: {
      // 是否 $emit 外部函数onSubmit，默认false;
      type: Boolean,
      default: false
    },
    isBackCallSuccess: {
      // 外部函数onSubmit 是否成功
      type: Boolean,
      default: false
    },
    sceneId: {
      // 场景id，
      type: String,
      default: ''
    },
    sceneType: {
      // 场景类型 科会->meeting；调研-> questionnaire；直播->live
      type: String,
      default: ''
    },
    tabsList: {
      type: Array,
      default() {
        return [
          {
            label: '代表未使用',
            name: 'represent',
            show: ''
          },
          {
            label: '组织未使用',
            name: 'department',
            show: 'training'
          },
          {
            label: '定向任务',
            name: 'doctor',
            show: ''
          }
        ]
      }
    },
    projectId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // ----------------------- base -------------
      message: null,
      // -------------------- tab --------------
      activeName: 'represent',
      timerId: null,
      changeTabTimer: null,
      isChangeTab: true,
      baseTabsList: [],
      sourceList: [],
      // -------------------- codeList ---------------------
      totalNum: 0,
      totalGredit: 0,
      money: 0,
      index: 0,
      codeList: [],
      codeTotal: 0,
      validDay: 0,
      representValidDay: 0,
      // ------------------------ 选择table -----------------
      loading: true,
      checkAll: false,
      checkedRecallCodeList: [], // 勾选的数量
      recallCodeList: [], // table 数据
      recallCodeKeyList: [], // 所有的勾选id
      isIndeterminate: false,
      // -------------------- scroll ----------------
      isScrollReq: true,
      // -------------------- submit ----------------
      btnLoading: false,
      count: 0,
      code: 0,
      searchObj: {
        pageNo: 1,
        pageSize: 100,
        searchName: ''
      },
      totalCount: 0,
      timer: null,
      selectListMap: {}
    }
  },
  computed: {
    ...mapGetters(['recallDialogList', 'isTopOrg']),
    placeholderStr() {
      const obj = {
        represent: '代表姓名',
        department: '组织名称',
        doctor: '医生姓名'
      }
      return obj[this.activeName]
    },
    showDialog: {
      get() {
        return this.visible
      },
      set(val) {
        if (!val) {
          this.onClose()
        }
      }
    }
  },
  watch: {
    visible(show) {
      if (show) {
        this.activeName = this.baseTabsList[0].name
        this.initCode()
        this.showDialog = true
      } else {
        this.showDialog = false
      }
      this.searchObj = {
        pageNo: 1,
        pageSize: 100,
        searchName: ''
      }
      this.codeTotal = 0
    },
    isBackCallSuccess() {
      if (this.isBackCallSuccess) {
        this.$emit('update:isBackCallSuccess', false)
        this.initRecallList()
        this.onSuccess()
      }
    }
  },
  mounted() {
    const initTabsList = []
    for (const item of this.tabsList) {
      if (!(this.isTopOrg && item.name === '2')) {
        initTabsList.push(item)
      }
    }
    this.baseTabsList = initTabsList
  },
  methods: {
    ...mapActions([
      'getRecallDialogList',
      'getFactoryAllotUserList',
      'getDoctorAllotUserList',
      'getRecallDialogTableFactory',
      'onRecallSceneRepCode',
      'onRecallSceneDepCode'
    ]),
    handleSizeChange(options) {
      this.searchObj.pageSize = options
      this.searchObj.pageNo = 1
      this.initRecallList()
    },
    handleCurrentChange(options) {
      this.searchObj.pageNo = options
      this.initRecallList()
    },
    onPageInit() {
      const list = []
      for (const item of this.recallCodeList) {
        const itemObj =
          this.selectListMap[
            this.activeName === 'represent'
              ? `${item.representId  }-${  item.orgId}`
              : this.activeName === 'doctor'
                ? item.codeId
                : item.factoryId
          ]
        if (itemObj) {
          item.inputVal = itemObj.inputVal
        }
        if (this.activeName === 'represent') {
          list.push(`${item.representId  }-${  item.orgId}`)
        } else if (this.activeName === 'department') {
          list.push(item.factoryId)
        } else {
          list.push(item.codeId)
        }
      }
      this.recallCodeKeyList = list
      this.onSelectStatus()
      this.loading = false
      // ---------------------- submit -------------------------
      this.btnLoading = false
    },
    initCode() {
      this.searchObj = {
        pageNo: 1,
        pageSize: 100,
        searchName: ''
      }
      this.totalNum = 0
      this.totalGredit = 0
      this.codeTotal = 0
      this.index = 0
      this.recallCodeList = []
      this.checkedRecallCodeList = []
      this.selectListMap = {}
      const Data = {
        isSelfBuy: this.isSelfBuy,
        sceneId: this.sceneId,
        sceneType: this.sceneType,
        ownerType: this.activeName === 'department' ? '3' : '2',
        isPreDoctorCode: this.activeName === 'doctor' ? '1' : '0',
        projectId: this.projectId
      }
      this.codeList = []
      this.getRecallDialogList(Data).then(
        res => {
          const list = (res.data || []).filter(v => v.canRecallCount > 0)
          this.loading = false
          this.codeList = JSON.parse(JSON.stringify(list))
          if (this.codeList.length > 0) {
            this.$set(this.codeList[this.index], 'isSelected', true)
            this.money = this.codeList[this.index].money
            this.validDay = this.codeList[this.index].validDay
            this.representValidDay =
              this.codeList[this.index].representValidDay
          }
          this.initRecallList()
        },
        () => {
          this.codeList = []
        }
      )
    },
    handleClick() {
      this.initCode()
    },
    initRecallList(isReset = false) {
      if (isReset) {
        this.searchObj = {
          pageNo: 1,
          pageSize: 100,
          searchName: ''
        }
      }
      this.loading = true
      const req = {
        ...this.searchObj,
        isSelfBuy: this.isSelfBuy,
        sceneId: this.sceneId,
        sceneType: inviteCodeType(this.sceneType)[0],
        money: this.money,
        validDay: this.validDay,
        representValidDay: this.representValidDay,
        projectId: this.projectId
      }
      if (this.activeName === 'represent') {
        const data = Object.assign({}, req, { isPreDoctorCode: false })
        this.getFactoryAllotUserList(data).then(
          res => {
            let list = []
            for (const item of res.data.data || []) {
              if (item.codeCount) {
                list.push(item)
              }
            }
            list = list?.map(item => ({
              ...item,
              canRecallCount: item.codeCount
            }))
            this.totalCount = res.data.totalCount
            this.recallCodeList = JSON.parse(JSON.stringify(list))
            this.sourceList = JSON.parse(JSON.stringify(list))
            this.onPageInit()
          },
          () => {
            this.recallCodeList = []
            this.onPageInit()
          }
        )
      } else if (this.activeName === 'doctor') {
        this.getDoctorAllotUserList(req).then(
          res => {
            const list = []
            for (const item of res.data.data || []) {
              list.push(item)
            }
            this.totalCount = res.data.totalCount
            this.recallCodeList = JSON.parse(JSON.stringify(list))
            this.sourceList = JSON.parse(JSON.stringify(list))
            this.onPageInit()
          },
          () => {
            this.recallCodeList = []
            this.onPageInit()
          }
        )
      } else {
        const data = Object.assign({}, req, { includeSelf: true })
        this.getRecallDialogTableFactory(data).then(
          res => {
            const list = []
            for (const item of res.data || []) {
              if (item.canRecallCount) {
                list.push(item)
              }
            }
            this.recallCodeList = JSON.parse(JSON.stringify(list))
            this.sourceList = JSON.parse(JSON.stringify(list))
            this.onPageInit()
          },
          () => {
            this.recallCodeList = []
            this.onPageInit()
          }
        )
      }
    },
    onSelectCodeLabel(item, index) {
      this.codeTotal = 0
      this.totalNum = 0
      this.totalGredit = 0
      this.checkedRecallCodeList = []
      this.selectListMap = {}
      if (item.isSelected || this.loading) {
        return false
      }
      for (let i = 0; i < this.codeList.length; i++) {
        if (this.codeList[i].isSelected) {
          this.$set(this.codeList[i], 'isSelected', false)
          break
        }
      }
      this.money = item.money
      this.index = index
      this.validDay = item.validDay
      this.representValidDay = item.representValidDay
      this.$set(this.codeList[index], 'isSelected', true)
      this.initRecallList(true)
    },
    searchDoctorName(val) {
      if (this.activeName === 'department') {
        this.searchFactoryName(val)
      } else {
        this.timer && clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.searchObj.pageNo = 1
          this.searchObj.searchName = val
          this.initRecallList()
        }, 300)
      }
    },
    searchFactoryName(val) {
      if (!val) {
        this.recallCodeList = [...this.sourceList]
      } else {
        this.recallCodeList = []
        this.sourceList?.map(v => {
          const name = v.factoryName || ''
          if (name.indexOf(val) > -1) {
            this.recallCodeList.push(v)
          }
        })
      }
      this.onPageInit()
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false
      this.checkedRecallCodeList = val
        ? [
          ...new Set([
            ...this.checkedRecallCodeList,
            ...this.recallCodeKeyList
          ])
        ]
        : [
          ...new Set(
            [...this.checkedRecallCodeList].filter(
              x => this.recallCodeKeyList.indexOf(x) < 0
            )
          )
        ]
      if (val) {
        this.checkAll = true
        for (const item of this.recallCodeList) {
          this.$set(item, 'inputVal', item.canRecallCount)
          this.selectListMap[
            this.activeName === 'represent'
              ? `${item.representId  }-${  item.orgId}`
              : this.activeName === 'doctor'
                ? item.codeId
                : item.factoryId
          ] = { ...item }
        }
      } else {
        for (const item of this.recallCodeList) {
          this.$set(item, 'inputVal', null)
        }
        this.deleteItem()
      }
      this.onEmptyClear()
    },
    deleteItem() {
      for (const key in this.selectListMap) {
        if (!this.checkedRecallCodeList.includes(key)) {
          delete this.selectListMap[key]
        }
      }
    },
    handleCheckedRecallCodeListChange(value) {
      this.checkedRecallCodeList = [
        ...new Set([...this.checkedRecallCodeList, ...value])
      ]
      this.onSelectStatus()
      this.deleteItem()
      this.onEmptyClear()
    },
    onSelectStatus() {
      this.checkAll = false
      this.isIndeterminate = false
      let all = 1
      this.recallCodeList = [...this.recallCodeList]?.map(v => {
        this.checkedRecallCodeList?.map(k => {
          if (
            k === v.factoryId ||
            k === `${v.representId  }-${  v.orgId}` ||
            k === v.codeId
          ) {
            v = { ...v, inputVal: v.inputVal || v.canRecallCount || 0 }
            this.selectListMap[k] = { ...v }
            this.isIndeterminate = true
          }
          if (
            !this.checkedRecallCodeList.includes(
              this.activeName === 'represent'
                ? `${v.representId  }-${  v.orgId}`
                : this.activeName === 'doctor'
                  ? v.codeId
                  : v.factoryId
            )
          ) {
            all = 0
          }
        })
        return v
      })
      if (all && this.checkedRecallCodeList.length > 0) {
        this.checkAll = true
        this.isIndeterminate = false
      }
    },
    handleInput(val, item) {
      const itemObj =
        this.selectListMap[
          this.activeName === 'represent'
            ? `${item.representId  }-${  item.orgId}`
            : this.activeName === 'doctor'
              ? item.codeId
              : item.factoryId
        ]
      if (itemObj) {
        itemObj.inputVal = val || 0
        this.onAllCount()
      }
    },
    onClose() {
      this.$emit('onClose', false)
    },
    onEmptyClear() {
      for (const item of this.recallCodeList) {
        this.$set(item, 'isEmpty', false)
        if (
          this.checkedRecallCodeList.includes(
            this.activeName === 'represent'
              ? `${item.representId  }-${  item.orgId}`
              : this.activeName === 'doctor'
                ? item.codeId
                : item.factoryId
          )
        ) {
          this.selectListMap[
            this.activeName === 'represent'
              ? `${item.representId  }-${  item.orgId}`
              : this.activeName === 'doctor'
                ? item.codeId
                : item.factoryId
          ].inputVal = item.inputVal || 0
          this.$nextTick(() => {
            if (!item.inputVal || Number(item.inputVal) === 0) {
              this.$set(item, 'isEmpty', true)
            }
          })
        }
      }
      this.onAllCount()
    },
    onAllCount() {
      this.totalNum = 0
      this.totalGredit = 0
      this.codeTotal = 0
      if (this.activeName === 'doctor') {
        this.totalNum = this.checkedRecallCodeList.length
        this.totalGredit = this.money * this.totalNum
      } else {
        for (const key in this.selectListMap) {
          this.totalNum += this.selectListMap[key].inputVal
          this.totalGredit = this.money * this.totalNum
        }
      }
    },
    onSuccess() {
      const num =
        Number(this.codeList[this.index].canRecallCount) - this.totalNum
      this.$set(this.codeList[this.index], 'canRecallCount', num)
    },
    onSubmit() {
      if (!this.checkedRecallCodeList.length) {
        this.$message({
          message: '请选择撤回对象',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      if (this.totalNum > 100) {
        this.$message({
          message: '总撤回任务最多不能超过100',
          type: 'warning',
          duration: 2000
        })
        return false
      }
      this.message && this.message.close()
      const selectList = []
      const recallRepresentCodeBOList = []
      const recallUsers = []
      let isHasEmpty = false
      this.checkedRecallCodeList.map(v => {
        selectList.push(this.selectListMap[v])
      })
      selectList.map(item => {
        if (this.activeName !== 'doctor') {
          if (!item.inputVal) {
            this.$set(item, 'isEmpty', true)
            !isHasEmpty &&
              (this.message = this.$message({
                message: '请填写撤回数量！',
                type: 'warning',
                duration: 2000
              }))
            isHasEmpty = true
          }
        }
        recallRepresentCodeBOList.push({
          representId: item.representId || '',
          codeId: item.codeId || '',
          recallCount: item.inputVal || 1,
          orgId: item.orgId || item.factoryId || item.bindOrgId || ''
        })
        recallUsers.push({
          stringVal: item.orgId || item.factoryId || '',
          intVal: item.inputVal
        })
      })
      if (selectList.length === 0 || isHasEmpty) {
        return false
      }
      this.btnLoading = true
      let req = {
        money: this.money,
        sceneId: this.sceneId,
        sceneType: this.sceneType,
        representValidDay: this.representValidDay,
        validDay: this.validDay,
        projectId: this.projectId
      }
      if (this.activeName === 'department') {
        req = { ...req, recallUsers: recallUsers }
      } else {
        req = { ...req, recallRepresentCodeBOList: recallRepresentCodeBOList }
      }
      if (this.activeName === 'doctor') {
        req = { ...req, isPreDoctorCode: true }
      }
      if (!this.isBackCall) {
        const reqUrl =
          this.activeName === 'department'
            ? this.onRecallSceneDepCode
            : this.onRecallSceneRepCode
        reqUrl(req).then(
          res => {
            this.btnLoading = false
            if (res.data) {
              this.$message({
                message: '撤回成功',
                type: 'success'
              })
              this.initCode()
              this.initRecallList()
              this.$emit('update')
              // this.onSuccess()
            } else {
              this.$message({
                message: res.message,
                type: 'error'
              })
            }
          },
          rea => {
            this.btnLoading = false
            this.$message({
              message: rea.message,
              type: 'error'
            })
          }
        )
      } else {
        this.$emit(
          'onSubmit',
          Object.assign({}, req, { selectList: selectList })
        )
      }
    }
  }
}
</script>
<style>
.empty .el-input__inner {
  border: 1px solid red;
}
.checkbox-message-item .el-row {
  width: 100%;
}
.search-input .el-input__inner {
  border-radius: 20px;
}
</style>
<style lang="scss" scoped>
.code-list-box {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.code-list-title {
  font-weight: bold;
  width: 130px;
  flex-shrink: 0;
  flex-grow: 0;
}
.code-list-label {
  display: flex;
  flex-flow: row nowrap;
}
.recall-scene-code-modal-checkbox {
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    max-height: 200px;
    overflow: scroll;
  }
}
.table-empry {
  height: 100px;
  line-height: 100px;
  background: #fff;
  text-align: center;
}
.checkbox-title {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  background: #f6f7fb;
}
.checkbox-item:nth-child(even) {
  background: #f6f7fb;
}
.checkbox-item {
  height: 50px;
  line-height: 50px;
  padding-left: 15px;
}
.checkbox-message-box {
  width: 100%;
}
.checkbox-message-item:nth-child(even) {
  background: #f6f7fb;
}
.checkbox-message-item {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.check-res {
  margin-top: 10px;
  text-align: center;
}
.search-input {
  width: 300px;
}
.recall-scene-code-modal ::v-deep {
  .el-dialog__wrapper .el-dialog {
    width: 700px !important;
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
.recall-scene-code-modal-checkbox ::v-deep {
  .el-checkbox {
    color: #272e40;
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.scroll-list {
  overflow-y: scroll;
  height: 300px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9eaeb;
  @media screen and (max-width: 768px) {
    height: 120px;
  }
}
.has-select {
  color: #3d61e3;
}
</style>
