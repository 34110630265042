import request from '@/utils/request'
import API from '@/apis/api-types'

export function getLivePreviewQrCode(liveId = '') {
  // 已经分配任务的代表列表
  return request({
    url: API.getLivePreviewQr,
    method: 'get',
    params: { liveId }
  })
}

// TODO: 定向直播--创建直播
// 添加播客
export function getSpeakerList(params) {
  return request({
    url: API.getSpeakerList,
    method: 'get',
    params
  })
}

// 创建直播
export function createLiveDraft(data) {
  return request({
    url: API.createLive,
    method: 'post',
    data
  })
}
// 添加题目
export function getExamList(data) {
  return request({
    url: API.getExamList,
    method: 'post',
    data
  })
}
//
export function getCategoryList(params) {
  return request({
    url: API.getCategoryList,
    method: 'get',
    params
  })
}

// TODO: 直播中控台
export function getLiveConsoleDetail(params) {
  return request({
    url: API.getLiveConsoleDetail,
    method: 'get',
    params
  })
}
export function getDynamicData(params) {
  return request({
    url: API.getDynamicData,
    method: 'get',
    params
  })
}
export function getLivePacketList(params) {
  return request({
    url: API.getLivePacketList,
    method: 'get',
    params
  })
}
export function getJobTitle(params) {
  return request({
    url: API.getJobTitle,
    method: 'get',
    params
  })
}
export function getLiveRecordList(params) {
  return request({
    url: API.getLiveRecordList,
    method: 'get',
    params
  })
}
export function downLoadLiveRecordList(params) {
  return request({
    url: API.downLoadLiveRecordList,
    method: 'get',
    params
  })
}
export function handleLiveStatus(params) {
  return request({
    url: API.handleLiveStatus,
    method: 'get',
    params
  })
}
export function editViewCount(params) {
  return request({
    url: API.editViewCount,
    method: 'get',
    params
  })
}
// 创建红包
export function submitRedEnvelope(data) {
  return request({
    url: API.submitRedEnvelope,
    method: 'POST',
    data
  })
}

// 导入白名单
export function importWhiteList(data) {
  return request({
    url: API.importWhiteList,
    method: 'post',
    data
  })
}

// 聊天室红包信息
export function getImEnvelope(params) {
  return request({
    url: API.getImEnvelope,
    method: 'get',
    params
  })
}
// 回放视频列表
export function getLiveCacheList(params) {
  return request({
    url: API.getLiveCacheList,
    method: 'get',
    params
  })
}
export function onSettingReplayVideo(params) {
  return request({
    url: API.onSettingReplayVideo,
    method: 'get',
    params
  })
}
export function onMergeVideo(data) {
  return request({
    url: API.onMergeVideo,
    method: 'post',
    data
  })
}
export function onDelVideoCache(data) {
  return request({
    url: API.onDelVideoCache,
    method: 'post',
    data
  })
}
export function getLiveWhiteList(params) {
  return request({
    url: API.getLiveWhiteList,
    method: 'get',
    params
  })
}
export function getLiveOldMessageList(params) {
  return request({
    url: API.getLiveOldMessageList,
    method: 'get',
    params
  })
}
export function getIMUserInfo(params) {
  return request({
    url: API.getIMUserInfo,
    method: 'get',
    params
  })
}
export function dealChatMsg(params) {
  return request({
    url: API.dealChatMsg,
    method: 'get',
    params
  })
}
export function getRedEnvelopeDetail(params) {
  return request({
    url: API.getRedEnvelopeDetail,
    method: 'get',
    params
  })
}
export function recallIMRedEnvelope(params) {
  return request({
    url: API.recallIMRedEnvelope,
    method: 'get',
    params
  })
}
// 直播黑名单
export function getBlackLiveList(params) {
  return request({
    url: API.getBlackLiveList,
    method: 'get',
    params
  })
}
// 解除黑名单
export function handleBlackList(params) {
  return request({
    url: API.handleBlackList,
    method: 'get',
    params
  })
}
export function removeLiveWhiteList(params) {
  return request({
    url: API.removeLiveWhiteList,
    method: 'get',
    params
  })
}
export function recordLiveTime(params) {
  return request({
    url: API.recordLiveTime,
    method: 'get',
    params
  })
}
export function replayLive(params) {
  return request({
    url: API.replayLive,
    method: 'get',
    params
  })
}
export function getLiveDetail(params) {
  return request({
    url: API.getLiveDetail,
    method: 'get',
    params
  })
}

// 中控台获取红包列表
export function getLiveRedPacketList(params) {
  return request({
    url: API.getLiveRedPacketList,
    method: 'get',
    params
  })
}

// 获取红包答题详情
export function getRedPacketExamDetail(params) {
  return request({
    url: API.getRedPacketExamDetail,
    method: 'get',
    params
  })
}

// 导出某个答题红包对应的医生答题信息列表
export function exportRedPacketExamDetail(params) {
  return request({
    url: API.exportRedPacketExamDetail,
    method: 'get',
    params
  })
}

export function getRepresentLiveRecordList(params) {
  return request({
    url: API.getRepresentLiveRecordList,
    method: 'get',
    params
  })
}

export function setRecordingRule(params) {
  return request({
    url: API.setRecordingRule,
    method: 'get',
    params
  })
}
